<template>
<div>
  <nav-bar v-if="authUser"></nav-bar>
  <side-navigation v-if="authUser"></side-navigation>
  <router-view v-if="!authUser"></router-view>
</div>
</template>

<script>
import SideNavigation from "./components/SideNavigation.vue"
import NavBar from "./components/NavBar.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
      SideNavigation,
      NavBar
  },
  computed: mapGetters(['authUser'])
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
