<template>
  <div>
    <map-svg ref="svg"></map-svg>
  </div>
</template>

<script>
import MapSvg from './MapSvg.vue'

export default {
  name: 'AirportMap',
  components: {
    MapSvg
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    resetNodeColor: function() {
      // ------ Update color of Baggage Claim node ----------
      let claim = document.getElementById('baggage-claim')
      claim.style.opacity = 0.05

      // ------ Update color of Security Check node ----------
      let sec = document.getElementById('security-check')
      sec.style.opacity = 0.05

      // ------ Update color of Check-In node ----------
      let check = document.getElementById('check-in')
      check.style.opacity = 0.05

      // ------ Update color of Airside Dwell node ----------
      let air = document.getElementById('airside-dwell')
      air.style.opacity = 0.05

      // ------ Update color of Airside Dwell node ----------
      let intAir = document.getElementById('int-airside-dwell')
      intAir.style.opacity = 0.05

      // ------ Update color of International Baggage Claim node ----------
      let intClaim = document.getElementById('int-baggage-claim')
      intClaim.style.opacity = 0.05

      // ------ Update color of PLAGS node ----------
      let plags = document.getElementById('plags')
      plags.style.opacity = 0.05

      // ------ Update color of Broder Control Outbound node ----------
      let borderOut = document.getElementById('border-control-out')
      borderOut.style.opacity = 0.05

      // ------ Update color of Broder Control Inbound node ----------
      let borderIn = document.getElementById('border-control-in')
      borderIn.style.opacity = 0.05

      // ------ Update color of Customs node ----------
      let customs = document.getElementById('customs')
      customs.style.opacity = 0.05

      // ------ Update color of Gates node ----------
      for (let i = 1; i <= 15; i++) {
        let gate = document.getElementById(`gate-${i}`)
        gate.style.opacity = 0.05
      }
    }
  },
  mounted() {}
}
</script>

<style scoped></style>
