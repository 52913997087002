<template>
  <div id="login-card">
    <div class="d-flex flex-column text-center align-items-center">
      <h4 class="mt-0">Password Changed !</h4>
      <p class="text-muted">Your password has been changed successfully.</p>
      <img
        src="../assets/success.svg"
        class="mt-5"
        alt="reset success image"
        height="140"
        width="140"
      />
    </div>

    <footer class="footer footer-alt">
      <p class="text-muted">
        Back to
        <router-link to="/login" class="text-muted ml-1"
          ><b>Log In</b></router-link
        >
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ResetPassSuccess',
  data() {
    return {}
  },
  methods: {}
}
</script>
