<template>
  <div class="text-center mt-4">
    <p class="text-muted font-16 mb-3">Sign in with your corporate ID</p>
    <b-spinner v-if="authLoading" small></b-spinner>
    <img
      @click="loginAzure"
      v-if="!authLoading"
      class="sso-btn p-1"
      src="../assets/azure-ad_white.png"
      alt=""
      height="43"
    />
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapMutations } from 'vuex'

// const logoutURL =
//  'https://myamplifyproject709d713b-709d713b-devv.auth.ap-southeast-2.amazoncognito.com/logout?client_id=4ltl3paraokitnfm2e1ueb1t1i&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http://localhost:8080/login/'

export default {
  name: 'AzureSSO',
  data() {
    return {
      authLoading: false,
      error: ''
    }
  },
  methods: {
    ...mapMutations(['setAuthUser']),
    async loginAzure() {
      try {
        this.authLoading = true
        await Auth.federatedSignIn({ provider: 'MSAzureAD' })
        this.authLoading = false
      } catch (err) {
        console.log(err)
        this.authLoading = false
      }
    }
  }
}
</script>

<style></style>
