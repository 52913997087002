import axios from 'axios'

const state = {
  baseAPI: `${process.env.VUE_APP_BASE_API_URL}/`, // Protected API from Phase 2
  loadFactorApi: 'calloadfactor',
  bayGateApi: 'calbayallocation',
  runApi: 'run',
  initApi: 'init',
  resetApi: 'reset?onlymine=True',
  analysisListApi: 'analysis/list',
  getDataApi: 'analysis/getdata',
  apiLoading: '',
  stepStatus: {
    step1: 'PROGRESS',
    step2: 'IDLE',
    step3: 'IDLE',
    step4: 'IDLE'
  },
  axiosConfig: {
    headers: {
      Authorization: ''
    }
  }
}
const getters = {
  baseAPI: state => state.baseAPI,
  runApi: state => state.runApi,
  resetApi: state => state.resetApi,
  initApi: state => state.initApi,
  loadFactorApi: state => state.loadFactorApi,
  bayGateApi: state => state.bayGateApi,
  analysisListApi: state => state.analysisListApi,
  getDataApi: state => state.getDataApi,
  apiLoading: state => state.apiLoading,
  axiosConfig: state => state.axiosConfig,
  stepStatus: state => state.stepStatus
}
const actions = {
  async simulationAction({ commit, getters }, params) {
    let res = null
    let prefix = ''
    try {
      commit('setLoading', params.type)
      if (params.content) {
        prefix = '?'
        for (const [key, value] of Object.entries(params.content)) {
          if (prefix != '?') prefix = `${prefix}&`
          prefix = `${prefix}${key}=${value}`
        }
      }
      if (params.method == 'post') {
        res = await axios.post(
          getters.baseAPI + getters[`${params.type}Api`] + prefix ?? prefix,
          '',
          getters.axiosConfig
        )
      } else {
        res = await axios.get(
          getters.baseAPI + getters[`${params.type}Api`] + prefix ?? prefix,
          getters.axiosConfig
        )
      }

      commit('setLoading', '')
      let apiMessage = res.data['message']
      return { status: 'success', response: res, content: apiMessage }
    } catch (error) {
      commit('setLoading', '')
      let message = ''
      console.log(error)
      console.log(error.response)
      if (error.response?.status === 401) {
        message = error.response.data['message']
      } else {
        message = error.message
      }
      return { status: 'error', err_msg: message }
    }
  },
  async triggerCalculation({ commit, getters }, params) {
    try {
      let URI = ''
      let res = null
      if (params.type == 'LoadFactor') {
        URI = getters.baseAPI + getters.loadFactorApi
      } else if (params.type == 'BayGate') {
        URI = getters.baseAPI + getters.bayGateApi
      } else return { status: 'error', err_msg: 'Wrong type configuration!' }
      commit('setLoading', params.type)
      res = await axios.post(URI, '', getters.axiosConfig)
      commit('setLoading', '')
      let apiMessage = res.data['message']
      return { status: 'success', response: res, content: apiMessage }
    } catch (error) {
      commit('setLoading', '')
      let message = ''
      console.log(error)
      if (error.response.status === 401) {
        message = error.response.data['message']
      } else {
        message = error.message
      }
      return { status: 'error', err_msg: message }
    }
  }
}
const mutations = {
  setLoading: (state, option) => (state.apiLoading = option),
  setAxiosConfig: (state, option) => {
    state.axiosConfig.headers.Authorization = option
  },
  setStepStatus: (state, option) => {
    if (option.step1) state.stepStatus.step1 = option.step1
    if (option.step2) state.stepStatus.step2 = option.step2
    if (option.step3) state.stepStatus.step3 = option.step3
    if (option.step4) state.stepStatus.step4 = option.step4
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
