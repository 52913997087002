<template>
  <div>
    <b-col cols="7">
      <div v-for="(item, k) in nodeData" :key="item.id">
        <span v-if="item.Type == 'Distribution'">
          <b-row class="align-items-center" no-gutters>
            <h5 class="font-14 my-1 mr-2">{{ k | camelCase }}:</h5>
          </b-row>
          <b-row class="align-items-baseline" no-gutters>
            <b-col class="d-flex flex-row align-items-center">
              <h5 class="font-13 my-1 mr-1">
                Function:
              </h5>
              <node-field
                v-if="isObject(item)"
                :fieldData="item"
                :fieldType="'select'"
                v-on="$listeners"
                @functionChange="value => changeFunction(value, k)"
              ></node-field>
            </b-col>
            <b-col class="d-flex flex-row align-items-baseline mr-2">
              <h5 class="font-13 my-1 mr-2">
                Parameters:
              </h5>
              <b-row class="text-muted flex-column" no-gutters>
                <b-col
                  v-if="Boolean(item.Params.a)"
                  class="d-flex mb-1 align-items-baseline"
                >
                  <span class="font-14">a:</span>
                  <node-field
                    :fieldData="item.Params.a"
                    :fieldType="'input'"
                    v-on="$listeners"
                  ></node-field>
                </b-col>
                <span
                  class="text-muted font-14 my-1 mr-2"
                  v-else-if="!Boolean(item.Params.b)"
                  >-</span
                >
                <b-col
                  v-if="Boolean(item.Params.b)"
                  class="d-flex align-items-baseline"
                >
                  <span class="font-14">b:</span>
                  <node-field
                    :fieldData="item.Params.b"
                    :fieldType="'input'"
                    v-on="$listeners"
                  ></node-field>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="Boolean(item.Range)"
              class="d-flex flex-row align-items-baseline"
            >
              <h5 class="my-1 mr-2 font-13">
                Range:
              </h5>
              <b-row
                v-if="Boolean(item.Range.Low) && Boolean(item.Range.High)"
                class="text-muted flex-column"
                no-gutters
              >
                <b-col class="d-flex align-items-baseline mb-1">
                  <span class="font-14">Low:</span>
                  <node-field
                    :fieldData="item.Range.Low"
                    :fieldType="'input'"
                    v-on="$listeners"
                  ></node-field>
                </b-col>
                <b-col class="d-flex align-items-baseline">
                  <span class="font-14">High:</span>
                  <node-field
                    :fieldData="item.Range.High"
                    :fieldType="'input'"
                    v-on="$listeners"
                  ></node-field
                ></b-col>
              </b-row>
            </b-col>
          </b-row>
        </span>
      </div>
    </b-col>
    <b-col cols="5">
      <div v-for="(item, attrkey) in nodeData" :key="item.id">
        <span
          v-if="
            item.Type != 'Distribution' &&
              attrkey != 'SubNodes' &&
              attrkey != 'Schedule'
          "
        >
          <b-row class="align-items-center" no-gutters>
            <h5 class="font-14 my-1 mr-1">{{ attrkey | camelCase }}:</h5>
            <node-field
              v-if="isObject(item)"
              :fieldData="item"
              :fieldType="'input'"
              v-on="$listeners"
            ></node-field>
          </b-row>
        </span>
      </div>
    </b-col>
    <b-container v-if="Boolean(nodeData.Schedule)" class="mb-2">
      <h5 class="font-14 my-1 mr-1">Schedule:</h5>
      <b-row class="bg-dark text-white py-1">
        <b-col cols="auto" class="pl-2 pr-0"
          ><i class="fas fa-plus mr-2 pointer" @click="addRow"></i
        ></b-col>
        <b-col>Week Day</b-col>
        <b-col>Time Window</b-col>
        <b-col>Counters</b-col>
      </b-row>
      <b-row
        v-for="(item, id) in nodeData.Schedule"
        :key="item.id"
        class="schedule-row py-1"
      >
        <b-col cols="auto" class="pl-2 pr-0"
          ><i
            class="far fa-trash-alt mr-2 pointer text-muted"
            @click="removeRow(id)"
          ></i
        ></b-col>
        <b-col class="d-flex">
          <node-field
            v-if="isObject(item.WeekDay)"
            :fieldData="item.WeekDay"
            :fieldType="item.WeekDay.Type"
            v-on="$listeners"
          ></node-field>
        </b-col>
        <b-col class="d-flex">
          <node-field
            v-if="isObject(item.TimeWindow.Start)"
            :fieldData="item.TimeWindow.Start"
            :fieldType="'Time'"
            :fieldName="'Start'"
            v-on="$listeners"
          ></node-field>
          <node-field
            v-if="isObject(item.TimeWindow.Finish)"
            :fieldData="item.TimeWindow.Finish"
            :fieldType="'Time'"
            :fieldName="'Finish'"
            v-on="$listeners"
          ></node-field>
        </b-col>
        <b-col class="d-flex">
          <node-field
            v-if="isObject(item.Workers)"
            :fieldData="item.Workers"
            :fieldType="'select-workers'"
            :maxValue="Number(nodeData.MaxWorkers.Value)"
            v-on="$listeners"
          ></node-field>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NodeField from './NodeField.vue'

export default {
  components: {
    NodeField
  },
  name: 'NodeInfo',
  data() {
    return {}
  },
  props: {
    nodeData: Object
  },
  computed: {
    console: () => console
  },
  methods: {
    isObject: function(obj) {
      const type = typeof obj
      return type == 'object' ? true : false
    },
    addRow() {
      const workers = this.nodeData.DefaultWorkers.Value
      const obj = {
        WeekDay: {
          Value: '*',
          Type: 'WeekDay'
        },
        TimeWindow: {
          Start: { Value: '00:00', Type: 'Time' },
          Finish: { Value: '18:00', Type: 'Time' }
        },
        Workers: { Value: workers, Type: 'Number' }
      }
      this.nodeData.Schedule.push(obj)
      this.$emit('updated')
    },
    removeRow: function(id) {
      this.nodeData.Schedule.splice(id, 1)
      this.$emit('updated')
    },
    changeFunction: function(val, name) {
      switch (val) {
        case 'Normal':
          this.nodeData[`${name}`].Params = {}
          break
        case 'Beta':
          this.nodeData[`${name}`].Params = {
            a: { Type: 'Number', Value: 0 },
            b: { Type: 'Number', Value: 10 }
          }
          break
        case 'ExponentialPower':
          this.nodeData[`${name}`].Params = {
            b: { Type: 'Number', Value: 5 }
          }
          break
        case 'Exponential':
          this.nodeData[`${name}`].Params = {}
          break
        case 'Gamma':
          this.nodeData[`${name}`].Params = {
            a: { Type: 'Number', Value: 5 }
          }
          break
        default:
          break
      }
    }
  },
  filters: {
    camelCase: function(title) {
      return title.replace(/([a-z])([A-Z])/g, '$1 $2')
    }
  },
  mounted() {}
}
</script>

<style scoped>
h5 {
  margin: 10px 0;
  font-weight: 700;
  color: #6c757d;
}
.font-14 {
  font-size: 14px !important;
}
.font-13 {
  font-size: 13px !important;
}
.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}
.schedule-row:nth-child(odd) {
  background-color: #e4eff9;
}
.schedule-row:nth-child(even) {
  background-color: white;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  color: #007dbd;
}
.fa-trash-alt:hover {
  color: red !important;
}
</style>
