<template>
  <div>
    <b-card no-body>
      <b-tabs
        active-nav-item-class="step-active"
        active-tab-class="step-content"
        vertical
        nav-wrapper-class="w-25 tabs-wrapper"
        nav-class="border-0"
        v-model="tabIndex"
      >
        <b-tab
          active
          title-item-class="text-left"
          title-link-class="p-0 border-0"
        >
          <template #title>
            <b-row
              no-gutters
              class="justify-content-start tab-1 h-100"
              :class="tabIndex != 0 ? 'border-dia-blue' : ''"
            >
              <i
                class="fas fa-calendar-alt mx-2 icon-bg text-white"
                :class="[
                  stepStatus.step1 == 'DONE' ? 'icon-bg-green' : '',
                  stepStatus.step1 == 'PROGRESS' ? 'icon-bg-orange' : ''
                ]"
              ></i>
              <b-col
                class="d-flex flex-column pl-1"
                :class="tabIndex == 0 ? 'border-dia-no-right' : ''"
              >
                <span class="tab-title mb-2">Flight Schedule</span>
                <b-row v-if="!Boolean(remoteFlightScheduleCSV)" no-gutters
                  ><b-spinner class="mr-2" small></b-spinner>Loading...</b-row
                >
                <b-row v-else no-gutters class="mb-2 align-items-start">
                  <b-img
                    :src="require('@/assets/csv-file.png')"
                    height="30"
                    alt="Responsive image"
                    class="mr-2"
                  ></b-img>
                  <small class="text-secondary font-weight-normal mr-2"
                    ><i>FlightShedule.csv</i></small
                  >
                  <b-badge
                    @click="$router.push('/flight-schedule')"
                    variant="info"
                    class="badge"
                    ><i class="fas fa-edit mr-1"></i>Edit</b-badge
                  >
                  <small class="d-flex py-1 align-items-baseline">
                    <h5 class="font-13 my-0 mr-1">File last modified:</h5>
                    {{ remoteFlightScheduleCSV.lastModified }}</small
                  >
                </b-row>
              </b-col>
            </b-row>
          </template>
          <b-card-text class="text-left">
            <b-row no-gutters class="">
              <b-col class="mr-3">
                <h5 class="header-title">
                  Load Factor Status:
                </h5>
                <b-alert :variant="loadFactorStatus.status" show>
                  {{ loadFactorStatus.message }}
                </b-alert>
                <b-button @click="calcLF" class="mt-2" size="sm"
                  ><b-spinner
                    v-if="apiLoading == 'LoadFactor'"
                    small
                    class="mr-2"
                  ></b-spinner
                  >Calculate Load Factor</b-button
                ></b-col
              >
              <b-col class="d-flex flex-column">
                <h5 class="header-title">
                  Flight Schedule Information:
                </h5>
                <span class="d-flex pl-2 py-1 align-items-baseline bg-white">
                  <h5 class="font-14 my-0 mr-1">Number of Flights:</h5>
                  {{ numberOfFligts }}</span
                >
                <span
                  v-if="firstFlight"
                  class="d-flex pl-2 py-1 align-items-baseline"
                >
                  <h5 class="font-14 my-0 mr-1">First Flight:</h5>
                  {{ firstFlight | moment }}</span
                >
                <span
                  v-if="lastFlight"
                  class="d-flex pl-2  py-1 align-items-baseline bg-white"
                >
                  <h5 class="font-14 my-0 mr-1">Last Flight:</h5>
                  {{ lastFlight | moment }}</span
                >
                <span
                  v-if="missing > 0"
                  class="d-flex pl-2 py-1 align-items-baseline"
                >
                  <h5 class="font-14 my-0 mr-1">Load factor missing in:</h5>
                  {{ missing }} Flight<span v-if="missing > 1">s</span></span
                >
              </b-col>
            </b-row></b-card-text
          ></b-tab
        >
        <b-tab
          title-item-class="text-left"
          title-link-class="p-0 border-0"
          :disabled="stepStatus.step1 != 'DONE'"
        >
          <template #title>
            <b-row
              no-gutters
              class="justify-content-start tab-2"
              :class="[
                tabIndex != 1 ? 'border-dia-blue' : '',
                tabIndex < 1 ? 'grey-line' : ''
              ]"
            >
              <i
                class="fas fa-plane mx-2 icon-bg text-white"
                :class="[
                  stepStatus.step2 == 'DONE' ? 'icon-bg-green' : '',
                  stepStatus.step1 == 'DONE' ? 'icon-bg-orange' : ''
                ]"
              ></i>
              <b-col
                class="pl-1"
                :class="tabIndex == 1 ? 'border-dia-no-right' : ''"
                >Bay and Gate Allocation</b-col
              >
            </b-row>
          </template>
          <b-card-text>
            <bay-gate-step
              :status="gateBayStatus"
              v-on:bayGateStatusChange="gateBayStatus = $event"
            />
            <bay-gate
              :status="gateBayStatus"
              v-on:bayGateStatusChange="gateBayStatus = $event"
            /> </b-card-text
        ></b-tab>
        <b-tab
          title-item-class="text-left"
          title-link-class="p-0 border-0"
          :disabled="stepStatus.step2 != 'DONE'"
        >
          <template #title>
            <b-row
              no-gutters
              class="justify-content-start tab-3"
              :class="[
                tabIndex != 2 ? 'border-dia-blue' : '',
                tabIndex < 2 ? 'grey-line' : ''
              ]"
            >
              <i
                class="fas fa-calendar-alt mx-2 icon-bg text-white"
                :class="[
                  stepStatus.step2 == 'DONE' ? 'icon-bg-orange' : '',
                  stepStatus.step3 == 'DONE' ? 'icon-bg-green' : ''
                ]"
              ></i>
              <b-col
                class="pl-1 d-flex flex-column align-items-start"
                :class="tabIndex == 2 ? 'border-dia-no-right' : ''"
                ><span>Resource Schedule</span>
              </b-col>
            </b-row>
          </template>
          <b-row no-gutters>
            <b-button
              class="mr-2"
              variant="success"
              size="sm"
              @click="confirmRoster"
              >Approve Schedule<i class="fas fa-check ml-2"></i
            ></b-button>
            <b-button
              variant="secondary"
              size="sm"
              @click="$router.push('/node-config')"
              ><i class="fas fa-edit mr-2"></i>Amend Schedule</b-button
            >
          </b-row>
          <resource-schedule></resource-schedule>
        </b-tab>
        <b-tab
          title-item-class="text-left"
          title-link-class="p-0 border-0"
          :disabled="stepStatus.step2 != 'DONE'"
        >
          <template #title>
            <b-row
              no-gutters
              class="justify-content-start tab-4"
              :class="[
                tabIndex != 3 ? 'border-dia-blue' : '',
                tabIndex < 3 ? 'grey-line' : ''
              ]"
            >
              <i
                class="fas fa-cog mx-2 icon-bg text-white"
                :class="[
                  stepStatus.step2 == 'DONE' ? 'icon-bg-orange' : '',
                  stepStatus.step4 == 'DONE' ? 'icon-bg-green' : ''
                ]"
              ></i>
              <b-col
                class="pl-1"
                :class="tabIndex == 3 ? 'border-dia-no-right' : ''"
                >Node Configuration</b-col
              >
            </b-row>
          </template>
          <b-card-text>
            <b-row v-if="config">
              <b-col class="d-flex flex-column col-12 col-lg-6">
                <b-row
                  no-gutters
                  class="justify-content-between align-items-baseline mb-2"
                >
                  <h5 class="header-title">
                    Simulation - Global Configuration:
                  </h5>
                  <b-button
                    size="sm"
                    :disabled="!Boolean(fileEdited)"
                    class="btn-forecast"
                    @click="saveToCloud()"
                    ><b-spinner
                      v-if="s3ApiLoading == 'upload'"
                      small
                      class="mr-2"
                    ></b-spinner
                    ><i v-else class="fas fa-cloud-upload-alt mr-2"></i>Save
                    Changes</b-button
                  ></b-row
                >
                <span class="d-flex pl-2 py-2 align-items-baseline bg-white">
                  <h5 class="font-14 my-0 mr-1">Analysis Frequency:</h5>
                  <node-field
                    v-on:updated="fileEdited = true"
                    :fieldData="config.Settings.Global.Analysis.Frequency"
                    :fieldType="'select'"
                    :selectOptions="['10', '30', '60']"
                  ></node-field>
                </span>
                <span class="d-flex pl-2 py-2 align-items-baseline">
                  <h5 class="font-14 my-0 mr-1">Time Zone:</h5>
                  <node-field
                    :fieldData="config.Settings.Global.TimeZone"
                    :fieldType="'select'"
                    :selectOptions="['Australia/Darwin']"
                    v-on="$listeners"
                  ></node-field>
                </span>
              </b-col>
            </b-row> </b-card-text
        ></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BayGate from '../BayGate.vue'
import BayGateStep from './BayGateStep.vue'
import NodeField from '../NodeConfig/NodeField.vue'
import ResourceSchedule from './ResourceSchedule.vue'

export default {
  name: 'SimulationSteps',
  components: {
    BayGate,
    BayGateStep,
    NodeField,
    ResourceSchedule
  },
  data() {
    return {
      token: '',
      numberOfFligts: 0,
      missing: 0,
      firstFlight: null,
      lastFlight: null,
      loadFactorStatus: { status: '', message: '' },
      gateBayStatus: { status: '', message: '' },
      tabIndex: 0,
      clock: null,
      fileEdited: false
    }
  },
  computed: {
    ...mapGetters([
      'authUser',
      'apiLoading',
      'remoteFlightScheduleCSV',
      'config',
      'stepStatus',
      'simulationStatus',
      's3ApiLoading'
    ])
  },
  methods: {
    ...mapActions([
      'triggerCalculation',
      'getRemoteFlightSchedule',
      'copyMasterFile',
      'getRemoteConfigFile',
      'uploadToS3'
    ]),
    ...mapMutations([
      'setAxiosConfig',
      'setRemoteSimStatus',
      'setStepStatus',
      'setFlightScheduleCSV'
    ]),
    confirmRoster: async function() {
      this.setStepStatus({ step3: 'DONE' })
      setTimeout(() => {
        this.tabIndex++
      }, 100)
    },
    calcLF: async function() {
      this.setAxiosConfig(this.authUser.signInUserSession.idToken.jwtToken)
      let params = { type: 'LoadFactor' }
      this.setRemoteSimStatus({
        single: 'LAODFACTOR',
        status: 'INITIALISING...'
      })
      let res = await this.triggerCalculation(params)
      if (res.status == 'success') {
        this.makeToast('success', res.content)
        this.loadFactorStatus = {
          status: 'warning',
          message: 'Load factor calculation - Started'
        }
        this.setStepStatus({ step1: 'PROGRESS' })
        // this.clock = setTimeout(() => {
        //   this.checkServiceStatus()
        // }, 10000)
      } else {
        this.loadFactorStatus = {
          status: 'danger',
          message: 'There was an error while calculating load factor'
        }
        this.makeToast('danger', res.err_msg)
      }
    },
    checkForLoadFactor: function() {
      let check = Object.prototype.hasOwnProperty.call(
        this.remoteFlightScheduleCSV,
        'file'
      )
      if (check) this.hasLoadFactor()
    },
    hasLoadFactor: function() {
      try {
        this.$papa.parse(this.remoteFlightScheduleCSV.file, {
          delimiter: ',',
          header: true,
          skipEmptyLines: true,
          complete: results => {
            this.numberOfFligts = results.data.length

            // Check if Flight Schedule has any Flights
            if (this.numberOfFligts < 1)
              return (this.loadFactorStatus = {
                status: 'danger',
                message:
                  'There is no Flights in the Flight Schedule, please add some flights first!'
              })

            // Check if Flight Schedule has Load Factor column
            let checkColumn = Object.prototype.hasOwnProperty.call(
              results.data[0],
              'LoadFactor'
            )
            if (!checkColumn)
              return (this.loadFactorStatus = {
                status: 'danger',
                message: 'Missing, please calculate load factor first!'
              })

            this.firstFlight = new Date(results.data[0].Schedule)
            this.lastFlight = new Date(results.data[0].Schedule)
            this.missing = 0
            // Check if All flights has load factor
            results.data.forEach(item => {
              let date = new Date(item.Schedule)
              if (date < this.firstFlight) {
                this.firstFlight = date
              } else if (date > this.lastFlight) {
                this.lastFlight = date
              }
              if (isNaN(Number.parseFloat(item.LoadFactor))) this.missing++
            })
            if (this.missing > 0)
              return (this.loadFactorStatus = {
                status: 'danger',
                message:
                  'Load factor is missing in ' +
                  this.missing +
                  ' flights, please recalculate!'
              })
            if (this.missing == 0) {
              this.setStepStatus({ step1: 'DONE' })
              this.setFlightScheduleCSV({
                flightNo: this.numberOfFligts,
                firstFlight: this.$options.filters.moment(this.firstFlight),
                lastFlight: this.$options.filters.moment(this.lastFlight)
              })
              return (this.loadFactorStatus = {
                status: 'success',
                message:
                  'Load Factor has been calculated for all flights, please proceed to the next step.'
              })
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    checkForGateBay: function() {
      try {
        this.$papa.parse(this.remoteFlightScheduleCSV.file, {
          delimiter: ',',
          header: true,
          skipEmptyLines: true,
          complete: results => {
            // Check if Flight Schedule has Load Factor column
            let checkColumn = Object.prototype.hasOwnProperty.call(
              results.data[0],
              'Bay'
            )
            if (!checkColumn)
              return (this.gateBayStatus = {
                status: 'danger',
                message: 'Not Allocated, please run Allocation first!'
              })
            this.setStepStatus({ step2: 'DONE' })
            return (this.gateBayStatus = {
              status: 'success',
              message: 'Allocated, please proceed to next step'
            })
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    checkServiceStatus: async function() {
      const res = await this.getRemoteFlightSchedule()
      if (res.err_msg) {
        this.makeToast('danger', res.err_msg)
      } else {
        if (
          this.stepStatus.step1 == 'PROGRESS' ||
          this.simulationStatus.LAODFACTOR != 'IDLE'
        ) {
          this.checkForLoadFactor()
        }
        if (this.stepStatus.step2 == 'PROGRESS') this.checkForGateBay()
      }
    },
    saveToCloud: async function() {
      let res = await this.uploadToS3({
        object: 'config',
        fileName: 'settings.json'
      })
      if (res.status == 'success') {
        this.fileEdited = false
        this.makeToast('success', 'Changes saved successfully')
      } else if (res.status == 'error') {
        this.makeToast('danger', res.err_msg)
      }
    },
    makeToast: function(variant = null, text = '') {
      this.$bvToast.toast(text, {
        title: `Simulation API status`,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    }
  },

  async mounted() {
    const res = await this.getRemoteFlightSchedule()
    if (res.err_msg == 'NoSuchKey') {
      await this.copyMasterFile('flightschedule.csv')
      await this.getRemoteFlightSchedule()
      this.loadFactorStatus = {
        status: 'danger',
        message: 'Missing, please calculate load factor first!'
      }
      this.gateBayStatus = {
        status: 'danger',
        message: 'Not Allocated, please run Allocation first!'
      }
    } else {
      this.checkForLoadFactor()
      this.checkForGateBay()
    }
    if (!this.config) {
      let confRes = await this.getRemoteConfigFile()
      if (confRes.err_msg == 'NoSuchKey') {
        await this.copyMasterFile('settings.json')
        await this.getRemoteConfigFile()
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  },
  beforeDestroy() {
    if (this.clock) {
      clearTimeout(this.clock)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';
::v-deep h5 {
  margin: 10px 0;
  color: #6c757d;
  font-weight: 700;
}
.flex-fill {
  flex: 1;
}
.font-14 {
  font-size: 14px !important;
}
.font-13 {
  font-size: 13px !important;
}
::v-deep .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
}

.step-content {
  box-shadow: none;
}
.border-dia-blue {
  border-right: 1px solid #a3d1fc;
}
.border-dia-no-right {
  border-top: 1px solid #a3d1fc;
  border-left: 1px solid #a3d1fc;
  border-bottom: 1px solid #a3d1fc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-box-shadow: inset 10px 0px 18px 0px #d1e5f8;
  -moz-box-shadow: inset 10px 0px 18px 0px #d1e5f8;
  box-shadow: inset 10px 0px 18px 0px #d1e5f8;
  transition: box-shadow 0.3s linear;
}
.tab-1,
.tab-2,
.tab-3,
.tab-4 {
  min-height: 150px;
  align-content: start;
  position: relative;
  font-weight: 700 !important;
  color: #6c757d;
}
.step-active .tab-1,
.step-active .tab-2,
.step-active .tab-3,
.step-active .tab-4 {
  color: map-get($colors, primary) !important;
}
.tab-1:before,
.tab-2:before,
.tab-3:before,
.tab-4:before {
  background-color: #35b8e0;
  bottom: 0;
  content: '';
  left: 12px;
  position: absolute;
  top: 26px;
  width: 2px;
  z-index: 0;
  margin-left: 0.5rem;
}
.grey-line:before {
  background-color: #c5c7c9;
}
.tab-1 > div,
.tab-2 > div,
.tab-3 > div,
.tab-4 > div {
  min-height: 150px;
}
.icon-bg {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 2px solid transparent;
  background-color: #c5c7c9;
  text-align: center;
  line-height: 22px;
}
.icon-bg-orange {
  background-color: #ffc107;
}
.icon-bg-green {
  background-color: #28a745;
}
.w-select {
  width: 200px;
}
.tabs-wrapper {
  min-width: 270px;
}
.btn-forecast {
  color: #fff;
  background-color: #007dbd;
  border-color: #007dbd;
}
.btn-forecast:hover:enabled,
.btn-forecast:active,
.btn-forecast:focus {
  background-color: #00689c !important;
  border-color: #00689c !important;
}
.btn-forecast:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: not-allowed;
}
</style>

<style>
.step-active {
  box-shadow: none;
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
