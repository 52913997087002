<template>
  <div>
    <div class="page-title-box d-flex">
      <h4 class="page-title">
        <i class="fas fa-cogs mr-2"></i>Node Configuration
      </h4>
    </div>
    <b-card>
      <div v-if="config">
        <b-row>
          <b-col lg="6" class="d-flex text-left my-2">
            <b-button size="sm" variant="success" @click="confirmResetSettings"
              ><b-spinner
                v-if="s3ApiLoading == 'get' || s3ApiLoading == 'config'"
                small
                variant="info"
                label="Spinning"
                class="mr-2"
              ></b-spinner>
              <i v-else class="fas fa-undo mr-2"></i>Restore Default
              Settings</b-button
            >
          </b-col>
          <b-col class="d-flex text-right my-2 justify-content-end">
            <b-button
              size="sm"
              :disabled="!Boolean(fileEdited)"
              class="btn-forecast"
              @click="saveToCloud()"
              ><b-spinner
                v-if="s3ApiLoading == 'upload'"
                small
                class="mr-2"
              ></b-spinner
              ><i v-else class="fas fa-cloud-upload-alt mr-2"></i>Save
              Changes</b-button
            >
          </b-col>
        </b-row>
        <b-row no-gutters class="mb-2 col-xl-6 px-0">
          <b-input
            type="search"
            placeholder="Search"
            class="form-control"
            v-model="search"
          ></b-input>
        </b-row>
        <b-row
          class="bg-white border-bottom mb-2 text-left p-2 main-row"
          v-for="(node, Nodekey) in configNodes"
          :key="node.id"
        >
          <b-col cols="2">
            <b-row no-gutters class="align-items-center">
              <b-img
                v-bind="imgProps"
                :src="require(`@/assets/svg/nodes/${Nodekey}.svg`)"
                class="mr-2 my-1"
              ></b-img>

              <h5 class="font-14 my-1">
                {{ Nodekey | camelCase }}
              </h5>
            </b-row>
          </b-col>
          <b-col cols="10">
            <b-row>
              <b-col cols="7">
                <span class="text-muted font-13 font-weight-bold"
                  >Distribution Functions:</span
                >
              </b-col>
              <b-col cols="5">
                <span class="text-muted font-13 font-weight-bold"
                  >Additional Settings:</span
                >
              </b-col>
            </b-row>
            <node-info
              :node-data="node"
              v-on:updated="fileEdited = true"
              class="row"
            ></node-info>
            <b-row
              v-for="(subNode, subNodeKey) in node.SubNodes"
              :key="subNode.id"
              class="bg-light mb-2 rounded border"
            >
              <b-col>
                <b-row>
                  <h5 class="font-14 my-1 mx-2">
                    {{ subNodeKey | camelCase }}:
                  </h5>
                </b-row>
                <node-info
                  :node-data="subNode"
                  v-on:updated="fileEdited = true"
                  class="row"
                ></node-info>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="s3ApiLoading == 'config'" class="row">
        <b-alert show variant="info" class="w-100 text-left">
          <b-spinner
            small
            variant="info"
            label="Spinning"
            class="mr-2"
          ></b-spinner>
          Loading settings file from the cloud</b-alert
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NodeInfo from './NodeInfo.vue'

export default {
  components: { NodeInfo },
  name: 'NodeConfig',
  data() {
    return {
      imgProps: { blank: false, width: 35, height: 35, class: 'm1' },
      fileEdited: false,
      search: ''
    }
  },
  computed: {
    ...mapGetters(['config', 's3ApiLoading']),
    console: () => console,
    configNodes() {
      let nodes = {}
      if (this.config) {
        nodes = this.config.Settings.Nodes
      }
      if (this.search) {
        Object.filter = (obj, predicate) =>
          Object.keys(obj)
            .filter(key => predicate(key.toLowerCase()))
            .reduce((res, key) => ((res[key] = obj[key]), res), {})

        nodes = Object.filter(
          nodes,
          node =>
            node.indexOf(this.search.toLowerCase().replace(/\s/g, '')) > -1
        )
      }
      return nodes
    }
  },
  methods: {
    ...mapActions(['getRemoteConfigFile', 'copyMasterFile', 'uploadToS3']),
    ...mapMutations(['setActive']),
    confirmResetSettings: async function() {
      this.$bvModal
        .msgBoxConfirm(
          'Are you sure you want to restore default settings? All your current settings will be overwritten!',
          {
            title: 'Please Confirm',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: false
          }
        )
        .then(confirm => {
          if (confirm) {
            this.resetSettings()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    resetSettings: async function() {
      await this.copyMasterFile('settings.json')
      await this.getRemoteConfigFile()
      this.makeToast('success', 'Node Settings has been successfully restored')
    },
    saveToCloud: async function() {
      let res = await this.uploadToS3({
        object: 'config',
        fileName: 'settings.json'
      })
      if (res.status == 'success') {
        this.fileEdited = false
        this.makeToast('success', 'Changes saved successfully')
      } else if (res.status == 'error') {
        this.makeToast('danger', res.err_msg)
      }
    },
    makeToast(variant = null, text = '') {
      this.$bvToast.toast(text, {
        title: `Remote S3 bucket status`,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    }
  },
  filters: {
    camelCase: function(title) {
      return title.replace(/([a-z])([A-Z])/g, '$1 $2')
    }
  },
  async mounted() {
    this.setActive('node-config')
    let res = await this.getRemoteConfigFile()
    if (res.err_msg == 'NoSuchKey') {
      await this.copyMasterFile('settings.json')
      await this.getRemoteConfigFile()
    }
    const isEmpty = Object.keys(this.$route.query).length === 0
    if (!isEmpty) {
      this.search = this.$route.query.node
    }
  }
}
</script>

<style scoped>
input {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
input:focus {
  border-bottom: 1px solid #4285f4;
  -webkit-box-shadow: 0 1px 0 0 #4285f4;
  box-shadow: 0 1px 0 0 #4285f4;
}
.form-control {
  height: auto;
  padding: 0.6rem 0 0.4rem 0;
  margin: 0 0 0.5rem 0;
  background-color: transparent;
  border-radius: 0;
}
h5 {
  margin: 10px 0;
  font-weight: 700;
  color: #6c757d;
}
.font-14 {
  font-size: 14px !important;
}
.font-13 {
  font-size: 13px !important;
}
.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}
.main-row:hover {
  background-color: #e4eff9 !important;
}
.btn-forecast {
  color: #fff;
  background-color: #007dbd;
  border-color: #007dbd;
}
.btn-forecast:hover:enabled,
.btn-forecast:active,
.btn-forecast:focus {
  background-color: #00689c !important;
  border-color: #00689c !important;
}
.btn-forecast:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: not-allowed;
}
</style>
