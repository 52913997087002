import Vue from 'vue'
import Vuex from 'vuex'

import s3Api from './modules/s3Api'
import gatewayApi from './modules/gatewayApi'
import quicksightApi from './modules/quicksightApi'

Vue.use(Vuex)

function initialState() {
  return {
    availWorkers: 0,
    currentQueueLength: 0,
    maxWaitTime: 0,
    avgWaitTime: 0,
    maxServiceTime: 0,
    avgServiceTime: 0,
    newPassengers: 0,
    passServed: 0,
    runId: null
  }
}

export default new Vuex.Store({
  modules: {
    s3Api,
    gatewayApi,
    quicksightApi
  },
  state: {
    active: '', // Used to set active state for menu items
    remoteSimulationStatus: '',
    analysisLoading: false,
    simulationStatus: {
      SIMULATION: 'IDLE',
      GATEBAY: 'IDLE',
      LAODFACTOR: 'IDLE'
    },
    stopLoop: false,
    simulationDays: null,
    simSelectedDay: '',
    simulationData: null,
    simulationName: '',
    simulationPosition: 0,
    simulationHistoryFiles: null,
    selectedNodeList: ['checkInNode'],
    checkInNode: {
      nodeName: 'Check-In',
      icon: 'fas fa-luggage-cart',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    securityNode: {
      nodeName: 'Security',
      icon: 'fas fa-user-shield',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    airsideNode: {
      nodeName: 'Airside Dwell',
      icon: 'fas fa-users',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    intAirsideNode: {
      nodeName: 'International Airside Dwell',
      icon: 'fas fa-users',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    disembarkNode: {
      nodeName: 'Disembark',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    baggageNode: {
      nodeName: 'Baggage Claim',
      icon: 'fas fa-suitcase',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    intBaggageNode: {
      nodeName: 'International Baggage Claim',
      icon: 'fas fa-suitcase',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    plagsNode: {
      nodeName: 'PLAGS',
      icon: 'fas fa-user-shield',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    borderControlOutNode: {
      nodeName: 'Border Control',
      icon: 'fas fa-passport',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    borderControlInNode: {
      nodeName: 'Border Control',
      icon: 'fas fa-passport',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    customsNode: {
      nodeName: 'Customs',
      icon: 'fas fa-user-shield',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate1Node: {
      nodeName: 'Gate 1',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate2Node: {
      nodeName: 'Gate 2',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate3Node: {
      nodeName: 'Gate 3',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate4Node: {
      nodeName: 'Gate 4',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate5Node: {
      nodeName: 'Gate 5',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate6Node: {
      nodeName: 'Gate 6',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate7Node: {
      nodeName: 'Gate 7',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate8Node: {
      nodeName: 'Gate 8',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate9Node: {
      nodeName: 'Gate 9',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate10Node: {
      nodeName: 'Gate 10',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate11Node: {
      nodeName: 'Gate 11',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate12Node: {
      nodeName: 'Gate 12',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate13Node: {
      nodeName: 'Gate 13',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate14Node: {
      nodeName: 'Gate 14',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    },
    gate15Node: {
      nodeName: 'Gate 15',
      icon: 'fas fa-plane-departure',
      availWorkers: 0,
      currentQueueLength: 0,
      maxWaitTime: 0,
      avgWaitTime: 0,
      maxServiceTime: 0,
      avgServiceTime: 0,
      newPassengers: 0,
      passServed: 0,
      runId: null
    }
  },
  getters: {
    BucketFileList: state => state.simulationHistoryFiles,
    simulationName: state => state.simulationName,
    remoteSimulationStatus: state => state.remoteSimulationStatus,
    simulationData: state => state.simulationData,
    simulationLength: state => {
      return state.simulationData.data[state.simSelectedDay].length
    },
    simulationDays: state => state.simulationDays,
    simulationPosition: state => state.simulationPosition,
    simSelectedDay: state => state.simSelectedDay,
    active: state => state.active,
    analysisLoading: state => state.analysisLoading,
    stopLoop: state => state.stopLoop,
    currentSimTime: state => {
      let pos = state.simulationPosition
      let day = state.simSelectedDay
      let nodeData = state.simulationData.data[day]
      let time = Object.keys(nodeData)[pos]
      return time
    },
    selectedNodeList: state => state.selectedNodeList
  },
  mutations: {
    addNodeToList: (state, payload) => {
      if (state.selectedNodeList.indexOf(payload) == -1) {
        state.selectedNodeList.push(payload)
      }
    },
    removeNode: (state, payload) => {
      for (var i = 0; i < state.selectedNodeList.length; i++) {
        if (state.selectedNodeList[i] === payload) {
          state.selectedNodeList.splice(i, 1)
        }
      }
    },

    clearNodeList: state => {
      state.selectedNodeList = []
    },
    setStopLoop: (state, payload) => (state.stopLoop = payload),
    setActive: (state, payload) => (state.active = payload),
    setNodeInfo: (state, payload) => {
      let workers = payload.data ? parseInt(payload.data.Workers) : 0
      let mwt = payload.data ? parseInt(payload.data.MaxWaitTime) : '-'
      let awt = payload.data ? parseInt(payload.data.AvgWaitTime) : '-'
      let mpt = payload.data ? parseInt(payload.data.MaxProcTime) : '-'
      let apt = payload.data ? parseInt(payload.data.AvgProcTime) : '-'
      let waiting = payload.data ? parseInt(payload.data.Waiting) : 0
      let newEnter = payload.data ? parseInt(payload.data.NewEnter) : 0
      let served = payload.data ? parseInt(payload.data.Served) : 0
      let time = payload.data ? parseInt(payload.data.Time) : 0

      state[`${payload.node}Node`].availWorkers = workers
      state[`${payload.node}Node`].currentQueueLength = waiting
      state[`${payload.node}Node`].maxWaitTime = mwt ? mwt : '-'
      state[`${payload.node}Node`].avgWaitTime = awt ? awt : '-'
      state[`${payload.node}Node`].maxServiceTime = mpt ? mpt : '-'
      state[`${payload.node}Node`].avgServiceTime = apt ? apt : '-'
      state[`${payload.node}Node`].newPassengers = newEnter
      state[`${payload.node}Node`].passServed = served
      state[`${payload.node}Node`].runId = time
    },
    setAnalysisLoading(state, payload) {
      state.analysisLoading = payload
    },
    setRemoteSimStatus(state, payload) {
      if (!payload.single) {
        state.remoteSimulationStatus = payload.status
      } else {
        state.remoteSimulationStatus[`${payload.single}`] = payload.status
      }
    },
    setSimStatus: (state, payload) => (state.simulationStatus = payload),
    setSimDays: (state, payload) => (state.simulationDays = payload),
    addSimDay: (state, payload) => {
      if (!state.simulationDays) {
        state.simulationDays = []
      }
      state.simulationDays.push(payload)
    },
    setSimData: (state, payload) => (state.simulationData = payload),
    setSimName: (state, payload) => (state.simulationName = payload),
    setSimPos: (state, payload) => (state.simulationPosition = payload),
    setSimFiles: (state, payload) => (state.simulationHistoryFiles = payload),
    setSimSelectedDay: (state, payload) => (state.simSelectedDay = payload),

    resetNodes(state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state.checkInNode[key] = s[key]
        state.securityNode[key] = s[key]
        state.airsideNode[key] = s[key]
        state.intAirsideNode[key] = s[key]
        state.disembarkNode[key] = s[key]
        state.baggageNode[key] = s[key]
        state.intBaggageNode[key] = s[key]
        for (let i = 1; i <= 15; i++) {
          state[`gate${i}Node`][key] = s[key]
        }
      })
    }
  },
  actions: {
    updateNodeInfo({ getters, commit }) {
      let pos = getters.simulationPosition
      let day = getters.simSelectedDay
      let nodeData = getters.simulationData.data[day]
      let time = Object.keys(nodeData)[pos]
      commit('setNodeInfo', { node: 'checkIn', data: nodeData[time].CheckIn })
      commit('setNodeInfo', { node: 'security', data: nodeData[time].Security })
      commit('setNodeInfo', {
        node: 'airside',
        data: nodeData[time].DomesticAirside
      })
      commit('setNodeInfo', {
        node: 'intAirside',
        data: nodeData[time].InternationalAirside
      })
      commit('setNodeInfo', {
        node: 'disembark',
        data: nodeData[time].Disembark
      })
      commit('setNodeInfo', {
        node: 'baggage',
        data: nodeData[time].DomesticBaggage
      })
      commit('setNodeInfo', {
        node: 'intBaggage',
        data: nodeData[time].InternationalBaggage
      })
      commit('setNodeInfo', {
        node: 'plags',
        data: nodeData[time].PLAGS
      })
      commit('setNodeInfo', {
        node: 'borderControlOut',
        data: nodeData[time].BorderControlOut
      })
      commit('setNodeInfo', {
        node: 'borderControlIn',
        data: nodeData[time].BorderControlIn
      })
      commit('setNodeInfo', {
        node: 'customs',
        data: nodeData[time].Customs
      })
      for (let i = 1; i <= 15; i++) {
        commit('setNodeInfo', {
          node: `gate${i}`,
          data: nodeData[time][`Gate_g${i}`]
        })
      }
    }
  }
})
