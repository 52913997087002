<template>
  <div>
    <div class="card">
      <b-row>
        <b-col lg="6" class="d-flex justify-content-start">
          <b-button v-b-modal.disable-bay-modal size="sm" variant="success"
            ><i class="fas fa-ban mr-2"></i>Disable Bay</b-button
          >
        </b-col>
        <b-col lg="6" class="d-flex text-right justify-content-end">
          <b-button
            size="sm"
            class="btn-forecast"
            :disabled="!fileEdited"
            @click="saveToCloud()"
            ><b-spinner
              v-if="s3ApiLoading == 'dBays'"
              small
              class="mr-2"
            ></b-spinner
            ><i v-else class="fas fa-cloud-upload-alt mr-2"></i>Save
            Changes</b-button
          >
        </b-col>
      </b-row>
      <b-modal
        id="disable-bay-modal"
        ref="bay-modal"
        title="Disable Bay"
        hide-footer
      >
        <p class="my-4">Plaese select Bay to disable</p>
        <b-form id="disable-bay-form" @submit="onSubmit" inline>
          <b-form-group
            id="input-group-1"
            label="Bay number:"
            label-for="input-1"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-select
              id="input-1"
              v-model="bayDisableForm.bay_number"
              :options="bayList"
              required
              class="w-200"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select ... --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <!-- Date From -->
          <b-form-group
            id="input-group-2"
            label="Date From:"
            label-for="input-2"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-datepicker
              id="input-2"
              v-model="bayDisableForm.disabled_from"
              placeholder="Select date"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }"
              locale="en-AU"
              :required="true"
            ></b-form-datepicker>
          </b-form-group>

          <!-- Date To -->
          <b-form-group
            id="input-group-3"
            label="Date To:"
            label-for="input-3"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-datepicker
              id="input-3"
              v-model="bayDisableForm.disabled_to"
              placeholder="Select date"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }"
              locale="en-AU"
              required
            ></b-form-datepicker>
          </b-form-group>
          <!-- Comments -->
          <b-form-group
            id="input-group-4"
            label="Comments:"
            label-for="input-4"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-input
              id="input-4"
              v-model="bayDisableForm.comments"
              placeholder="Add comments..."
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-row no-gutters class="w-100 justify-content-end pt-3">
            <b-button size="sm" variant="success" type="submit" class="mr-2">
              Save
            </b-button>
            <b-button
              size="sm"
              variant="danger"
              @click="$bvModal.hide('disable-bay-modal')"
            >
              Cancel
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
      <b-table
        ref="bayGateTable"
        striped
        hover
        :items="disabledBays"
        :fields="fields"
        responsive="sm"
        class="mt-3"
        per-page="500"
      >
        <template #cell(bay_number)="data">
          {{ 'Bay ' + data.value }}
        </template>
        <template #cell(actions)="data">
          <i class="far fa-trash-alt" @click="deleteRow(data)"></i>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'BayGate',
  props: {
    status: Object
  },
  data() {
    return {
      fields: [
        { key: 'bay_number', label: 'Bay Number' },
        { key: 'disabled_from' },
        { key: 'disabled_to' },
        { key: 'comments' },
        { key: 'actions' }
      ],
      disabledBays: [],
      bayList: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '21',
        '22',
        '23',
        '24',
        '25'
      ],
      bayDisableForm: {
        bay_number: null,
        disabled_from: null,
        disabled_to: null,
        comments: ''
      },
      fileEdited: false
    }
  },
  computed: {
    ...mapGetters({
      s3ApiLoading: 's3ApiLoading',
      remoteDisableBays: 'disabledBays',
      remoteFlightScheduleCSV: 'remoteFlightScheduleCSV'
    })
  },
  methods: {
    ...mapMutations(['setDisabledBaysJSON', 'setFileContent']),
    ...mapActions([
      'uploadDisabledBays',
      'getRemoteDisabledBaysList',
      'uploadFlightSchedule',
      'copyMasterFile'
    ]),
    onSubmit(event) {
      event.preventDefault()
      this.disabledBays.unshift(JSON.parse(JSON.stringify(this.bayDisableForm)))
      this.$refs['bay-modal'].hide()
      this.fileEdited = true
    },
    deleteRow: function(data) {
      this.disabledBays.splice(data.index, 1)
      this.fileEdited = true
    },
    saveToCloud: async function() {
      this.setDisabledBaysJSON(this.disabledBays)
      let res = await this.uploadDisabledBays()
      if (res.status == 'success') {
        this.fileEdited = false
        this.makeToast('success', 'Changes saved successfully')
      } else if (res.status == 'error') {
        this.makeToast('danger', res.err_msg)
      }
      this.dropGateBayColumns()
    },
    dropGateBayColumns: async function() {
      if (this.status.status == 'success') {
        this.drop()
      }
    },
    drop: async function() {
      try {
        this.$papa.parse(this.remoteFlightScheduleCSV.file, {
          delimiter: ',',
          header: true,
          skipEmptyLines: true,
          complete: async results => {
            let content = this.$papa.unparse({
              data: results.data,
              fields: [
                'DepAirport',
                'Carrier',
                'ArrAirport',
                'Direction',
                'Flight',
                'Schedule',
                'LoadFactor',
                'Seats',
                'Type',
                'Timezone',
                'AircraftCode',
                'Terminal'
              ]
            })
            this.setFileContent(content)
            let res = await this.uploadFlightSchedule()
            if (res.status == 'success') {
              let status = {
                message: 'Missing, please calculate load factor first!',
                status: 'danger'
              }
              this.$emit('bayGateStatusChange', status)
            } else if (res.status == 'error') {
              this.makeToast('danger', res.err_msg)
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    makeToast(variant = null, text = '') {
      this.$bvToast.toast(text, {
        title: `Remote S3 bucket status`,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    },
    fetchBays: async function() {
      let res = await this.getRemoteDisabledBaysList()
      if (res.err_msg == 'NoSuchKey') {
        await this.copyMasterFile('disabled-bays.json')
        await this.getRemoteDisabledBaysList()
      }
      if (res.status == 'success') {
        this.disabledBays = this.remoteDisableBays
      }
    }
  },
  async mounted() {
    this.fetchBays()
  }
}
</script>

<style scoped>
.btn-forecast {
  color: #fff;
  background-color: #007dbd;
  border-color: #007dbd;
}
.btn-forecast:hover:enabled,
.btn-forecast:active,
.btn-forecast:focus {
  background-color: #00689c !important;
  border-color: #00689c !important;
}
</style>
