<template>
  <div>
    <div class="page-title-box d-flex">
      <h4 class="page-title">
        <i class="fas fa-calendar-check mr-2"></i>Flight Schedule upload
      </h4>
    </div>

    <div class="card pb-0 mb-3">
      <b-row class="mb-3">
        <b-col lg="6">
          <b-form-file
            v-model="file"
            accept=".csv"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            @input="onInput(file)"
            class="text-left"
          >
            <template slot="file-name" slot-scope="{ names }">
              <b-badge class="blue">{{ names[0] }}</b-badge>
              <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                + {{ names.length - 1 }} More files
              </b-badge>
            </template></b-form-file
          >
        </b-col>
        <b-col lg="6" class="d-flex justify-content-left">
          <span id="disabled-wrapper" class="d-inline-block mr-2" tabindex="0">
            <b-button
              class="w-100"
              variant="success"
              :disabled="Boolean(!file)"
              @click="uploadToS3()"
              ><b-spinner v-if="s3ApiLoading == 'upload'" small></b-spinner>
              Upload</b-button
            >
          </span>
          <b-tooltip target="disabled-wrapper" v-if="!file" placement="bottom"
            >Please select a Flight Schedule CSV file first...</b-tooltip
          >
          <b-button v-if="file" class="" @click="clearLocalFile()"
            >Clear</b-button
          >
        </b-col>
      </b-row>

      <b-row
        class="text-left align-items-center mb-3"
        no-gutters
        v-if="remoteFlightScheduleCSV"
        align-h="start"
      >
        <b-img
          :src="require('@/assets/csv-file.png')"
          height="50"
          alt="Responsive image"
          class="mr-2"
        ></b-img>
        <b-row no-gutters class="flex-column">
          <div>
            <span><i>FlightShedule.csv</i></span
            ><b-badge
              class="ml-2 d-felx align-items-center"
              variant="success"
              href="#"
              @click="getRemote(), (fileEdited = false)"
              ><b-spinner
                v-if="s3ApiLoading == 'get'"
                class="mr-2"
                small
              ></b-spinner
              >Refresh</b-badge
            >
          </div>
          <small>
            File last modified:
            {{ remoteFlightScheduleCSV.lastModified }}</small
          >
        </b-row>
      </b-row>
    </div>

    <!-- ----------FILTERS Section ----------------- -->
    <div v-if="fileParsed" class="card mb-3 ">
      <b-form-group class="text-left mb-0">
        <template #label
          ><b-row no-gutters class="align-items-center">
            <h4
              class="header-title pointer mb-0 mr-2"
              v-b-toggle.collapse-filters
            >
              <i class="fas fa-filter mr-2"></i>
              <transition name="slide-fade" mode="out-in">
                <span class="d-inline-block" v-if="collapse" key="less"
                  >FILTERS<i class="ml-2 fas fa-caret-down"></i
                ></span>
                <span class="d-inline-block" v-else key="more"
                  >MORE FILTERS<i class="ml-2 fas fa-caret-right"></i
                ></span>
              </transition>
            </h4>
            <b-badge
              v-if="filterApplied"
              variant="light"
              href="#"
              class="align-self-top"
              @click="clearFilters()"
              >Clear all filters</b-badge
            >
          </b-row></template
        >
        <b-collapse v-model="collapse" id="collapse-filters" class="mb-2">
          <b-row no-gutters>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-select v-model="filter.Type">
                  <b-form-select-option value="" disabled
                    >Type...</b-form-select-option
                  >
                  <b-form-select-option value="Domestic"
                    >Domestic</b-form-select-option
                  >
                  <b-form-select-option value="International"
                    >International</b-form-select-option
                  >
                </b-form-select>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.Type)"
                    @click="filter.Type = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-select v-model="filter.Terminal">
                  <b-form-select-option value="" disabled
                    >Terminal...</b-form-select-option
                  >
                  <b-form-select-option value="Domestic"
                    >Domestic</b-form-select-option
                  >
                  <b-form-select-option value="International"
                    >International</b-form-select-option
                  >
                  <b-form-select-option value="Freight"
                    >Freight</b-form-select-option
                  >
                </b-form-select>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.Terminal)"
                    @click="filter.Terminal = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-input
                  list="airport-list-id"
                  id="filter-arrAirport"
                  v-model="filter.ArrAirport"
                  debounce="300"
                  placeholder="Arrival Airport"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    v-if="Boolean(filter.ArrAirport)"
                    @click="filter.ArrAirport = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <datalist id="airport-list-id">
                <option v-for="(airport, index) in airportList" :key="index">{{
                  airport
                }}</option>
              </datalist>
            </b-col>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-input
                  list="airport-list-id"
                  id="filter-depAirport"
                  v-model="filter.DepAirport"
                  debounce="300"
                  placeholder="Departure Airport"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.DepAirport)"
                    @click="filter.DepAirport = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-select v-model="filter.Direction">
                  <b-form-select-option value="" disabled
                    >Direction...</b-form-select-option
                  >
                  <b-form-select-option value="Arrival"
                    >Arrival</b-form-select-option
                  >
                  <b-form-select-option value="Departure"
                    >Departure</b-form-select-option
                  >
                </b-form-select>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.Direction)"
                    @click="filter.Direction = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-input
                  list="carrier-list-id"
                  id="filter-carrier"
                  v-model="filter.Carrier"
                  debounce="300"
                  placeholder="Carrier"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.Carrier)"
                    @click="filter.Carrier = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <datalist id="carrier-list-id">
              <option v-for="(carrier, index) in carrierList" :key="index">{{
                carrier
              }}</option>
            </datalist>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-input
                  id="filter-flight"
                  v-model="filter.Flight"
                  debounce="300"
                  placeholder="Flight number"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.Flight)"
                    @click="filter.Flight = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <datalist id="acCode-list-id">
              <option v-for="(code, index) in acCodeList" :key="index">{{
                code
              }}</option>
            </datalist>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-input
                  list="acCode-list-id"
                  id="filter-ac-code"
                  v-model="filter.AircraftCode"
                  debounce="300"
                  placeholder="Aircraft Code"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.AircraftCode)"
                    @click="filter.AircraftCode = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col class="mr-2">
              <b-input-group size="sm">
                <b-form-input
                  id="filter-seats"
                  v-model="filter.Seats"
                  debounce="300"
                  placeholder="Number of seats"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="Boolean(!filter.Seats)"
                    @click="filter.Seats = ''"
                    >x</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-collapse>
      </b-form-group>
      <b-row class="w-50" no-gutters>
        <b-col class="text-left mr-2">
          <label for="star-date-filter">Start date:</label>
          <b-form-datepicker
            size="sm"
            id="star-date-filter"
            v-model="filter.StartDate"
            reset-button
          ></b-form-datepicker>
        </b-col>
        <b-col class="text-left">
          <label for="end-date-filter">End date:</label>
          <b-form-datepicker
            size="sm"
            id="end-date-filter"
            v-model="filter.EndDate"
            reset-button
          ></b-form-datepicker>
        </b-col>
      </b-row>
    </div>

    <div v-if="fileParsed" class="card">
      <!-- The New Flight Modal -->
      <b-modal
        id="flight-schedule"
        ref="flight-modal"
        hide-footer
        title="Create new flight"
        ><b-form @submit="onSubmit" inline>
          <b-form-group
            id="input-group-1"
            label="Arrival airport:"
            label-for="input-1"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-select
              id="input-1"
              v-model="flightForm.arrAirport"
              :options="airportList"
              required
              class="w-200"
              @change="setDepAirport($event)"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select ... --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Departure Airport:"
            label-for="input-2"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-select
              id="input-2"
              v-model="flightForm.depAirport"
              :options="airportList"
              placeholder="Enter airport"
              required
              @change="setArrAirport($event)"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select ... --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label="Type:"
            label-for="input-4"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-select
              id="input-4"
              v-model="flightForm.type"
              :options="flightTypes"
              required
            ></b-form-select>
          </b-form-group>

          <!-- Flight Terminal -->
          <b-form-group
            id="input-group-13"
            label="Terminal:"
            label-for="input-13"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-select
              id="input-13"
              v-model="flightForm.terminal"
              :options="TerminalTypes"
              required
            ></b-form-select>
          </b-form-group>

          <!-- Flight Number -->
          <b-form-group
            id="input-group-5"
            label="Flight number:"
            label-for="input-5"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-input
              id="input-5"
              v-model="flightForm.flightNo"
              placeholder="Enter flight number"
              required
            ></b-form-input>
          </b-form-group>

          <!-- Aircraft Code -->
          <b-form-group
            id="input-group-14"
            label="Aircraft Code:"
            label-for="input-14"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-select
              id="input-14"
              v-model="flightForm.acCode"
              :options="acCodeList"
              required
              class="w-200"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select ... --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <!-- Load Factor -->
          <b-form-group
            id="input-group-12"
            label="Load factor:"
            label-for="input-12"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-input
              id="input-12"
              v-model="flightForm.loadFactor"
              placeholder="Enter load factor"
              type="number"
              step="1"
              min="0"
              max="100"
            ></b-form-input>
          </b-form-group>

          <!-- Flight Date -->
          <b-form-group
            id="input-group-6"
            label="Date:"
            label-for="input-6"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-datepicker
              id="input-6"
              v-model="flightForm.date"
              placeholder="Select date"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }"
              locale="en-AU"
              required
            ></b-form-datepicker>
          </b-form-group>

          <!-- Flight Time -->
          <div class="py-1 pr-1 d-flex">
            <label
              for="input-13"
              class="w-200 justify-content-start"
              label="Load factor:"
              label-for="input-12"
              >Time:</label
            >
            <b-input-group class="" label="Time:" label-for="input-13">
              <b-form-input
                id="input-13"
                v-model="flightForm.time"
                type="text"
                placeholder="HH:mm"
                required
              ></b-form-input>
              <b-input-group-append>
                <b-form-timepicker
                  button-only
                  right
                  v-model="flightForm.time"
                  minutes-step="15"
                  locale="en"
                  aria-controls="input-13"
                ></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
          </div>

          <!-- Number of Seats -->
          <b-form-group
            id="input-group-8"
            label="Number of seats:"
            label-for="input-8"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-input
              id="input-8"
              v-model="flightForm.seats"
              placeholder="Enter number of seats"
              type="number"
              step="1"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-10"
            label="Carrier:"
            label-for="input-10"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-select
              id="input-10"
              :options="carrierList"
              v-model="flightForm.carrier"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select ... --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-11"
            label="Repeat flight:"
            label-for="input-11"
            v-slot="{ ariaDescribedby }"
            class="py-1 pr-1"
            label-class="w-200"
          >
            <b-form-checkbox-group
              v-model="flightForm.checked"
              id="checkboxes-11"
              :aria-describedby="ariaDescribedby"
            >
              <b-form-checkbox value="weekly">Weekly</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <b-row no-gutters class="w-100 justify-content-end">
            <b-button type="submit" size="sm" variant="primary" class="mr-2"
              >Submit</b-button
            >
            <b-button type="reset" size="sm" variant="danger">Reset</b-button>
          </b-row>
        </b-form>
      </b-modal>
      <b-row>
        <b-col lg="6" class="d-flex text-left">
          <b-button size="sm" v-b-modal.flight-schedule
            >+ Add new flight</b-button
          >
        </b-col>
        <b-col lg="6" class="d-flex text-right justify-content-end">
          <b-button
            size="sm"
            class="btn-forecast"
            :disabled="!fileEdited"
            @click="saveToCloud()"
            ><b-spinner
              v-if="s3ApiLoading == 'upload'"
              small
              class="mr-2"
            ></b-spinner
            ><i v-else class="fas fa-cloud-upload-alt mr-2"></i>Save
            Changes</b-button
          >
        </b-col>
      </b-row>

      <div>
        <b-table
          ref="table"
          striped
          hover
          :items="fileParsed"
          :fields="fields"
          :filter="null"
          :no-local-sorting="true"
          @sort-changed="sortingChanged"
          responsive="sm"
          class="mt-3"
          per-page="500"
        >
          <template #cell(ArrAirport)="data">
            <b-form-select
              v-if="Boolean(fileParsed[data.index].isEdit)"
              :options="airportList"
              v-model="fileParsed[data.index].ArrAirport"
              class="min-w-90"
            >
            </b-form-select>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(DepAirport)="data">
            <b-form-select
              v-if="Boolean(fileParsed[data.index].isEdit)"
              :options="airportList"
              v-model="fileParsed[data.index].DepAirport"
              class="min-w-90"
            ></b-form-select>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(Carrier)="data">
            <b-form-select
              v-if="Boolean(fileParsed[data.index].isEdit)"
              :options="carrierList"
              v-model="fileParsed[data.index].Carrier"
              class="min-w-90"
            ></b-form-select>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(Direction)="data">
            <b-form-select
              v-if="fileParsed[data.index].isEdit"
              v-model="fileParsed[data.index].Direction"
              :options="['Arrival', 'Departure']"
            ></b-form-select>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(Flight)="data">
            <b-form-input
              v-if="Boolean(fileParsed[data.index].isEdit)"
              type="text"
              v-model="fileParsed[data.index].Flight"
            ></b-form-input>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(AircraftCode)="data">
            <b-form-select
              v-if="Boolean(fileParsed[data.index].isEdit)"
              :options="acCodeList"
              v-model="fileParsed[data.index].AircraftCode"
              class="min-w-90"
            ></b-form-select>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(Schedule)="data">
            <span v-if="fileParsed[data.index].isEdit" style="min-width: 200px">
              <b-form-datepicker
                v-model="fileParsed[data.index].Date"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }"
                locale="en-AU"
              ></b-form-datepicker>
              <b-form-timepicker
                v-model="fileParsed[data.index].Time"
                locale="en-AU"
                minutes-step="5"
              ></b-form-timepicker>
            </span>
            <span v-else>{{ data.value | moment }}</span>
          </template>
          <template #cell(LoadFactor)="data">
            <b-form-input
              v-if="Boolean(fileParsed[data.index].isEdit)"
              type="number"
              min="0"
              step="1"
              max="100"
              :formatter="removeNegative"
              style="min-width: 105px"
              v-model="fileParsed[data.index].LoadFactor"
            ></b-form-input>
            <span v-else>{{
              isNaN(parseInt(data.value)) ? '-' : Math.round(data.value) + '%'
            }}</span>
          </template>
          <template #cell(Seats)="data">
            <b-form-input
              v-if="Boolean(fileParsed[data.index].isEdit)"
              type="number"
              min="0"
              :formatter="removeNegative"
              v-model="fileParsed[data.index].Seats"
            ></b-form-input>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(sel)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(Type)="data">
            <b-form-select
              v-if="fileParsed[data.index].isEdit"
              v-model="fileParsed[data.index].Type"
              :options="['Domestic', 'International']"
            ></b-form-select>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(Terminal)="data">
            <b-form-select
              v-if="fileParsed[data.index].isEdit"
              v-model="fileParsed[data.index].Terminal"
              :options="['Domestic', 'International', 'Freight']"
            ></b-form-select>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(actions)="data">
            <span v-if="!fileParsed[data.index].isEdit">
              <i class="fas fa-edit mr-2" @click="editRow(data)"> </i>
              <i class="far fa-trash-alt" @click="deleteRow(data)"></i>
            </span>
            <span v-else>
              <i class="fas fa-check mr-2" @click="saveRow(data)"></i>
              <i class="fas fa-ban" @click="cancelEditRow(data)"></i>
            </span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FlightSchedule',
  data() {
    return {
      collapse: false,
      moment: new moment(),
      firstFlight: null,
      lastFlight: null,
      flightForm: {
        arrAirport: null,
        carrier: null,
        depAirport: null,
        flightNo: '',
        acCode: null,
        loadFactor: '',
        date: '',
        time: '',
        seats: '',
        timeZone: 'Australia/Darwin',
        type: 'Domestic',
        terminal: 'Domestic',
        checked: []
      },
      flightTypes: [
        { value: 'Domestic', text: 'Domestic' },
        { value: 'International', text: 'International' }
      ],
      TerminalTypes: [
        { value: 'Domestic', text: 'Domestic' },
        { value: 'International', text: 'International' },
        { value: 'Freight', text: 'Freight' }
      ],
      fields: [
        { key: 'ArrAirport', sortable: true },
        { key: 'Carrier', sortable: true },
        { key: 'DepAirport', sortable: true },
        { key: 'Direction', sortable: true },
        { key: 'Flight', sortable: false },
        { key: 'AircraftCode', sortable: true },
        { key: 'LoadFactor', sortable: true },
        { key: 'Schedule', sortable: true },
        { key: 'Seats', sortable: true },
        { key: 'Type', sortable: true },
        { key: 'Terminal', sortable: true },
        { key: 'Actions' }
      ],
      filter: {
        Type: '',
        Terminal: '',
        ArrAirport: '',
        DepAirport: '',
        Direction: '',
        Carrier: '',
        AircraftCode: '',
        Flight: '',
        Seats: '',
        StartDate: '',
        EndDate: ''
      },
      airportList: [
        'DRW',
        'SYD',
        'ADL',
        'ASP',
        'BME',
        'BNE',
        'BTC',
        'CBR',
        'CNS',
        'DIL',
        'DPS',
        'ELC',
        'GOV',
        'GTE',
        'GTS',
        'HKG',
        'ISA',
        'KNX',
        'KTR',
        'LHR',
        'MCV',
        'MEL',
        'MGT',
        'MNG',
        'PER',
        'ROR',
        'SIN',
        'TSV',
        'XMN'
      ],
      carrierList: [
        'JQ',
        'QF',
        'QQ',
        'TL',
        'VA',
        'TFR',
        'HJ',
        '3K',
        'MF',
        'SQ'
      ],
      acCodeList: [
        '320',
        'E90',
        '73H',
        '76Y',
        '332',
        '333',
        'E70',
        'EM2',
        '73F',
        '76F',
        '787',
        '789',
        '738'
      ],
      selectedFlights: [],
      selectStatus: false,
      remoteFileLength: 0,
      localFileLength: 0,

      // CSV file info
      fileEdited: false, //Flag that Flight Schedule was edited - enable save changes button
      file: null,
      fileParsed: null,
      remoteFileParsed: null,
      fileFiltered: null,

      // Radio button select
      selected: 'remote'
    }
  },
  computed: {
    ...mapGetters(['s3ApiLoading', 'remoteFlightScheduleCSV', 'authUser']),
    time() {
      return this.flightForm.time
    },
    filterApplied() {
      for (const value of Object.entries(this.filter)) {
        if (value[1] != '') return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations(['setFileContent', 'setActive']),
    ...mapActions([
      'uploadFlightSchedule',
      'getRemoteFlightSchedule',
      'copyMasterFile'
    ]),
    uploadToS3: async function() {
      let res = await this.uploadFlightSchedule()
      if (res.status == 'success') {
        this.makeToast('success', 'File upload successful')
      } else if (res.status == 'error') {
        this.makeToast('danger', res.err_msg)
      }
    },
    clearFilters: function() {
      for (const [key, value] of Object.entries(this.filter)) {
        if (value != '') this.filter[key] = ''
      }
    },
    clearLocalFile: function() {
      this.file = null
    },
    onInput: function() {
      if (this.file) {
        this.getContent()
      }
    },
    getContent: function() {
      const reader = new FileReader()
      reader.onload = res => {
        // used to remove 5 empty lines (disabled for now)
        // let array = res.target.result.split('\n')
        // array.splice(0, 5)
        // this.content = array.join('\n')

        this.parseLocalCSV(res.target.result)
      }
      reader.onerror = err => console.log(err)
      reader.readAsText(this.file)
    },
    parseLocalCSV(file) {
      try {
        this.$papa.parse(file, {
          delimiter: ',',
          header: true,
          skipEmptyLines: true,
          transformHeader: header => {
            switch (header) {
              case 'Carrier Code':
                return 'Carrier'
              case 'Dep Airport Code':
                return 'DepAirport'
              case 'Arr Airport Code':
                return 'ArrAirport'
              case 'International/Domestic':
                return 'Type'
              case 'Seats (Total)':
                return 'Seats'
              case 'Flight No':
                return 'Flight'
              case 'Time series':
                return 'Date'
              case 'Specific Aircraft Code':
                return 'AircraftCode'
              case 'Local Dep Time':
                return 'DepTime'
              case 'Local Arr Time':
                return 'ArrTime'
              default:
                return header
            }
          },
          transform: (value, header) => {
            switch (header) {
              case 'DepTime': {
                value = value.padStart(4, 0)
                value = value.substring(0, 2) + ':' + value.substring(2)
                return value
              }
              case 'ArrTime': {
                value = value.padStart(4, 0)
                value = value.substring(0, 2) + ':' + value.substring(2)
                return value
              }
              case 'Date': {
                let array = value.split('/')
                let date =
                  array[2] + '-' + array[1] + '-' + array[0].padStart(2, 0)
                return date
              }
              default:
                return value
            }
          },

          complete: async results => {
            let res = await this.combineDateTime(results.data)
            let content = await this.$papa.unparse(res, {})
            this.setFileContent(content)
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    // Combine date and time for Schedule column
    // Add aditional day to Arrival date if plane lands next day
    combineDateTime: async function(res) {
      await res.forEach(item => {
        let time = '00:00'
        let date = '1985-10-02'

        item.Timezone = 'Australia/Darwin'
        if (item.DepAirport == 'DRW') {
          item.Direction = 'Departure'
          time = item.DepTime
          date = item.Date
        } else {
          item.Direction = 'Arrival'
          time = item.ArrTime
          date = item.Date
          if (Number(item['Local Arr Day']) > 0) {
            let newDate = new Date(item.Date)
            newDate.setDate(newDate.getDate() + Number(item['Local Arr Day']))
            date = moment(newDate).format('YYYY-MM-DD')
          }
        }
        item.Schedule = date + ' ' + time
      })
      return res
    },
    parseCSV: function(file) {
      try {
        this.$papa.parse(file, {
          delimiter: ',',
          header: true,
          skipEmptyLines: true,
          complete: results => {
            // Used to Extract select data from Flight Schedule
            // let acArray = []
            // let airportArr = []
            // let carrierArr = []

            this.remoteFileLength = results.data.length
            this.remoteFileParsed = results.data

            this.firstFlight = new Date(results.data[0].Schedule)
            this.lastFlight = new Date(results.data[0].Schedule)

            this.remoteFileParsed.forEach((item, index) => {
              // Used to Extract select data from Flight Schedule
              // if (!acArray.includes(item.AircraftCode)) {
              //   acArray.push(item.AircraftCode)
              // }
              // if (!airportArr.includes(item.ArrAirport)) {
              //   airportArr.push(item.ArrAirport)
              // }
              // if (!airportArr.includes(item.DepAirport)) {
              //   airportArr.push(item.DepAirport)
              // }
              // if (!carrierArr.includes(item.Carrier)) {
              //   carrierArr.push(item.Carrier)
              // }

              let date = new Date(item.Schedule)
              if (date < this.firstFlight) {
                this.firstFlight = date
              } else if (date > this.lastFlight) {
                this.lastFlight = date
              }

              item.id = index
              item.LoadFactor = Number.parseFloat(item.LoadFactor)
              item.Seats = Number(item.Seats)
              item.Schedule = date
              item.Date = this.getDate(item.Schedule)
              item.Time = this.getTime(item.Schedule)
            })
            this.fileParsed = JSON.parse(
              JSON.stringify(this.remoteFileParsed.slice(0, 200))
            )

            // Used to Extract select data from Flight Schedule
            // console.log('Aircradt Code: ' + acArray.sort())
            // console.log('Airport: ' + airportArr.sort())
            // console.log('Carrier: ' + carrierArr.sort())
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    saveToCloud: async function() {
      let tmpFile = JSON.parse(JSON.stringify(this.remoteFileParsed))
      await tmpFile.forEach(item => {
        let dateTimeString =
          moment(item.Schedule).format('YYYY-MM-DD') +
          ' ' +
          moment(item.Schedule).format('HH:mm')
        item.Schedule = dateTimeString
      })
      let content = this.$papa.unparse(tmpFile, {
        columns: [
          'DepAirport',
          'Carrier',
          'ArrAirport',
          'Direction',
          'Flight',
          'Schedule',
          'LoadFactor',
          'Seats',
          'Type',
          'Timezone',
          'AircraftCode',
          'Terminal'
        ]
      })
      this.setFileContent(content)
      let res = await this.uploadFlightSchedule()
      if (res.status == 'success') {
        this.file = null
        this.fileEdited = false
        this.makeToast('success', 'Changes saved successfully')
      } else if (res.status == 'error') {
        this.makeToast('danger', res.err_msg)
      }
    },
    getRemote: async function() {
      let res = await this.getRemoteFlightSchedule()
      if (res.status == 'success') {
        this.file = null
        this.fileEdited = false
        this.makeToast('success', 'File content refreshed successfully')
      } else if (res.status == 'error') {
        this.makeToast('danger', res.err_msg)
      }
    },
    makeToast(variant = null, text = '') {
      this.$bvToast.toast(text, {
        title: `Remote S3 bucket status`,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    },
    onSubmit: async function(event) {
      let flightDetails = {}
      event.preventDefault()
      flightDetails = {
        id: this.remoteFileLength,
        ArrAirport: this.flightForm.arrAirport,
        Carrier: this.flightForm.carrier,
        DepAirport: this.flightForm.depAirport,
        Direction: this.getDirection(),
        Flight: this.flightForm.flightNo,
        AircraftCode: this.flightForm.acCode,
        LoadFactor: this.flightForm.loadFactor.trim() == '' ? '' : Number(this.flightForm.loadFactor),
        Schedule: new Date(this.flightForm.date + ' ' + this.flightForm.time),
        Date: this.flightForm.date,
        Time: this.flightForm.time,
        Seats: Number(this.flightForm.seats),
        Type: this.flightForm.type,
        Terminal: this.flightForm.terminal,
        Timezone: this.flightForm.timeZone
      }

      await this.addFlight(flightDetails) // Add new Flight

      // Check if Repeat flight option is selected and create additinal Flights
      if (this.flightForm.checked.length != 0) {
        await this.repeatFlights(flightDetails)
      }

      this.$refs['flight-modal'].hide()
      this.fileEdited = true
    },
    repeatFlights: async function(flight) {
      let date = moment(flight.Schedule)
      date.add(7, 'days')
      let end = moment(this.lastFlight)
      for (var m = date; m.diff(end, 'days') <= 0; m.add(7, 'days')) {
        let newFlight = JSON.parse(JSON.stringify(flight))
        newFlight.id = this.remoteFileLength
        newFlight.Schedule = new Date(m)
        newFlight.Date = m.format('YYYY-MM-DD')
        this.addFlight(newFlight)
      }
      return
    },
    addFlight: async function(flight) {
      this.remoteFileParsed.unshift(flight)
      this.remoteFileLength++

      if (this.fileFiltered) {
        this.fileFiltered.unshift(flight)
      }
      this.fileParsed.unshift(flight)
      return
    },
    setArrAirport: function(e) {
      if (e != 'DRW') return (this.flightForm.arrAirport = 'DRW')
      if (this.flightForm.arrAirport != 'DRW') return
      this.flightForm.arrAirport = null
    },
    setDepAirport: function(e) {
      if (e != 'DRW') return (this.flightForm.depAirport = 'DRW')
      if (this.flightForm.depAirport != 'DRW') return
      this.flightForm.depAirport = null
    },
    getDirection: function() {
      if (this.flightForm.arrAirport == 'DRW') return 'Arrival'
      return 'Departure'
    },
    enableRemove: function() {
      if (!this.selectStatus) {
        this.fields.unshift('Sel')
      } else {
        this.fields.splice(0, 1)
      }
      this.selectStatus = !this.selectStatus
    },
    //disable select option for now
    // onRowSelected(items) {
    //   this.selectedFlights = items
    // },
    sortFlightSchedule: function(file, ctx) {
      let sortFile = this[`${file}`]
      let sortType = typeof sortFile[0][`${ctx.sortBy}`]

      if (sortType == 'number' || sortType == 'object') {
        if (!ctx.sortDesc) {
          sortFile.sort(function(a, b) {
            return a[`${ctx.sortBy}`] - b[`${ctx.sortBy}`]
          })
        } else {
          sortFile.sort(function(a, b) {
            return b[`${ctx.sortBy}`] - a[`${ctx.sortBy}`]
          })
        }
      }
      if (sortType == 'string') {
        if (!ctx.sortDesc) {
          sortFile.sort(function(a, b) {
            if (a[`${ctx.sortBy}`] < b[`${ctx.sortBy}`]) {
              return -1
            }
            if (a[`${ctx.sortBy}`] > b[`${ctx.sortBy}`]) {
              return 1
            }
            return 0
          })
        } else {
          sortFile.sort(function(a, b) {
            if (a[`${ctx.sortBy}`] < b[`${ctx.sortBy}`]) {
              return 1
            }
            if (a[`${ctx.sortBy}`] > b[`${ctx.sortBy}`]) {
              return -1
            }
            return 0
          })
        }
      }
    },
    sortingChanged(ctx) {
      this.sortFlightSchedule('remoteFileParsed', ctx)
      if (!this.fileFiltered) {
        this.fileParsed = JSON.parse(
          JSON.stringify(this.remoteFileParsed.slice(0, 200))
        )
      } else {
        this.sortFlightSchedule('fileFiltered', ctx)
        this.fileParsed = JSON.parse(
          JSON.stringify(this.fileFiltered.slice(0, 200))
        )
      }
    },
    editRow(data) {
      this.fileParsed[data.index].isEdit = true
      this.$refs.table.refresh()
    },
    cancelEditRow(data) {
      let tempRow = {} //Store original data to restore changed values
      let rowID = data.item.id //Original file row ID

      tempRow = this.remoteFileParsed.find(obj => {
        return obj.id == rowID
      })

      this.fileParsed[data.index] = JSON.parse(JSON.stringify(tempRow))
      this.fileParsed[data.index].isEdit = false
      this.$refs.table.refresh()
    },
    findOriginalIdex: function(id) {
      let orgID = this.remoteFileParsed.findIndex(obj => {
        return obj.id == id
      })
      return orgID
    },
    saveRow(data) {
      let rowID = data.item.id //Original file row ID
      let originalIndex = -1

      originalIndex = this.findOriginalIdex(rowID)
      let dateTime = data.item.Date + ' ' + data.item.Time

      data.item.Schedule = new Date(dateTime)
      this.remoteFileParsed[originalIndex] = data.item
      data.item.isEdit = false
      this.fileEdited = true //Flag that Flight Schedule was edited (show save changes button)
    },
    deleteRow(data) {
      let rowID = data.item.id //Original file row ID
      let originalIndex = -1

      originalIndex = this.findOriginalIdex(rowID)

      this.remoteFileParsed.splice(originalIndex, 1)
      this.fileParsed.splice(data.index, 1)
      if (this.fileFiltered) {
        let filteredIndex = -1
        filteredIndex = this.fileFiltered.findIndex(obj => {
          return obj.id == rowID
        })
        this.fileFiltered.splice(filteredIndex, 1)
        this.fileParsed = JSON.parse(
          JSON.stringify(this.fileFiltered.slice(0, 200))
        )
      } else {
        this.fileParsed = JSON.parse(
          JSON.stringify(this.remoteFileParsed.slice(0, 200))
        )
      }
      this.fileEdited = true //Flag that Flight Schedule was edited (show save changes button)
    },
    removeNegative: function(value) {
      if (value < 0) {
        return Math.abs(value)
      } else {
        return value
      }
    },
    getDate: function(dateTime) {
      return moment(dateTime).format('YYYY-MM-DD')
    },
    getTime: function(dateTime) {
      return moment(dateTime).format('HH:mm')
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  },
  watch: {
    remoteFlightScheduleCSV: {
      handler(current) {
        this.parseCSV(current.file)
      },
      deep: true
    },
    time() {
      this.flightForm.time = this.flightForm.time
        .split(':')
        .slice(0, 2)
        .join(':')
    },
    filter: {
      handler(current) {
        let filtersCount = 0
        this.fileFiltered = this.remoteFileParsed
        for (const [key, value] of Object.entries(current)) {
          let keyword = value.toLowerCase().trim()
          if (keyword) {
            if (key == 'StartDate') {
              keyword = new Date(keyword + ' 00:00:00')
            }
            if (key == 'EndDate') {
              keyword = new Date(keyword + ' 23:59:59')
            }
            filtersCount++
            this.fileFiltered = this.fileFiltered.filter(item => {
              const type = typeof item[`${key}`]
              if (type == 'string') {
                return item[`${key}`].toLowerCase().indexOf(keyword) > -1
              }
              if (type == 'number') {
                return item[`${key}`].toString().indexOf(keyword) > -1
              }
              if (key == 'StartDate') {
                return item.Schedule >= keyword
              }
              if (key == 'EndDate') {
                return item.Schedule <= keyword
              }
            })
          }
        }
        this.fileParsed = this.fileFiltered.slice(0, 200)
        if (filtersCount == 0) {
          this.fileFiltered = null
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.setActive('flight-schedule')
    let res = await this.getRemoteFlightSchedule()
    if (res.err_msg == 'NoSuchKey') {
      await this.copyMasterFile('flightschedule.csv')
      await this.getRemoteFlightSchedule()
    }
  }
}
</script>
<style>
.w-200 {
  width: 150px;
}
.min-w-90 {
  min-width: 90px;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.5;
}
</style>

<style scoped lang="scss">
@import '../assets/css/variables';
.card-bg {
  background-color: #f1f3fa;
}
.blue {
  background-color: map-get($colors, primary);
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
}
.card {
  border: none;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  margin-bottom: 24px;
  background-color: #f1f3fa;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem;
  padding: 1.5rem 1.5rem;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
  font-weight: 700;
  color: #6c757d;
}
.btn-forecast {
  color: #fff;
  background-color: #007dbd;
  border-color: #007dbd;
}
.btn-forecast:hover:enabled,
.btn-forecast:active,
.btn-forecast:focus {
  background-color: #00689c !important;
  border-color: #00689c !important;
}
@media (max-width: 992px) {
  .w-25 {
    width: 100% !important;
  }
}
@media (max-width: 1200px) {
  .w-25 {
    width: 50% !important;
  }
}
.pl-24 {
  padding-left: 28px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.fa-check:hover {
  color: green;
}
.fa-ban:hover {
  color: red;
}
.fa-trash-alt:hover {
  color: red;
}
.fa-edit:hover {
  color: blue;
}
</style>
