<template>
  <div id="app">
    <!-- Start Content-->
    <div class="container-fluid">
      <!-- Begin page -->
      <div class="wrapper">
        <!-- ========== Left Sidebar Start ========== -->
        <div class="leftside-menu">
          <div class="leftbar-user">
            <a href="#">
              <img
                src="../assets/logo.svg"
                alt="user-image"
                height="55"
                class=""
              />
            </a>
          </div>
          <!--- Sidemenu -->
          <ul class="side-nav text-left">
            <li class="side-nav-title">Simulation</li>
            <li class="side-nav-item" @click="setActive('simulation')">
              <router-link
                to="/run"
                class="side-nav-link"
                :class="{ active: active === 'simulation' }"
              >
                <i class="fas fa-plane"></i>
                <span> DIA DT Simulation</span>
              </router-link>
            </li>
            <li class="side-nav-item" @click="setActive('insights')">
              <router-link
                to="/insights"
                class="side-nav-link"
                :class="{ active: active === 'insights' }"
              >
                <i class="fas fa-file-contract"></i>
                <span> Insights </span>
              </router-link>
            </li>
            <li class="side-nav-item" @click="setActive('flight-schedule')">
              <router-link
                to="/flight-schedule"
                class="side-nav-link"
                :class="{ active: active === 'flight-schedule' }"
              >
                <i class="fas fa-calendar-alt"></i>
                <span> Flight Schedule </span>
              </router-link>
            </li>
            <li class="side-nav-item" @click="setActive('node-config')">
              <router-link
                to="/node-config"
                class="side-nav-link"
                :class="{ active: active === 'node-config' }"
              >
                <i class="fas fa-cogs"></i>
                <span> Node Configuration </span>
              </router-link>
            </li>
            <li class="side-nav-item" @click="setActive('history')">
              <router-link
                class="side-nav-link"
                :class="{ active: active === 'history' }"
                :to="{ name: 'history' }"
                v-b-toggle.sidebarHistory
              >
                <i class="fas fa-history"></i>
                <span> Simulation Replay </span>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Left Sidebar End -->

        <router-view class="content-page"></router-view>
      </div>
      <!-- end wrapper-->
    </div>
    <!-- END Container -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'SideNavigation',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['error', 'active'])
  },
  methods: {
    ...mapMutations(['setActive']),
    ...mapActions([])

    // --------  Convert RunId to date format  ---------
    // --------   Disable for now ------------
    //   convertDate: function(date) {
    //       let newDate = moment(date.slice(0, -5))
    //       let dateUTC =  newDate.tz('UTC')
    //       return dateUTC.tz('Australia/Broken_Hill').format('MMMM Do YYYY, h:mm:ss a')
    //   }
  },
  filters: {
    runID: function(id) {
      const timestamp = parseInt(id)
      const date = moment(timestamp / 1000000).format('YYYY-MM-DD HH:mm')
      return 'Run(' + date + ')'
    }
  },
  async mounted() {},
  watch: {
    error: function(newError) {
      this.$bvToast.toast(newError.message, {
        title: 'S3 ERROR',
        toaster: 'b-toaster-top-right',
        autoHideDelay: 2000,
        variant: 'danger'
      })
    }
  }
}
</script>

<style>
#app {
  font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {
  text-decoration: none;
}
/* Wrapper */
.wrapper {
  display: flex;
  overflow: inherit;
  height: 100%;
  width: 100%;
}
.content-page {
  margin-left: 0;
  overflow: hidden;
  padding: 0 15px 5px 30px;
  position: relative;
  margin-right: -15px;
  width: 100%;
  padding-bottom: 50px;
  /* min-height: 100vh; */
}
.leftside-menu {
  position: relative;
  background: #f1f3fa !important;
  min-width: 260px;
  max-width: 260px;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  padding-top: 0 !important;
  z-index: 1001 !important;
  width: 260px;
  z-index: 10;
  min-height: 700px;
  background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
  bottom: 0;
  top: 0;
  padding-top: 70px;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}
.leftbar-user {
  background: url(../assets/waves.png) no-repeat;
  padding: 30px 20px;
  text-align: center;
}
.side-nav {
  padding-left: 0;
  list-style-type: none;
}
.side-nav-title {
  color: #6c757d;
  padding: 12px 30px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
}
.side-nav-item a:hover {
  background-color: #e4eff9;
  text-decoration: none;
}
.active {
  -webkit-box-shadow: 3px 0 0 0 #007dbd inset;
  box-shadow: 3px 0 0 0 #007dbd inset;
}
.side-nav-link {
  color: #6c757d;
  display: block;
  padding: 10px 30px;
  font-size: 0.9375rem;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  list-style: none;
}
.side-nav-link i {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 1.1rem;
  vertical-align: middle;
  width: 20px;
}
.side-nav .side-nav-link:active,
.side-nav .side-nav-link:focus,
.side-nav .side-nav-link:hover {
  color: #fff;
  text-decoration: none;
}
.leftside-menu .side-nav .side-nav-link:active,
.leftside-menu .side-nav .side-nav-link:focus,
.leftside-menu .side-nav .side-nav-link:hover {
  color: #007dbd !important; /* DIA logo blue color */
}
.side-nav ul {
  list-style-type: none;
  padding-left: 0;
}
.side-nav-forth-level li a,
.side-nav-second-level li a,
.side-nav-third-level li a {
  padding: 8px 30px 8px 65px;
  color: #6c757d;
  display: block;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 0.89rem;
}
.side-nav-second-level a:active,
.side-nav-second-level a:focus,
.side-nav-second-level a:hover {
  color: #007dbd !important; /* DIA logo blue color */
}
.side-nav .side-nav-link i {
  vertical-align: middle;
}
.router-link-active {
  color: #007dbd !important;
}
</style>
