<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm table-centered mb-0 font-14">
      <thead class="table-dia">
        <tr>
          <th>Service of Digital Twin</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr>
          <td>
            Airport Simulation
          </td>
          <td>
            <small>
              <b-alert
                :variant="
                  remoteSimulationStatus.SIMULATION == 'ERROR'
                    ? 'danger'
                    : 'info'
                "
                show
                class="mb-0 py-1"
                ><b-spinner
                  v-if="
                    remoteSimulationStatus.SIMULATION != 'IDLE' &&
                      remoteSimulationStatus.SIMULATION != 'ERROR'
                  "
                  class="mr-2"
                  small
                ></b-spinner
                ><i
                  v-if="remoteSimulationStatus.SIMULATION == 'ERROR'"
                  class="fas fa-exclamation-triangle mr-2"
                ></i
                >{{ remoteSimulationStatus.SIMULATION }}</b-alert
              ></small
            >
          </td>
          <td>
            <span
              id="disabled-wrapper"
              class="d-inline-block mr-2"
              tabindex="0"
            >
              <b-button
                v-if="remoteSimulationStatus.SIMULATION != 'ERROR'"
                v-on:click="$bvModal.show('start-sim')"
                variant="success"
                size="sm"
                id="start-button"
                :disabled="
                  remoteSimulationStatus.SIMULATION != 'IDLE' ||
                    stepStatus.step1 != 'DONE' ||
                    stepStatus.step2 != 'DONE'
                "
                class=""
              >
                <b-spinner
                  v-if="apiLoading == 'run'"
                  class="mr-2"
                  small
                ></b-spinner>
                Start
              </b-button>
            </span>
            <b-button
              v-if="adminOn"
              v-on:click="simAction('init')"
              variant="success"
              size="sm"
              class="mr-2"
            >
              <b-spinner v-if="apiLoading == 'init'" small></b-spinner>
              Initialise
            </b-button>
            <b-button
              v-on:click="resetRun('SIMULATION')"
              variant="warning"
              size="sm"
              class="mr-2"
            >
              <b-spinner v-if="apiLoading == 'reset'" small></b-spinner>
              Reset
            </b-button>
          </td>
        </tr>
        <tr>
          <td>Load Factor Calculation</td>
          <td>
            <small>
              <b-alert
                :variant="
                  remoteSimulationStatus.LAODFACTOR == 'ERROR'
                    ? 'danger'
                    : 'info'
                "
                show
                class="mb-0 py-1"
                ><b-spinner
                  v-if="
                    remoteSimulationStatus.LAODFACTOR != 'IDLE' &&
                      remoteSimulationStatus.LAODFACTOR != 'ERROR'
                  "
                  class="mr-2"
                  small
                ></b-spinner
                ><i
                  v-if="remoteSimulationStatus.LAODFACTOR == 'ERROR'"
                  class="fas fa-exclamation-triangle mr-2"
                ></i
                >{{ remoteSimulationStatus.LAODFACTOR }}</b-alert
              ></small
            >
          </td>
          <td>
            <b-button
              v-if="remoteSimulationStatus.LAODFACTOR != 'IDLE'"
              v-on:click="resetRun('LOADFACTOR')"
              variant="warning"
              size="sm"
              class="mr-2"
            >
              <b-spinner v-if="apiLoading == 'reset'" small></b-spinner>
              Reset
            </b-button>
          </td>
        </tr>
        <tr>
          <td>Gate and Bay Allocation</td>
          <td class="">
            <small>
              <b-alert
                :variant="
                  remoteSimulationStatus.GATEBAY == 'ERROR' ? 'danger' : 'info'
                "
                show
                class="mb-0 py-1"
                ><b-spinner
                  v-if="
                    remoteSimulationStatus.GATEBAY != 'IDLE' &&
                      remoteSimulationStatus.GATEBAY != 'ERROR'
                  "
                  class="mr-2"
                  small
                ></b-spinner
                ><i
                  v-if="remoteSimulationStatus.GATEBAY == 'ERROR'"
                  class="fas fa-exclamation-triangle mr-2"
                ></i
                >{{ remoteSimulationStatus.GATEBAY }}</b-alert
              >
            </small>
          </td>
          <td>
            <b-button
              v-if="remoteSimulationStatus.GATEBAY != 'IDLE'"
              v-on:click="resetRun('GATEBAY')"
              variant="warning"
              size="sm"
              class="mr-2"
            >
              <b-spinner v-if="apiLoading == 'reset'" small></b-spinner>
              Reset
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal id="start-sim" body-class="header-title" :hide-footer="true">
      <template #modal-title
        ><i class="fas fa-plane mr-2"></i>Start Simulation</template
      >
      <label for="name-input"><h5 class="my-0">Name:</h5></label>
      <ValidationProvider
        :rules="{
          regex1: /^[^\\/:*?&quot;<>|]+$/,
          regex2: /^(?!\.)/,
          regex3: /^(?!(none|nul|prn|con|lpt[0-9]|com[0-9])(\..+)?$)/i
        }"
        v-slot="v"
      >
        <b-form
          id="name-form"
          @submit.prevent="
            simAction('run', simName ? { runname: simName } : null)
          "
        >
          <b-form-input
            v-model="simName"
            id="name-input"
            placeholder="Enter simulation name"
          ></b-form-input
          ><small class="text-danger">{{ v.errors[0] }}</small>
        </b-form>

        <h5 class="header-title mt-3">
          {{ simName ? simName : dateNow }}
        </h5>
        <div class="p-2 extra-info mb-3" v-if="remoteFlightScheduleCSV">
          <p class="mb-1">
            Number of Flights:
            <span class="text-info">{{
              remoteFlightScheduleCSV.flightNo
            }}</span>
          </p>
          <p class="mb-1">
            First Flight:
            <span class="text-info">{{
              remoteFlightScheduleCSV.firstFlight
            }}</span>
          </p>
          <p class="mb-1">
            Last Flight:
            <span class="text-info">{{
              remoteFlightScheduleCSV.lastFlight
            }}</span>
          </p>
        </div>
        <b-row no-gutters class="justify-content-end">
          <b-button
            form="name-form"
            :disabled="v.invalid"
            class="mr-2"
            variant="success"
            type="submit"
            @click="$bvModal.hide('start-sim')"
          >
            Start
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="$bvModal.hide('start-sim')"
          >
            Cancel
          </b-button>
        </b-row>
      </ValidationProvider>
    </b-modal>
    <b-tooltip
      placement="left"
      variant="warning"
      target="disabled-wrapper"
      v-if="remoteSimulationStatus.SIMULATION != 'IDLE'"
      >Please wait for current Simulation to finish...</b-tooltip
    >
    <b-tooltip
      placement="left"
      variant="warning"
      target="disabled-wrapper"
      v-if="stepStatus.step1 != 'DONE'"
      >Please calculate Load Factor first</b-tooltip
    >
    <b-tooltip
      placement="left"
      target="disabled-wrapper"
      variant="warning"
      v-if="stepStatus.step2 != 'DONE'"
      >Please allocate Gate and Bay first</b-tooltip
    >
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { regex } from 'vee-validate/dist/rules'

extend('regex1', {
  ...regex,
  message: `The following characters are not allowed: \\ / : * ? " < > |`
})

extend('regex2', {
  ...regex,
  message: `File name cannot start with dot (.)`
})

extend('regex3', {
  ...regex,
  message: `This file name is forbidden`
})

import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
export default {
  components: {
    ValidationProvider
  },
  name: 'StatusTable',
  data() {
    return {
      params: { type: '', method: '' },
      simName: '',
      adminOn: false
    }
  },
  computed: {
    ...mapGetters([
      'remoteSimulationStatus',
      'remoteFlightScheduleCSV',
      'flightNo',
      'firstFlight',
      'lastFlight',
      'authUser',
      'apiLoading',
      'stepStatus'
    ]),
    dateNow() {
      return 'Run(' + this.$options.filters.moment(Date.now()) + ')'
    }
  },
  methods: {
    ...mapActions(['simulationAction']),
    ...mapMutations(['setAxiosConfig', 'setRemoteSimStatus']),
    resetRun: async function(service) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to reset ' + service + ' run?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false
        })
        .then(confirm => {
          if (confirm) {
            this.simAction('reset')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    simAction: async function(type, params = null) {
      this.params.type = type
      this.params.method = 'post'
      this.params.content = params
      this.setAxiosConfig(this.authUser.signInUserSession.idToken.jwtToken)
      if (type == 'run') {
        this.setRemoteSimStatus({
          single: 'SIMULATION',
          status: 'INITIALISING...'
        })
      }
      const res = await this.simulationAction(this.params)
      if (res.status == 'success' && type == 'reset') {
        this.setRemoteSimStatus({
          status: {
            SIMULATION: 'IDLE',
            GATEBAY: 'IDLE',
            LAODFACTOR: 'IDLE'
          }
        })
      }
      this.parseResponse(res)
    },
    parseResponse: function(res) {
      if (res.status == 'success') {
        this.$emit('showApiMessage', {
          message: res.content,
          status: res.status
        })
      }
      if (res.status == 'error') {
        this.$emit('showApiMessage', {
          message: res.err_msg,
          status: res.status
        })
      }
    },

    // Admin Keyboard Key combination to show Initialise Simulation button (Shift+Alt+G)
    handler(e) {
      if (e.altKey && e.shiftKey && e.key == 'G') this.adminOn = true
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  },
  created: function() {
    window.addEventListener('keydown', this.handler)
  },
  destroyed: function() {
    window.removeEventListener('keydown', this.handler)
  }
}
</script>

<style scoped>
.header-title h5 {
  margin-bottom: 0.5rem;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin: 10px 0;
  color: #6c757d;
}
.header-title .extra-info {
  border: 1px solid #a3d1fc;
  border-radius: 5px;
  -webkit-box-shadow: inset 0px 0px 10px 3px #d1e5f8;
  -moz-box-shadow: inset 0px 0px 10px 3px #d1e5f8;
  box-shadow: inset 0px 0px 10px 3px #d1e5f8;
  transition: box-shadow 0.3s linear;
}
</style>
