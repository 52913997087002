import axios from 'axios'

const state = {
  bAPI: `${process.env.VUE_APP_BASE_API_URL}/`, // Protected API from Phase 2
  quicksightApi: 'getqsurl',
  qsApiLoading: ''
}
const getters = {
  bAPI: state => state.bAPI,
  quicksightApi: state => state.quicksightApi,
  qsApiLoading: state => state.qsApiLoading
}
const actions = {
  async getQuicksightURL({ commit, getters }, token) {
    try {
      const config = {
        headers: {
          Authorization: token
        }
      }
      console.log(config)
      const URI = getters.bAPI + getters.quicksightApi
      commit('SetQsApiLoading', 'quicksight')
      const res = await axios.get(URI, config)
      commit('SetQsApiLoading', '')
      let apiMessage = res.data['message']
      return { status: 'success', response: res, content: apiMessage }
    } catch (error) {
      commit('SetQsApiLoading', '')
      let message = ''
      if (error.response.status === 401) {
        message = error.response.data['message']
      } else {
        message = error.message
      }
      return { status: 'error', err_msg: message }
    }
  }
}
const mutations = {
  SetQsApiLoading: (state, option) => (state.qsApiLoading = option)
}

export default {
  state,
  getters,
  actions,
  mutations
}
