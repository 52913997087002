<template>
  <div class="">
    <div class="page-title-box d-flex">
      <h4 class="page-title">
        <i class="fas fa-history mr-2"></i>
        Simulation Replay
      </h4>
    </div>
    <s-3-controls ref="s3"></s-3-controls>
    <div class="card flex-column align-items-center">
      <!---- Show if simulation is not initialised  ------>
      <b-row
        class="align-items-center"
        v-if="apiLoading == 'analysisList' && !simulationList"
        no-gutters
      >
        <b-spinner class="mr-2" small></b-spinner>
        <span>Loading...</span>
      </b-row>
      <b-row
        class=""
        v-if="!simulationList && apiLoading != 'analysisList'"
        no-gutters
        >You have no Simulation runs, please
        <router-link to="/run" class=" mx-2">Run Simulation</router-link> first!
      </b-row>
      <b-row
        v-if="simulationList && !simulationData"
        class="justify-content-center d-flex flex-row w-50 align-items-center align-self-start"
        no-gutters
      >
        <b-col class="d-flex justify-content-end mr-2"
          >Select Simulation:</b-col
        >
        <b-col class="">
          <b-select :value="selected" v-model="selected">
            <b-select-option :value="null" disabled
              >Please select...</b-select-option
            >
            <b-select-option
              v-for="run in simulationList"
              :value="run.Run"
              :key="run.Run"
              ><span v-if="run.RunName">{{ run.RunName }}</span>
              <span v-else>{{ run.Run | runIdToTime }}</span></b-select-option
            >
          </b-select>
        </b-col>
        <b-col class="d-flex justify-content-start ml-2">
          <!---- Call API Button ---->
          <b-button
            v-on:click="$refs.s3.fetchSimFiles(selected)"
            :disabled="!Boolean(selected)"
            variant="success"
            size="sm"
            class="mr-3"
          >
            <b-spinner v-if="analysisLoading" small></b-spinner>
            Initialise
          </b-button>
        </b-col>
      </b-row>

      <!---- Show if simulation is loaded from s3 ------>
      <div
        v-if="simulationData"
        class="d-flex flex-row align-items-center w-100"
      >
        <span v-if="simulationData.runID" class="mr-2 text-nowrap">
          <h5
            v-if="simulationList[simulationData.runID].RunName"
            class="header-title"
          >
            {{ simulationList[simulationData.runID].RunName }}
          </h5>
          <h5 v-else class="header-title">
            {{ simulationData.runID | runIdToTime }}
          </h5>
        </span>
        <!---- Start Simulation Button ---->
        <b-button
          :disabled="disabled"
          v-on:click="startSimulation()"
          variant="success"
          size="sm"
          class="mr-2"
        >
          Start
        </b-button>

        <!---- Stop Button ---->
        <b-button
          :disabled="!disabled"
          v-on:click="stopClock(), makeSimpleToast('warning', 'Stopped')"
          variant="warning"
          size="sm"
          class="mr-2"
        >
          Pause
        </b-button>

        <!---- Reset Button ---->
        <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
          <b-button
            :disabled="disabled"
            v-on:click="clear()"
            variant="light"
            size="sm"
            class="mr-2"
          >
            Reset
          </b-button>
        </span>

        <b-tooltip
          v-if="disabled"
          target="disabled-wrapper"
          triggers="hover"
          placement="top"
          variant="secondary"
        >
          Please pause simulation first
        </b-tooltip>

        <!-- -------- Simulation time slider ------ -->
        <slider ref="sld"></slider>
      </div>
      <b-row
        v-if="this.simulationData"
        no-gutters
        class="align-self-start align-items-baseline mt-2"
      >
        <b-form for="select-1" inline class="mr-2">
          <label class="mr-2">Choose Simulation day:</label>
          <b-select
            id="select-1"
            v-model="selectedDay"
            :options="simulationDays"
            @change="setSimPos(0), ($refs.sld.value = 0)"
          ></b-select>
        </b-form>
        <small v-if="analysisLoading"
          ><b-spinner class="mr-2" small></b-spinner>Loading...</small
        >
      </b-row>
    </div>
    <node-info-details />
    <airport-map ref="map"></airport-map>
  </div>
  <!-- End Content -->
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AirportMap from './AirportMap.vue'
import S3Controls from './S3Controls.vue'
import Slider from './Slider.vue'
import NodeInfoDetails from './NodeInfoDetails.vue'

export default {
  name: 'SimHistory',
  components: {
    AirportMap,
    S3Controls,
    Slider,
    NodeInfoDetails
  },
  data() {
    return {
      active: null, // Used to set active state for menu items

      myClock: null, // Timer for data reading from database
      disabled: false, // Used to control Start/Stop button state
      selected: null,
      params: { type: '', method: 'post' }
    }
  },
  computed: {
    ...mapGetters([
      'simulationData',
      'simulationName',
      'analysisLoading',
      'simulationDays',
      'simulationPosition',
      'simSelectedDay',
      'simulationLength',
      'simulationList',
      'apiLoading',
      'authUser'
    ]),
    selectedDay: {
      get() {
        return this.simSelectedDay
      },
      set(day) {
        this.setSimSelectedDay(day)
      }
    },
    selectedTime: {
      get() {
        let time = Object.keys(this.simulationData.data[this.simSelectedDay])[
          this.simulationPosition
        ]
        return time
      }
    }
  },
  methods: {
    ...mapActions(['updateNodeInfo', 'simulationAction']),
    ...mapMutations([
      'setSimSelectedDay',
      'setSimStatus',
      'setSimPos',
      'setSimData',
      'setSimName',
      'setSimDays',
      'setStopLoop',
      'setActive',
      'setAxiosConfig',
      'setSimList',
      'resetNodes'
    ]),
    makeSimpleToast(variant = null, text = '') {
      this.$bvToast.toast(`Simulation - ` + text, {
        title: 'Darwin International Airport',
        toaster: 'b-toaster-top-right',
        autoHideDelay: 1500,
        variant: variant
      })
    },
    startSimulation: function() {
      this.makeSimpleToast('info', 'Simulation Started')
      this.disabled = !this.disabled
      this.setSimStatus('running')
      //this.$refs.map.$refs.svg.startDots()
      this.startClock()
    },
    startClock: async function() {
      let pos = this.simulationPosition
      if (pos + 1 < this.simulationLength) {
        this.$refs.sld.value = pos + 1
        this.setSimPos(pos + 1)
        await this.updateNodeInfo()
        this.$refs.sld.updatestatus()
        this.myClock = setTimeout(this.startClock, 1000)
      } else {
        this.makeSimpleToast('warning', 'Simulation Finished')
        this.$refs.sld.stopMoving()
        this.stopClock()
      }
    },
    stopClock: function() {
      this.setSimStatus('stopped')
      clearInterval(this.myClock)
      if (this.$refs.sld) {
        this.$refs.sld.stopMoving()
      }
      this.disabled = !this.disabled
    },
    clear: function() {
      if (this.analysisLoading) {
        this.setStopLoop(true)
      }
      this.setSimData(null)
      this.$refs.map.resetNodeColor()
      if (this.$refs.sld) {
        this.$refs.sld.value = 0
        this.$refs.sld.stopMoving
      }
      this.stopClock()

      this.setSimDays(null)
      this.setSimName('')
      this.setSimSelectedDay('')
      this.setSimPos(0)
      this.resetNodes()
      this.disabled = false
    },
    calcOpacity: function(info, max) {
      let nodeOp = 0
      if (info != '-') {
        nodeOp = info / max
      }
      return nodeOp
    },
    fetchSimList: async function() {
      this.params.type = 'analysisList'
      this.params.method = 'get'
      this.setAxiosConfig(this.authUser.signInUserSession.idToken.jwtToken)

      const res = await this.simulationAction(this.params)
      let checkObj = Object.keys(res.response.data.runlist).length

      if (checkObj > 0) {
        let list = JSON.parse(res.response.data.runlist)
        let runList = {}
        list.forEach(run => {
          runList[run.Run] = run
        })
        this.setSimList(runList)
      }
    }
  },
  mounted() {
    this.setActive('history')
    this.fetchSimList()
  },
  beforeRouteLeave(to, from, next) {
    this.clear()
    next()
  }
}
</script>
<style scoped>
.flex-basis {
  flex-basis: content;
}
.header-title {
  margin-bottom: 0;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
  margin-top: 0;
}
h5 {
  margin: 10px 0;
  color: #6c757d;
  font-weight: 700;
}
</style>

<style>
.bg {
  box-shadow: 0 0 6px 2px #e8dddd;
  border-radius: 19px;
  padding: 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  background-color: #f1f3fa;
}
.w-85 {
  width: 85px;
}
.content-page {
  margin-left: 0;
  overflow: hidden;
  padding: 0 15px 5px 30px;
  position: relative;
  margin-right: -15px;
  width: 100%;
  padding-bottom: 50px;
  /* min-height: 100vh; */
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
}
.card {
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  margin-bottom: 24px;
  background-color: #f1f3fa;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem;
  padding: 1.5rem 1.5rem;
}
</style>
