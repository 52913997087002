<template>
  <div v-if="simulationData" class="card py-1 mb-3">
    <b-row no-gutters :class="collapse ? 'border-bottom' : ''">
      <div v-b-toggle.collapse-1 class="pointer d-flex align-items-center">
        <i v-if="!collapse" class="fas fa-plus-circle mr-2 text-secondary"></i
        ><i v-else class="fas fa-minus-circle mr-2 text-secondary"></i>
        <h5 class="mr-2">Node details</h5>
        <b-row
          v-if="collapse"
          class="d-flex align-items-start no-gutters justify-content-center align-self-start mt-1"
        >
          <b-badge @click="clearNodeList()" variant="secondary" class="badge"
            ><i class="fas fa-times-circle my-0 mr-2"></i>Clear List</b-badge
          >
        </b-row>
      </div>
    </b-row>

    <b-collapse v-model="collapse" id="collapse-1" class="mt-2">
      <b-row v-if="selectedNodeList.length == 0" no-gutters
        ><div>Please click on the Node to watch it's details!</div></b-row
      >
      <b-row>
        <b-col
          xl="3"
          md="6"
          sm="12"
          v-for="node in selectedNodeList"
          :key="node"
          class="mb-2"
        >
          <b-container class="text-left text-14">
            <b-row class="border-bottom font-weight-bold mb-2">
              <b-col>
                <i :class="$store.state[node].icon"></i>
                {{ $store.state[node].nodeName }}
              </b-col>
              <b-col class="text-right text-c">
                <i
                  @click="removeNode(node)"
                  class="fas fa-times-circle remove-icon"
                ></i>
              </b-col>
            </b-row>
            <b-row class="bg-white rounded-top">
              <b-col cols="8">Queue length:</b-col>
              <b-col>{{ $store.state[node].currentQueueLength }}</b-col>
            </b-row>
            <b-row class="bg-c-light">
              <b-col cols="8">New passengers:</b-col>
              <b-col>{{ $store.state[node].newPassengers }}</b-col>
            </b-row>
            <b-row class="bg-white">
              <b-col cols="8">Served passengers:</b-col>
              <b-col>{{ $store.state[node].passServed }}</b-col>
            </b-row>
            <b-row class="bg-c-light">
              <b-col cols="8">Max wait time:</b-col>
              <b-col>{{ secondsToHms($store.state[node].maxWaitTime) }}</b-col>
            </b-row>
            <b-row class="bg-white">
              <b-col cols="8">Avg wait time:</b-col>
              <b-col>{{ secondsToHms($store.state[node].avgWaitTime) }}</b-col>
            </b-row>
            <b-row class="bg-c-light">
              <b-col cols="8">Max service time:</b-col>
              <b-col>{{
                secondsToHms($store.state[node].maxServiceTime)
              }}</b-col>
            </b-row>
            <b-row class="bg-white rounded-bottom">
              <b-col cols="8">Avg service time:</b-col>
              <b-col>{{
                secondsToHms($store.state[node].avgServiceTime)
              }}</b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-collapse>
  </div></template
>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'NodeInfoDetails',
  data() {
    return {
      collapse: false
    }
  },
  computed: {
    ...mapGetters(['selectedNodeList', 'simulationData'])
  },
  methods: {
    ...mapMutations(['clearNodeList', 'removeNode']),
    secondsToHms: function(d) {
      if (d == '-') {
        return '-'
      }
      d = Math.round(Number(d))
      var h = Math.floor(d / 3600)
      var m = Math.floor((d % 3600) / 60)
      var s = Math.floor((d % 3600) % 60)

      var hDisplay = h > 0 ? h + 'h ' : ''
      var mDisplay = m > 0 ? m + 'm ' : ''
      var sDisplay = s > 0 ? s + 's' : ''
      return hDisplay + mDisplay + sDisplay
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
h5 {
  margin-bottom: 0.5rem;
  letter-spacing: 0.02em;
  font-size: 1.1rem;
  margin: 10px 0;
  color: #6c757d;
  font-weight: 700;
}
.badge:hover {
  transform: scale(1.05);
  box-shadow: #757b80 0px 2px 4px 0px, #757b80 0px 2px 4px 0px;
}
.remove-icon {
  color: #6c757d;
}
.remove-icon:hover {
  cursor: pointer;
  color: red;
  transform: scale(1.05);
}
</style>
