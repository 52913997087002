import LoginPage from './views/LoginPage.vue'

const moduleRoutes = {
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/signup',
      name: 'signUp',
      component: LoginPage
    },
    {
      path: '/signup/confirm',
      name: 'signUpConfirm',
      component: LoginPage
    },
    {
      path: '/signup/success',
      name: 'signUpSuccess',
      component: LoginPage
    },
    {
      path: '/resetpass',
      name: 'resetPass',
      component: LoginPage
    },
    {
      path: '/resetpass/confirm',
      name: 'resetPassConfirm',
      component: LoginPage
    },
    {
      path: '/resetpass/success',
      name: 'resetPassSuccess',
      component: LoginPage
    }
  ]
}

export default (router) => {
  moduleRoutes.routes.forEach(route => {
      router.addRoute(route);
    }
  )
}
