<template>
  <div>
    <div class="text-right small mr-3">
      Time Zone: <span class="font-italic"> Darwin NT (ACST)</span>
    </div>
    <div v-if="simulationData" class="text-right small mr-3">
      Calculation Window:
      <span class="font-italic"> {{ this.getCW() }}</span>
    </div>
    <div class="px-3 py-3 mt-3">
      <div v-if="!simulationData">
        <p>Please run simulation first!</p>
        <b-img :src="pic" fluid thumbnail></b-img>
      </div>
      <div v-else>
        <div class="mb-2">{{ runTS }}</div>
        <b-tabs active-nav-item-class="shadow-none" justified>
          <b-tab title="Outbound" active :title-link-class="tabLinkClass">
            <b-container class="text-left text-14 mt-4">
              <b-row class="border-bottom font-weight-bold mb-2">
                <b-col>
                  <i class="fas fa-luggage-cart"></i>
                  {{ this.$store.state.checkInNode.nodeName }}
                </b-col>
                <b-col class="text-right text-c">
                  <i
                    id="popover-target-1"
                    class="fas fa-info-circle text-primary"
                  ></i>
                </b-col>
              </b-row>
              <b-row class="bg-white rounded-top">
                <b-col cols="7">Queue length:</b-col>
                <b-col>{{
                  this.$store.state.checkInNode.currentQueueLength
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">New passengers:</b-col>
                <b-col>{{ this.$store.state.checkInNode.newPassengers }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Served passengers:</b-col>
                <b-col>{{ this.$store.state.checkInNode.passServed }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.checkInNode.maxWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Avg wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.checkInNode.avgWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.checkInNode.maxServiceTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white rounded-bottom">
                <b-col cols="7">Avg service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.checkInNode.avgServiceTime)
                }}</b-col>
              </b-row>
            </b-container>
            <b-container class="text-left mt-4 text-14">
              <b-row class="border-bottom font-weight-bold mb-2">
                <b-col>
                  <i class="fas fa-user-shield"></i>
                  {{ this.$store.state.securityNode.nodeName }}
                </b-col>
              </b-row>
              <b-row class="bg-white rounded-top">
                <b-col cols="7">Queue length:</b-col>
                <b-col>{{
                  this.$store.state.securityNode.currentQueueLength
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">New passengers:</b-col>
                <b-col>{{
                  this.$store.state.securityNode.newPassengers
                }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Served passengers:</b-col>
                <b-col>{{ this.$store.state.securityNode.passServed }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.securityNode.maxWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Avg wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.securityNode.avgWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.securityNode.maxServiceTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white rounded-bottom">
                <b-col cols="7">Avg service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.securityNode.avgServiceTime)
                }}</b-col>
              </b-row>
            </b-container>
            <b-container class="text-left mt-4 text-14">
              <b-row class="border-bottom font-weight-bold mb-2">
                <b-col>
                  <i class="fas fa-users"></i>
                  {{ this.$store.state.airsideNode.nodeName }}
                </b-col>
              </b-row>
              <b-row class="bg-white rounded-top">
                <b-col cols="7">Passengers:</b-col>
                <b-col>{{
                  this.$store.state.airsideNode.currentQueueLength
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.airsideNode.maxWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Avg wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.airsideNode.avgWaitTime)
                }}</b-col>
              </b-row>
            </b-container>
            <b-popover
              target="popover-target-1"
              triggers="hover"
              placement="leftbottom"
            >
              <template #title>Info</template>
              <b>Queue Length:</b> Number of passengers currently in the
              queue<br />
              <b>New passengers:</b> Number of passengers joined the queue in
              the last {{ this.getCW() }}<br />
              <b>Served passengers:</b> Number of passengers served in the last
              {{ this.getCW() }}<br />
              <b>Max wait time:</b> The maximum waiting time in the last
              {{ this.getCW() }}<br />
              <b>Avg wait time:</b> The average waiting time in the last
              {{ this.getCW() }}<br />
              <b>Max service time:</b> The maximum service time in the last
              {{ this.getCW() }}<br />
              <b>Avg service time:</b> The average waiting time in the last
              {{ this.getCW() }}
            </b-popover>
          </b-tab>
          <b-tab title="Inbound" :title-link-class="tabLinkClass">
            <b-container class="text-left text-14 mt-4">
              <b-row class="border-bottom font-weight-bold mb-2">
                <b-col>
                  <i class="fas fa-plane-arrival"></i>
                  {{ this.$store.state.disembarkNode.nodeName }}
                </b-col>
                <b-col class="text-right text-c">
                  <i
                    id="popover-target-2"
                    class="fas fa-info-circle text-primary"
                  ></i>
                </b-col>
              </b-row>
              <b-row class="bg-white rounded-top">
                <b-col cols="7">Queue length:</b-col>
                <b-col>{{
                  this.$store.state.disembarkNode.currentQueueLength
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">New passengers:</b-col>
                <b-col>{{
                  this.$store.state.disembarkNode.newPassengers
                }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Served passengers:</b-col>
                <b-col>{{ this.$store.state.disembarkNode.passServed }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.disembarkNode.maxWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Avg wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.disembarkNode.avgWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.disembarkNode.maxServiceTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white rounded-bottom">
                <b-col cols="7">Avg service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.disembarkNode.avgServiceTime)
                }}</b-col>
              </b-row>
            </b-container>
            <b-container class="text-left mt-4 text-14">
              <b-row class="border-bottom font-weight-bold mb-2">
                <b-col>
                  <i class="fas fa-suitcase"></i>
                  {{ this.$store.state.baggageNode.nodeName }}
                </b-col>
              </b-row>
              <b-row class="bg-white rounded-top">
                <b-col cols="7">Queue length:</b-col>
                <b-col>{{
                  this.$store.state.baggageNode.currentQueueLength
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">New passengers:</b-col>
                <b-col>{{ this.$store.state.baggageNode.newPassengers }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Served passengers:</b-col>
                <b-col>{{ this.$store.state.baggageNode.passServed }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.baggageNode.maxWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white">
                <b-col cols="7">Avg wait time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.baggageNode.avgWaitTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-c-light">
                <b-col cols="7">Max service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.baggageNode.maxServiceTime)
                }}</b-col>
              </b-row>
              <b-row class="bg-white rounded-bottom">
                <b-col cols="7">Avg service time:</b-col>
                <b-col>{{
                  secondsToHms(this.$store.state.baggageNode.avgServiceTime)
                }}</b-col>
              </b-row>
            </b-container>
            <b-popover
              target="popover-target-2"
              triggers="hover"
              placement="leftbottom"
            >
              <template #title>Info</template>
              <b>Queue Length:</b> Number of passengers currently in the
              queue<br />
              <b>New passengers:</b> Number of passengers joined the queue in
              the last {{ this.getCW() }}<br />
              <b>Served passengers:</b> Number of passengers served in the last
              {{ this.getCW() }}<br />
              <b>Max wait time:</b> The maximum waiting time in the last
              {{ this.getCW() }}<br />
              <b>Avg wait time:</b> The average waiting time in the last
              {{ this.getCW() }}<br />
              <b>Max service time:</b> The maximum service time in the last
              {{ this.getCW() }}<br />
              <b>Avg service time:</b> The average waiting time in the last
              {{ this.getCW() }}
            </b-popover>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'

export default {
  name: 'SideBar',
  data() {
    return {
      pic: require('@/assets/noData.png'),
      tabLinkClass: 'p-1'
    }
  },
  computed: {
    ...mapGetters(['simulationData', 'simSelectedDay', 'currentSimTime']),
    runTS() {
      let time = this.currentSimTime
      let date = moment(time)
      return date.format('MMMM Do YYYY, h:mm:ss a')
    }
  },
  methods: {
    secondsToHms: function(d) {
      if (d == '-') {
        return '-'
      }
      d = Math.round(Number(d))
      var h = Math.floor(d / 3600)
      var m = Math.floor((d % 3600) / 60)
      var s = Math.floor((d % 3600) % 60)

      var hDisplay = h > 0 ? h + 'h ' : ''
      var mDisplay = m > 0 ? m + 'm ' : ''
      var sDisplay = s > 0 ? s + 's' : ''
      return hDisplay + mDisplay + sDisplay
    },
    getCW: function() {
      let durantionString = ''
      let startTime = moment(
        Object.keys(this.simulationData.data[this.simSelectedDay])[0]
      )
      let endTime = moment(
        Object.keys(this.simulationData.data[this.simSelectedDay])[1]
      )
      let duration = moment.duration(endTime.diff(startTime))
      if (duration._data.years) {
        durantionString += `${duration._data.years}y `
      }
      if (duration._data.months) {
        durantionString += `${duration._data.months}m `
      }
      if (duration._data.days) {
        durantionString += `${duration._data.days}d `
      }
      if (duration._data.hours) {
        durantionString += `${duration._data.hours}h `
      }
      if (duration._data.minutes) {
        durantionString += `${duration._data.minutes}min`
      }
      if (durantionString) {
        return durantionString
      } else {
        return '-'
      }
    }
  }
}
</script>

<style scoped>
.text-14 {
  font-size: 14px;
}
.active {
  box-shadow: none;
}
b,
.text-c {
  color: #6c757d;
}
</style>
