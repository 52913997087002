import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueCompositionAPI from '@vue/composition-api'
import VuePapaParse from 'vue-papa-parse'

import Amplify from 'aws-amplify'
import awsConfig from './aws-exports'
// Testing config

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
var urls = awsConfig.oauth.redirectSignIn.split(',')

let localRedirectSignIn = urls.find(url => url.includes('localhost'))
if (typeof localRedirectSignIn != 'undefined') {
  urls.splice(urls.indexOf(localRedirectSignIn), 1)
}
let productionRedirectSignIn = urls[0]

urls = awsConfig.oauth.redirectSignOut.split(',')

let localRedirectSignOut = urls.find(url => url.includes('localhost'))
if (typeof localRedirectSignOut != 'undefined') {
  urls.splice(urls.indexOf(localRedirectSignOut), 1)
}
let productionRedirectSignOut = urls[0]

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_S3_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: 'ap-southeast-2' //OPTIONAL -  Amazon service region
    }
  }
}

Amplify.configure(updatedAwsConfig)

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'

import { registerModules } from './register-modules'
import loginModule from './modules/login'

import store from './store'
import { createRouter } from './router'

import moment from 'moment-timezone'

const router = createRouter()

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueCompositionAPI)
Vue.use(VuePapaParse)

Vue.filter('runIdToTime', function(runID) {
  const timestamp = parseInt(runID.replace(/^\D+/g, ''), 10)
  const date = moment(timestamp / 1000000).format('YYYY-MM-DD HH:mm')
  return 'Run(' + date + ')'
})

registerModules(
  {
    login: loginModule
  },
  router,
  store
)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
