<template>
  <div>
    <b-navbar toggleable="md" class="bd-navbar mb-3">
      <div class="container-fluid">
        <b-navbar-brand class="logo" href="#">
          <img
            src="../assets/logo.svg"
            class="d-inline-block align-top"
            alt="forecast"
            height="40"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown
              right
              class="nav-item-user align-self-stretch d-flex align-items-center"
            >
              <template #button-content class="text-darker">
                <b-img
                  :src="require('../assets/avatar-2.jpg')"
                  v-bind="mainProps"
                  rounded="circle"
                  alt="Circle image"
                ></b-img>
                <span class="cap">{{ prefUsername }}</span>
              </template>
              <b-dropdown-item @click="handleSignOutButtonClick">
                <i class="fas fa-sign-out-alt mr-2"></i>Sign Out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'NavBar',
  async mounted() {},
  data() {
    return {
      mainProps: {
        width: 32,
        height: 32,
        class: 'm1 mr-2'
      }
    }
  },
  computed: {
    ...mapGetters(['authUser']),
    prefUsername() {
      if (!this.authUser.attributes) {
        return this.authUser.username
      }
      if (this.authUser.attributes.preferred_username) {
        return this.authUser.attributes.preferred_username
      } else {
        return this.authUser.username
      }
    }
  },
  methods: {
    ...mapMutations(['setAuthUser']),
    async handleSignOutButtonClick() {
      try {
        await Auth.signOut()
        this.setAuthUser(null)
        this.$router.push({ path: '/login' })
      } catch (error) {
        console.log('error signing out: ', error)
      }
    }
  },
  beforeDestroy() {}
}
</script>

<style>
h4 {
  font-weight: 700;
}
</style>

<style scoped>
/* Wrapper */
.wrapper {
  display: flex;
  overflow: inherit;
  height: 100%;
  width: 100%;
}
@media (min-width: 992px) {
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 95%;
  }
}
.bd-navbar {
  box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
}
@media (min-width: 768px) {
  .nav-item-user {
    background-color: #f8f9fa !important;
    min-height: 64px;
    margin: -8px 0;
    padding: 0 8px;
  }
}
.logo {
  width: 160px;
}
</style>

<style lang="scss">
@import '../assets/css/variables';

/* Wrapper */
.wrapper {
  display: flex;
  overflow: inherit;
  height: 100%;
  width: 100%;
}
.content-page {
  margin-left: 0;
  overflow: hidden;
  padding: 0 15px 5px 30px;
  position: relative;
  margin-right: -15px;
  width: 100%;
  padding-bottom: 60px;
  min-height: 100vh;
}

*:focus {
  outline: none;
}

.navbar-nav {
  text-align: left;
}
.dropdown-item {
  color: map-get($colors, text) !important;
  padding: 7px 20px !important;
}
.dropdown-item:active {
  color: map-get($colors, text-active) !important;
  background-color: map-get($colors, nav-link-hover) !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: map-get($colors, text-hover) !important;
  background-color: map-get($colors, nav-link-hover) !important;
}
.dropdown-menu {
  padding: 0.25rem 0 !important;
  font-size: 0.9rem !important;
}
</style>
