<template>
  <div id="login-card">
    <h4 class="mt-0">Sign In</h4>
    <p class="text-muted mb-5">
      Enter your email address and password to access account.
    </p>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="error != ''"
      @dismissed="error = ''"
    >
      <i class="fas fa-exclamation-triangle mr-2"></i>{{ error }}
    </b-alert>
    <b-form @submit="onSubmit" class="d-flex flex-column text-left">
      <b-form-group id="input-group-1">
        <template v-slot:description>
          <span class="text-muted"
            >We'll never share your email with anyone else.</span
          >
        </template>
        <label label-for="input-1">Username:</label>
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="text"
          placeholder="Enter your username"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2">
        <label label-for="input-2">Password:</label>
        <b-input-group class="">
          <template #append>
            <b-input-group-text @click="showPass()"
              ><strong :class="[showPassword ? 'text-forecast' : 'text-muted']"
                ><i
                  class="far"
                  :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
                ></i></strong
            ></b-input-group-text>
          </template>
          <b-form-input
            id="input-2"
            v-bind:type="showPassword ? 'text' : 'password'"
            v-model="form.password"
            placeholder="Enter your password"
            required
          ></b-form-input>
        </b-input-group>
        <router-link to="resetpass" class="text-muted float-end"
          ><small>Forgot your password?</small></router-link
        >
      </b-form-group>

      <b-form-group id="input-group-4">
        <b-form-checkbox v-model="form.checked" value="me"
          >Remember me</b-form-checkbox
        >
      </b-form-group>

      <b-button
        type="submit"
        class="btn-forecast d-flex justify-content-center align-items-center"
        ><b-spinner v-if="authLoading" small></b-spinner
        ><i v-if="!authLoading" class="fas fa-sign-in-alt"></i
        ><span class="ml-2">Log In</span></b-button
      >
    </b-form>
    <azure-s-s-o />
    <footer class="footer footer-alt">
      <p class="text-muted">
        Don't have an account?
        <router-link to="signup" class="text-muted"><b>Sign Up</b></router-link>
      </p>
    </footer>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { Auth, Hub } from 'aws-amplify'
import AzureSSO from './AzureSSO.vue'

export default {
  name: 'Login',
  components: {
    AzureSSO
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        checked: []
      },
      showPassword: false,
      authLoading: false,
      error: '',
      myListener: null // Store listeners callback data, it is needed for destroying listener
    }
  },
  computed: mapGetters([
    'resetPass',
    'signUp',
    'resetCode',
    'resetSuccess',
    'signUpConfirm',
    'signUpSuccess'
  ]),
  methods: {
    ...mapMutations([
      'setAuthUser',
      'setResetPass',
      'setSignUp',
      'setSignUpConfirm',
      'setconfirmUsername',
      'setconfirmEmail'
    ]),
    onSubmit(event) {
      event.preventDefault()
      this.login(this.form.email, this.form.password)
    },
    showPass: function() {
      this.showPassword = !this.showPassword
    },
    // Login function
    async login(username, password) {
      this.authLoading = true
      try {
        await Auth.signIn(username, password)
        this.authLoading = false
      } catch (err) {
        console.log(err)
        this.error = err.message
        if (err.code == 'UserNotConfirmedException') {
          this.resendConfirmationCode(username)
        }
      }
      this.authLoading = false
    },
    async resendConfirmationCode(username) {
      try {
        await Auth.resendSignUp(username)
        console.log('code resent successfully')
        this.setconfirmUsername(username)
        this.setconfirmEmail('registered email')
        this.$router.push({ path: '/signup/confirm' })
      } catch (err) {
        console.log('error resending code: ', err)
        this.error = err.message
      }
    },
    startListening: function() {
      Hub.listen(
        'auth',
        (this.myListener = ({ payload: { event, data } }) => {
          switch (event) {
            case 'signIn':
              if (data.attributes) {
                this.setAuthUser(data)
              } else {
                this.addAttributes()
              }
              this.authLoading = false
              this.$router.push({ path: '/dashboard' })
              break
            case 'signOut':
              console.log('logout from HUB')
              break
            case 'customOAuthState':
            //this.setState({ customState: data })
          }
        })
      )
    },
    async addAttributes() {
      try {
        const userWithAtt = await Auth.currentAuthenticatedUser()
        this.setAuthUser(userWithAtt)
      } catch (err) {
        console.log(err)
      }
    }
  },
  mounted() {
    this.startListening()
  },
  beforeDestroy() {
    Hub.remove('auth', this.myListener)
  }
}
</script>
