const registerModule = (name, module, router, store) => {
    if (module.router) {
        module.router(router)
    }
    if (module.store) {
        store.registerModule(name, module.store)
    }
}

export const registerModules = (modules, router, store) => {
    Object.keys(modules).forEach((moduleKey) => {
        const module = modules[moduleKey]
        registerModule(moduleKey, module, router, store)
    })
}  