<template>
  <div id="login-card">
    <div class="d-flex flex-column text-center align-items-center">
      <img
        src="../assets/mail_sent.svg"
        class="mb-3"
        alt="mail sent image"
        height="64"
        width="64"
      />
      <h4 class="mt-0">Please check your email</h4>
      <p class="text-muted">
        An email has been send to <b>{{ confirmEmail }}</b
        >. Please check for an email from company and enter your verification
        code.
      </p>
    </div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="error != ''"
      @dismissed="error = ''"
    >
      <i class="fas fa-exclamation-triangle mr-2"></i>{{ error }}
    </b-alert>
    <b-form @submit="onSubmit" class="d-flex flex-column text-left">
      <b-form-group id="input-group-1">
        <label for="input-1" class="mb-0">Username:</label>
        <b-form-input
          id="input-1"
          v-model="confirmUsername"
          type="text"
          class="input-readonly"
          plaintext
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-2">
        <label for="input-2" class="">Verification code:</label>
        <b-form-input
          id="input-2"
          v-model="form.code"
          type="text"
          placeholder="Enter verification code"
          required
        ></b-form-input>
      </b-form-group>

      <b-button
        type="submit"
        class="btn-forecast d-flex justify-content-center align-items-center"
        ><b-spinner v-if="resetCodeLoading" small></b-spinner>
        <i v-if="!resetCodeLoading" class="fas fa-check"></i>
        <span class="ml-2">Confirm Sign Up</span></b-button
      >
    </b-form>
    <footer class="footer footer-alt">
      <p class="text-muted">
        Back to
        <router-link to="/login" class="text-muted ml-1"
          ><b>Log In</b></router-link
        >
      </p>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Auth } from 'aws-amplify'

export default {
  name: 'ResetPassCode',
  data() {
    return {
      form: {
        code: ''
      },
      resetCodeLoading: false,
      error: ''
    }
  },
  computed: mapGetters(['confirmEmail', 'confirmUsername']),
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      this.resetCodeLoading = true
      try {
        await Auth.confirmSignUp(this.confirmUsername, this.form.code)
        this.resetCodeLoading = false
        this.$router.push({ path: '/signup/success' })
      } catch (err) {
        console.log(err)
        this.error = err.message
        this.resetCodeLoading = false
      }
    }
  }
}
</script>
