<template>
  <div class="mapBg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="airport.viewBox"
      :aria-label="airport.label"
      class="svg-map airport"
    >
      <path
        v-for="location in airport.locations"
        :id="location.id"
        :key="location.id"
        :name="location.name"
        :d="location.path"
        class="svg-map__location pre-formatted"
        v-b-popover.hover.top.html="tooltipContent(location.id)"
        @click="addNodeToList(location.vuexName + 'Node')"
        :title="location.name"
      />
      <!-- v-b-toggle:sidebar-right -->
      <path
        id="curve"
        d="m 430.77795,539.25221 v -62.0348 c 0,0 0.69313,-23.56629 -17.67472,-19.06097 -18.36784,4.50532 -197.54097,-0.69313 -201.69973,-0.69313 -4.15876,0 -13.51596,-10.05033 1.38625,-14.90221 14.90222,-4.85189 209.67069,-1.38625 209.67069,-1.38625 0,0 16.98159,0.69312 17.67472,-9.01065 0.69312,-9.70376 -23.21973,-6.93126 -23.21973,-6.93126 H 229.77135 c 0,0 -21.48692,2.42594 -23.91286,-8.66408 -2.42594,-11.09002 -0.69313,-10.39689 -0.69313,-10.39689"
        style="fill: none; stroke: #000; stroke-width: 0"
      />
      <path
        id="curve2"
        d="m 459.54269,405.47884 c 0,0 1.03969,72.08513 7.27783,73.12482 6.23813,1.03969 15.9419,4.50532 19.40753,-0.69312 3.46563,-5.19845 -0.34656,-12.12971 4.50532,-15.59534 4.85188,-3.46564 82.13546,-2.42595 82.13546,-2.42595 0,0 14.55565,0.34657 12.82283,-9.3572 -1.73281,-9.70377 -9.01064,-10.05033 -15.9419,-9.3572 -6.93126,0.69312 -76.59045,1.73281 -76.59045,1.73281 0,0 -9.70376,-2.42594 -4.85188,-11.78314 4.85188,-9.35721 5.89157,-8.31752 18.36784,-7.27783 12.47628,1.03969 69.31263,2.07938 69.31263,2.07938 0,0 9.01064,-1.38625 7.62439,-7.97095 -1.38626,-6.5847 -0.69313,-9.01064 -0.69313,-9.01064"
        style="fill: none; stroke: #000; stroke-width: 0"
      />
      <path
        id="curve3"
        d="m 582.91916,408.94448 c -5.56458,-20.774 -23.20869,-19.79377 -23.20869,-19.79377 0,0 -36.63953,21.55597 -57.83348,1.96046 -21.19395,-19.59551 36.26845,-12.74297 55.87302,-15.68366 19.60457,-2.94068 26.46617,-15.68365 16.66389,-20.58479 -9.80229,-4.90115 -22.54526,-3.92092 -22.54526,-3.92092 0,0 -8.82205,0 0.98023,-6.8616 9.80229,-6.8616 11.76274,13.7232 18.62434,-1.96045 6.8616,-15.68366 6.8616,-41.1696 6.8616,-41.1696"
        style="fill: none; stroke: #000; stroke-width: 0"
      />
      <path
        id="curve4"
        d="m 580.29527,174.48067 c -1.96046,17.64412 3.92091,19.60457 -0.98023,22.54526 -4.90114,2.94068 -30.38708,-1.96046 -25.48594,-6.8616 4.90114,-4.90114 10.78251,1.96046 16.66389,-2.94069 5.88137,-4.90114 15.68365,-51.95211 5.88137,-61.75439 -9.80229,-9.80229 -92.14148,-40.189371 -122.52857,-41.169599 -30.38708,-0.980229 -131.35061,15.683656 -119.58787,30.387079 11.76274,14.70343 101.94376,33.32777 146.05404,28.42663 44.11029,-4.90114 127.42971,-52.932338 170.55976,-38.22891 43.13006,14.70343 48.0312,1.96046 49.99166,19.60457 1.96045,17.64411 63.71485,20.5848 63.71485,20.5848"
        style="fill: none; stroke: #000; stroke-width: 0"
      />
      <path
        id="curve5"
        d="m 764.57823,145.07382 c -56.85326,-3.92092 -34.308,4.90114 -55.87303,-0.98023 -21.56502,-5.88137 16.66389,-38.22891 5.88137,-46.07074 -10.78251,-7.841828 -131.35061,43.13005 -182.3225,38.22891 -50.97188,-4.90114 -96.06239,-39.209139 -165.65861,-27.4464 -69.59623,11.76275 -72.53691,14.70343 -75.4776,29.40686 -2.94068,14.70343 -3.92091,37.24868 -3.92091,37.24868 0,0 15.68366,-68.61599 -10.78251,-65.67531 -26.46617,2.94069 -164.67839,-10.782512 -179.381819,2.94069 -14.703427,13.7232 -38.228911,62.73462 -16.663884,68.61599 21.565023,5.88137 121.548333,18.62434 107.825133,0.98023 -13.7232,-17.64411 -79.39851,-15.68366 -79.39851,-15.68366"
        style="fill: none; stroke: #000; stroke-width: 0"
      />
      <path
        id="curve6"
        d="m 108.80536,166.63884 c -42.149822,14.70343 -54.892793,0.98023 -42.149822,-30.38708 12.74297,-31.36731 137.231992,-22.54525 137.231992,-22.54525 0,0 421.49825,19.60457 446.00396,-1.96046 24.50572,-21.565028 2.94069,-45.090512 10.78252,-60.774168 7.84183,-15.683656 111.74605,-28.426627 120.5681,-26.46617 8.82206,1.960457 255.83959,10.782514 288.18719,0.980229 32.3475,-9.802285 43.13,-19.60457 43.13,-19.60457"
        style="fill: none; stroke: #000; stroke-width: 0"
      />
      <!-- -------- Gate icons and bars --------------------------------->
      <!-- -------- Gate 1 -------------------->
      <g transform="matrix(1.383085,0,0,1.383085,39.05,275.892)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.92,4.1 5.34,4.71 C 5.01,4.46 4.75,4.34 4.35,4.34 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.27 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.5 H 4.21 L 4.1,5.69 h 1.86 v 2.4 C 5.46,8.41 4.83,8.6 4.22,8.6 2.74,8.6 1.99,7.71 1.99,6.05 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 8.69,8.48 H 7.6 V 4.82 L 6.58,5.44 6.1,4.7 7.7,3.73 h 0.99 v 4.73 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 2 -------------------->
      <g transform="matrix(1.383085,0,0,1.383085,114.803,275.892)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.64,3.67 5.06,4.28 C 4.73,4.03 4.47,3.91 4.07,3.91 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.27 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.07 H 3.93 L 3.82,5.26 h 1.86 v 2.4 C 5.18,7.98 4.55,8.17 3.94,8.17 2.46,8.17 1.71,7.28 1.71,5.62 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 9.22,4.56 C 9.22,5.3 8.86,5.85 7.38,7.19 H 9.32 L 9.21,8.04 H 6.13 V 7.26 C 7.72,5.69 8.09,5.26 8.09,4.68 8.09,4.29 7.85,4.06 7.48,4.06 7.14,4.06 6.9,4.21 6.62,4.55 L 5.97,4.03 c 0.38,-0.5 0.92,-0.82 1.62,-0.82 1.06,0 1.63,0.62 1.63,1.35 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 3 -------------------->
      <g id="g3" transform="matrix(1.3830416,0,0,1.3830416,92.922,77.36258)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.6,3.67 5.02,4.28 C 4.69,4.03 4.43,3.91 4.03,3.91 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.28 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.07 H 3.89 L 3.78,5.26 h 1.86 v 2.4 C 5.14,7.98 4.51,8.17 3.9,8.17 2.42,8.17 1.67,7.28 1.67,5.62 1.67,3.96 2.69,3.08 4,3.08 c 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 9.19,4.42 C 9.19,4.99 8.85,5.38 8.27,5.55 8.86,5.61 9.35,5.96 9.35,6.71 9.35,7.5 8.71,8.17 7.54,8.17 6.83,8.17 6.29,7.9 5.92,7.46 L 6.52,6.88 C 6.84,7.2 7.12,7.34 7.49,7.34 7.92,7.34 8.23,7.09 8.23,6.65 8.23,6.15 7.96,5.97 7.46,5.97 H 7.07 L 7.19,5.21 H 7.46 C 7.88,5.21 8.14,4.99 8.14,4.59 8.14,4.25 7.89,4.04 7.51,4.04 7.16,4.04 6.87,4.18 6.59,4.45 L 6.04,3.85 c 0.44,-0.42 0.97,-0.64 1.6,-0.64 1.02,0 1.58,0.54 1.58,1.21 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 4 -------------------->
      <g transform="matrix(1.3842982,0,0,1.3842982,144.77617,77.355966)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.44,3.67 4.86,4.28 C 4.53,4.03 4.27,3.91 3.87,3.91 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.28 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.07 H 3.73 L 3.62,5.26 h 1.86 v 2.4 C 4.98,7.98 4.35,8.17 3.74,8.17 2.26,8.17 1.51,7.28 1.51,5.62 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 9.59,7.02 H 9.06 V 8.04 H 7.98 V 7.02 h -2 V 6.28 L 7.32,3.18 8.26,3.53 7.12,6.19 H 7.99 L 8.11,5.07 h 0.95 v 1.12 h 0.53 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 5 -------------------->
      <g transform="matrix(1.384312,0,0,1.384312,334.332,77.356)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.54,3.67 4.96,4.28 C 4.63,4.03 4.37,3.91 3.97,3.91 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.28 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.07 H 3.83 L 3.72,5.26 h 1.86 v 2.4 C 5.08,7.98 4.45,8.17 3.84,8.17 2.36,8.17 1.61,7.28 1.61,5.62 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 9.06,4.1 H 7.34 v 1 C 7.58,4.99 7.82,4.95 8.04,4.95 8.82,4.95 9.4,5.51 9.4,6.49 9.4,7.47 8.67,8.17 7.54,8.17 6.82,8.17 6.32,7.87 5.96,7.46 L 6.58,6.89 C 6.83,7.18 7.11,7.35 7.5,7.35 7.98,7.35 8.26,7.06 8.26,6.51 8.26,5.92 7.99,5.72 7.59,5.72 7.4,5.72 7.24,5.77 7.02,5.89 H 6.27 V 3.31 h 2.9 L 9.04,4.1 Z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 6 -------------------->
      <g transform="matrix(1.383085,0,0,1.383085,360.087,77.356)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.44,3.67 4.86,4.28 C 4.53,4.03 4.27,3.91 3.87,3.91 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.28 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.07 H 3.73 L 3.62,5.26 h 1.86 v 2.4 C 4.98,7.98 4.35,8.17 3.74,8.17 2.26,8.17 1.51,7.28 1.51,5.62 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 9.56,6.43 C 9.56,7.4 8.9,8.17 7.84,8.17 6.59,8.17 6.05,7.25 6.05,5.89 c 0,-1.6 0.78,-2.67 2.06,-2.67 0.45,0 0.81,0.13 1.08,0.32 L 8.78,4.2 C 8.58,4.09 8.38,4.02 8.14,4.02 7.61,4.02 7.26,4.48 7.2,5.31 c 0.29,-0.3 0.64,-0.43 1,-0.43 0.78,0 1.36,0.57 1.36,1.57 z M 8.45,6.44 C 8.45,5.83 8.25,5.62 7.89,5.62 7.64,5.62 7.4,5.75 7.2,5.99 7.21,6.98 7.38,7.37 7.86,7.37 8.26,7.37 8.45,7 8.45,6.44 Z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 7 -------------------->
      <g transform="matrix(1.383085,0,0,1.383085,542.43806,77.356)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.84,4.1 5.26,4.71 C 4.93,4.46 4.67,4.34 4.27,4.34 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.28 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.5 H 4.13 L 4.02,5.69 h 1.86 v 2.4 C 5.38,8.41 4.75,8.6 4.14,8.6 2.66,8.6 1.91,7.71 1.91,6.05 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 9.21,4.49 7.47,8.59 6.47,8.25 8.06,4.57 H 6.15 V 3.74 h 3.06 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 8 -------------------->
      <g transform="matrix(1.383085,0,0,1.383085,564.993,77.356)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.38,3.67 4.8,4.28 C 4.47,4.03 4.21,3.91 3.81,3.91 3.13,3.91 2.63,4.41 2.63,5.62 2.63,6.9 2.97,7.33 3.64,7.33 3.88,7.33 4.1,7.28 4.3,7.17 V 6.07 H 3.67 L 3.56,5.26 h 1.86 v 2.4 C 4.92,7.98 4.29,8.17 3.68,8.17 2.2,8.17 1.45,7.28 1.45,5.62 1.45,3.96 2.47,3.08 3.78,3.08 4.49,3.08 5,3.33 5.4,3.68 Z"
          style="fill:#fff;"
        />
        <path
          d="m 9.58,6.76 c 0,0.76 -0.67,1.4 -1.85,1.4 C 6.55,8.16 5.92,7.54 5.92,6.78 5.92,6.22 6.26,5.84 6.8,5.6 6.3,5.31 6.11,4.91 6.11,4.48 6.11,3.72 6.8,3.2 7.76,3.2 8.72,3.2 9.4,3.65 9.4,4.42 9.4,4.83 9.17,5.21 8.64,5.49 9.28,5.8 9.58,6.21 9.58,6.75 Z m -1.11,0 C 8.47,6.38 8.27,6.19 7.74,6 L 7.51,5.92 C 7.19,6.1 7.03,6.38 7.03,6.74 c 0,0.39 0.28,0.64 0.73,0.64 0.45,0 0.71,-0.27 0.71,-0.62 z M 7.18,4.51 c 0,0.33 0.19,0.5 0.57,0.67 L 7.9,5.24 C 8.24,5.02 8.32,4.8 8.32,4.5 8.32,4.19 8.13,3.97 7.76,3.97 7.41,3.97 7.18,4.15 7.18,4.51 Z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 9 -------------------->
      <g transform="matrix(1.383085,0,0,1.383085,654.819,77.356)">
        <path
          d="m 5.7,11.41 c 3.15,0 5.7,-2.55 5.7,-5.7 C 11.4,2.56 8.85,0 5.7,0 2.55,0 0,2.55 0,5.7 c 0,3.15 2.55,5.7 5.7,5.7"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.49,3.67 4.91,4.28 C 4.58,4.03 4.32,3.91 3.92,3.91 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.28 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 6.07 H 3.78 L 3.67,5.26 h 1.86 v 2.4 C 5.03,7.98 4.4,8.17 3.79,8.17 2.31,8.17 1.56,7.28 1.56,5.62 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="m 9.47,5.05 c 0,1.93 -0.92,2.78 -2.95,3.19 L 6.3,7.47 C 7.49,7.18 8.09,6.79 8.28,5.95 8.06,6.22 7.75,6.41 7.3,6.41 6.56,6.41 5.95,5.82 5.95,4.85 c 0,-0.97 0.77,-1.63 1.76,-1.63 1.17,0 1.76,0.76 1.76,1.83 z M 8.34,5.21 c 0,-0.88 -0.17,-1.2 -0.64,-1.2 -0.39,0 -0.64,0.27 -0.64,0.81 0,0.62 0.26,0.82 0.58,0.82 0.27,0 0.51,-0.15 0.7,-0.42 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 10 -------------------->
      <g transform="matrix(1.1646494,0,0,1.1646494,792.06468,77.356)">
        <path
          d="m 6.77,13.55 c 3.74,0 6.77,-3.03 6.77,-6.77 C 13.54,3.04 10.52,0 6.77,0 3.02,0 0,3.03 0,6.77 c 0,3.74 3.03,6.77 6.77,6.77"
          style="fill:#cf971cff"
        />
        <path
          d="M 4.99,5.01 4.45,5.58 C 4.14,5.35 3.9,5.24 3.53,5.24 c -0.63,0 -1.1,0.47 -1.1,1.59 0,1.18 0.31,1.59 0.94,1.59 0.22,0 0.42,-0.04 0.61,-0.15 V 7.25 H 3.4 L 3.3,6.5 H 5.02 V 8.73 C 4.55,9.03 3.97,9.2 3.4,9.2 2.02,9.2 1.33,8.37 1.33,6.83 c 0,-1.54 0.95,-2.36 2.16,-2.36 0.66,0 1.13,0.23 1.5,0.55 z"
          style="fill:#fff;"
        />
        <path
          d="M 7.56,9.08 H 6.55 V 5.69 L 5.61,6.27 5.16,5.59 6.64,4.69 h 0.92 z"
          style="fill:#fff;"
        />
        <path
          d="m 11.65,6.89 c 0,1.47 -0.58,2.3 -1.66,2.3 -1.08,0 -1.66,-0.83 -1.66,-2.3 0,-1.47 0.58,-2.29 1.66,-2.29 1.08,0 1.66,0.82 1.66,2.29 z m -2.26,0 c 0,1.16 0.18,1.54 0.6,1.54 0.42,0 0.6,-0.35 0.6,-1.54 0,-1.19 -0.18,-1.53 -0.6,-1.53 -0.42,0 -0.6,0.37 -0.6,1.53 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 11 -------------------->
      <g transform="matrix(1.1646233,0,0,1.1646233,926.00981,77.356)">
        <path
          d="m 6.77,13.55 c 3.74,0 6.77,-3.03 6.77,-6.77 C 13.54,3.04 10.52,0 6.77,0 3.02,0 0,3.03 0,6.77 c 0,3.74 3.03,6.77 6.77,6.77"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.36,5.17 4.78,5.78 C 4.45,5.53 4.19,5.41 3.79,5.41 c -0.68,0 -1.18,0.5 -1.18,1.71 0,1.28 0.34,1.71 1.01,1.71 0.24,0 0.46,-0.05 0.66,-0.16 V 7.57 H 3.65 L 3.54,6.76 H 5.4 v 2.4 C 4.9,9.48 4.27,9.67 3.66,9.67 2.18,9.67 1.43,8.78 1.43,7.12 c 0,-1.66 1.02,-2.54 2.33,-2.54 0.71,0 1.22,0.25 1.62,0.6 z"
          style="fill:#fff;"
        />
        <path
          d="M 8.13,9.54 H 7.04 V 5.88 L 6.02,6.5 5.54,5.76 7.14,4.79 h 0.99 v 4.73 z"
          style="fill:#fff;"
        />
        <path
          d="M 11.26,9.54 H 10.17 V 5.88 L 9.15,6.5 8.67,5.76 10.27,4.79 h 0.99 v 4.73 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 12 -------------------->
      <g transform="matrix(1.1646494,0,0,1.1646494,1021.439,57.509723)">
        <path
          d="m 6.77,13.55 c 3.74,0 6.77,-3.03 6.77,-6.77 C 13.54,3.04 10.52,0 6.77,0 3.02,0 0,3.03 0,6.77 c 0,3.74 3.03,6.77 6.77,6.77"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.21,5.01 4.67,5.58 C 4.36,5.35 4.12,5.24 3.75,5.24 c -0.63,0 -1.1,0.47 -1.1,1.59 0,1.18 0.31,1.59 0.94,1.59 0.22,0 0.42,-0.04 0.61,-0.15 V 7.25 H 3.62 L 3.52,6.5 H 5.24 V 8.73 C 4.77,9.03 4.19,9.2 3.62,9.2 2.24,9.2 1.55,8.37 1.55,6.83 1.55,5.29 2.5,4.47 3.71,4.47 c 0.66,0 1.13,0.23 1.5,0.55 z"
          style="fill:#fff;"
        />
        <path
          d="M 7.78,9.08 H 6.77 V 5.69 L 5.83,6.27 5.38,5.59 6.86,4.69 h 0.92 z"
          style="fill:#fff;"
        />
        <path
          d="m 11.37,5.85 c 0,0.68 -0.34,1.2 -1.71,2.44 h 1.8 l -0.1,0.79 H 8.5 V 8.35 C 9.98,6.89 10.32,6.5 10.32,5.95 10.32,5.59 10.1,5.38 9.75,5.38 9.43,5.38 9.21,5.52 8.95,5.84 L 8.35,5.36 C 8.7,4.9 9.2,4.6 9.86,4.6 c 0.98,0 1.51,0.57 1.51,1.25 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 13 -------------------->
      <g transform="matrix(1.1646494,0,0,1.1646494,1021.4386,86.238)">
        <path
          d="m 6.77,13.55 c 3.74,0 6.77,-3.03 6.77,-6.77 C 13.54,3.04 10.52,0 6.77,0 3.02,0 0,3.03 0,6.77 c 0,3.74 3.03,6.77 6.77,6.77"
          style="fill:#cf971cff"
        />
        <path
          d="M 5.2,5.01 4.66,5.58 C 4.35,5.35 4.11,5.24 3.74,5.24 c -0.63,0 -1.1,0.47 -1.1,1.59 0,1.18 0.31,1.59 0.94,1.59 C 3.8,8.42 4,8.38 4.19,8.27 V 7.25 H 3.61 L 3.51,6.5 H 5.23 V 8.73 C 4.76,9.03 4.18,9.2 3.61,9.2 2.23,9.2 1.54,8.37 1.54,6.83 1.54,5.29 2.49,4.47 3.7,4.47 c 0.66,0 1.13,0.23 1.5,0.55 z"
          style="fill:#fff;"
        />
        <path
          d="M 7.77,9.08 H 6.76 V 5.69 L 5.82,6.27 5.37,5.59 6.85,4.69 h 0.92 z"
          style="fill:#fff;"
        />
        <path
          d="m 11.32,5.71 c 0,0.53 -0.32,0.89 -0.86,1.05 0.55,0.06 1,0.38 1,1.08 0,0.73 -0.59,1.35 -1.68,1.35 C 9.12,9.19 8.62,8.94 8.27,8.53 L 8.82,7.99 c 0.29,0.29 0.55,0.42 0.9,0.42 0.4,0 0.68,-0.23 0.68,-0.64 0,-0.46 -0.25,-0.63 -0.72,-0.63 H 9.32 L 9.43,6.43 h 0.25 c 0.39,0 0.63,-0.21 0.63,-0.57 0,-0.32 -0.23,-0.51 -0.58,-0.51 -0.33,0 -0.6,0.13 -0.86,0.38 L 8.36,5.18 c 0.41,-0.39 0.9,-0.59 1.48,-0.59 0.95,0 1.46,0.5 1.46,1.12 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 14 -------------------->
      <g transform="matrix(1.1646494,0,0,1.1646494,1096.123,112.68)">
        <path
          d="m 6.77,13.55 c 3.74,0 6.77,-3.03 6.77,-6.77 C 13.54,3.04 10.52,0 6.77,0 3.02,0 0,3.03 0,6.77 c 0,3.74 3.03,6.77 6.77,6.77"
          style="fill:#cf971cff"
        />

        <path
          d="M 5.2,5.01 4.66,5.58 C 4.35,5.35 4.11,5.24 3.74,5.24 c -0.63,0 -1.1,0.47 -1.1,1.59 0,1.18 0.31,1.59 0.94,1.59 C 3.8,8.42 4,8.38 4.19,8.27 V 7.25 H 3.61 L 3.51,6.5 H 5.23 V 8.73 C 4.76,9.03 4.18,9.2 3.61,9.2 2.23,9.2 1.54,8.37 1.54,6.83 1.54,5.29 2.49,4.47 3.7,4.47 c 0.66,0 1.13,0.23 1.5,0.55 z"
          style="fill:#fff;"
        />
        <path
          d="M 7.77,9.08 H 6.76 V 5.69 L 5.82,6.27 5.37,5.59 6.85,4.69 h 0.92 z"
          style="fill:#fff;"
        />
        <path
          d="M 11.686839,8.2243475 H 11.18523 V 9.1897098 H 10.163081 V 8.2243475 H 8.2702142 V 7.5239866 L 9.5384353,4.5900423 10.428083,4.9212941 9.3491486,7.4388076 h 0.8233974 l 0.113572,-1.0600057 h 0.899112 v 1.0600057 h 0.501609 z"
          style="fill:#fff;"
        />
      </g>

      <!-- -------- Gate 15 -------------------->
      <g transform="matrix(1.1646494,0,0,1.1646494,1122.965,112.68)">
        <path
          d="m 6.77,13.55 c 3.74,0 6.77,-3.03 6.77,-6.77 C 13.54,3.04 10.52,0 6.77,0 3.02,0 0,3.03 0,6.77 c 0,3.74 3.03,6.77 6.77,6.77"
          style="fill:#cf971cff"
        />

        <path
          d="M 5.2,5.01 4.66,5.58 C 4.35,5.35 4.11,5.24 3.74,5.24 c -0.63,0 -1.1,0.47 -1.1,1.59 0,1.18 0.31,1.59 0.94,1.59 C 3.8,8.42 4,8.38 4.19,8.27 V 7.25 H 3.61 L 3.51,6.5 H 5.23 V 8.73 C 4.76,9.03 4.18,9.2 3.61,9.2 2.23,9.2 1.54,8.37 1.54,6.83 1.54,5.29 2.49,4.47 3.7,4.47 c 0.66,0 1.13,0.23 1.5,0.55 z"
          style="fill:#fff;"
        />
        <path
          d="M 7.77,9.08 H 6.76 V 5.69 L 5.82,6.27 5.37,5.59 6.85,4.69 h 0.92 z"
          style="fill:#fff;"
        />
        <path
          d="M 11.204158,5.3379052 H 9.5762926 v 0.9464336 c 0.2271441,-0.1041077 0.4542884,-0.141965 0.6625034,-0.141965 0.738218,0 1.28715,0.5300028 1.28715,1.4575078 0,0.927505 -0.690897,1.5900085 -1.7603666,1.5900085 -0.6814323,0 -1.1546491,-0.2839301 -1.4953652,-0.6719679 L 8.8570031,7.978455 c 0.2366084,0.2744658 0.5016098,0.4353595 0.8707189,0.4353595 0.454288,0 0.719289,-0.2744657 0.719289,-0.7950042 0,-0.5583959 -0.255537,-0.7476826 -0.63411,-0.7476826 -0.1798224,0 -0.3312517,0.047322 -0.5394671,0.1608937 H 8.5636086 V 4.5902226 H 11.308266 L 11.18523,5.3379052 Z"
          style="fill:#fff;"
        />
      </g>

      <!----------- Border Control Outbound Title with Logo ----------------------->
      <g transform="matrix(1.3322206,0,0,1.3322206,734.01382,166.31)">
        <path
          d="M 3.84,0 C 3.84,0 0,0 0,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          d="M 4.14,4.77 V 2.92 C 4.14,2.69 3.94,2.53 3.7,2.53 H 1.91 v 2.6 L 3.22,6.16 C 3.4,6.29 3.58,6.13 3.58,5.9 V 5.21 h 0.15 c 0.31,0 0.41,-0.16 0.41,-0.43 M 3.89,4.8 c 0,0.09 -0.06,0.15 -0.15,0.15 H 3.59 V 3.88 C 3.59,3.77 3.57,3.64 3.41,3.52 l -1,-0.71 h 1.31 c 0.07,0 0.17,0.05 0.17,0.15 z"
          style="fill:#fff;"
        />
        <path
          d="m 11.02,5.61 c 0.34,0 0.85,0.18 0.85,0.94 v 5.19 H 7.29 v -1.4 h 3.33 V 9.69 H 7.98 M 10.4,3.24 H 7.78 L 7.25,3.9 h 0.62 c 0,0.74 0.58,1.25 1.25,1.25 0.67,0 1.28,-0.52 1.28,-1.28 V 3.23 Z M 7.12,2.04 h 3.27 V 2.96 H 7.75 Z M 7.28,6.83 V 9.56 L 10.26,5.61 H 7.24 C 6.85,5.61 6.59,5.78 6.36,6.07 L 5.39,7.37 4.01,6.28 C 3.37,5.8 2.65,6.72 3.27,7.21 L 5.08,8.64 C 5.47,8.92 5.78,8.79 5.94,8.63 L 7.28,6.86 Z"
          style="fill:#fff;"
        />
        <text
          transform="translate(16.31,6.97)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Passport
          </tspan>
        </text>
        <text
          transform="translate(16.31,15)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Control
          </tspan>
        </text>
      </g>

      <!----------- Border Control Inbound Title with Logo ----------------------->
      <g transform="matrix(1.3322206,0,0,1.3322206,1215.2634,382.31638)">
        <path
          d="M 3.84,0 C 3.84,0 0,0 0,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          d="M 4.14,4.77 V 2.92 C 4.14,2.69 3.94,2.53 3.7,2.53 H 1.91 v 2.6 L 3.22,6.16 C 3.4,6.29 3.58,6.13 3.58,5.9 V 5.21 h 0.15 c 0.31,0 0.41,-0.16 0.41,-0.43 M 3.89,4.8 c 0,0.09 -0.06,0.15 -0.15,0.15 H 3.59 V 3.88 C 3.59,3.77 3.57,3.64 3.41,3.52 l -1,-0.71 h 1.31 c 0.07,0 0.17,0.05 0.17,0.15 z"
          style="fill:#fff;"
        />
        <path
          d="m 11.02,5.61 c 0.34,0 0.85,0.18 0.85,0.94 v 5.19 H 7.29 v -1.4 h 3.33 V 9.69 H 7.98 M 10.4,3.24 H 7.78 L 7.25,3.9 h 0.62 c 0,0.74 0.58,1.25 1.25,1.25 0.67,0 1.28,-0.52 1.28,-1.28 V 3.23 Z M 7.12,2.04 h 3.27 V 2.96 H 7.75 Z M 7.28,6.83 V 9.56 L 10.26,5.61 H 7.24 C 6.85,5.61 6.59,5.78 6.36,6.07 L 5.39,7.37 4.01,6.28 C 3.37,5.8 2.65,6.72 3.27,7.21 L 5.08,8.64 C 5.47,8.92 5.78,8.79 5.94,8.63 L 7.28,6.86 Z"
          style="fill:#fff;"
        />
        <text
          transform="translate(-10,22.18)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Passport
          </tspan>
        </text>
        <text
          transform="translate(-8,30.18)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Control
          </tspan>
        </text>
      </g>

      <!----------- Baggage Claim International Title with Logo ----------------------->
      <g transform="matrix(1.3557531,0,0,1.3557531,1023.6821,290.87913)">
        <path
          d="M 3.84,0 C 3.84,0 0,0 0,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          d="M 7.45,4.26 H 6.32 V 3.61 h 1.13 z m -1.55,0 h -1 V 9 h 4 V 4.26 h -1 V 3.55 A 0.28,0.28 0 0 0 7.61,3.26 H 6.2 A 0.3,0.3 0 0 0 5.9,3.55 Z m -1.35,0 H 3.83 A 0.51,0.51 0 0 0 3.36,4.8 v 3.55 c 0,0.3 0.19,0.6 0.47,0.6 H 4.55 Z M 9.23,9 h 0.7 A 0.51,0.51 0 0 0 10.41,8.46 V 4.8 A 0.52,0.52 0 0 0 9.93,4.26 h -0.7 z m 0.94,1.1 a 0.57,0.57 0 1 1 0.56,0.56 0.56,0.56 0 0 1 -0.56,-0.56 m -1.92,0 A 0.56,0.56 0 1 1 8.81,10.66 0.56,0.56 0 0 1 8.25,10.1 m -1.92,0 A 0.56,0.56 0 1 1 6.89,10.66 0.56,0.56 0 0 1 6.33,10.1 m -1.93,0 A 0.56,0.56 0 1 1 4.97,10.66 0.56,0.56 0 0 1 4.4,10.1 m -1.92,0 A 0.56,0.56 0 1 1 3.04,10.66 0.56,0.56 0 0 1 2.48,10.1"
          style="fill:#fff;"
        />
        <path
          d="M 2.48,10.05 A 0.56,0.56 0 1 1 3.04,10.61 0.56,0.56 0 0 1 2.48,10.05"
          style="fill:#fff;"
        />
        <path
          d="M 4.4,10.05 A 0.57,0.57 0 1 1 4.97,10.61 0.56,0.56 0 0 1 4.4,10.05"
          style="fill:#fff;"
        />
        <path
          d="M 6.33,10.05 A 0.56,0.56 0 1 1 6.89,10.61 0.56,0.56 0 0 1 6.33,10.05"
          style="fill:#fff;"
        />
        <path
          d="M 8.25,10.05 A 0.56,0.56 0 1 1 8.81,10.61 0.56,0.56 0 0 1 8.25,10.05"
          style="fill:#fff;"
        />
        <path
          d="m 10.17,10.05 a 0.57,0.57 0 1 1 0.56,0.56 0.56,0.56 0 0 1 -0.56,-0.56"
          style="fill:#fff;"
        />
        <path
          d="m 9.23,9 h 0.7 A 0.51,0.51 0 0 0 10.41,8.46 V 4.8 A 0.52,0.52 0 0 0 9.93,4.26 h -0.7 z"
          style="fill:#fff;"
        />
        <path
          d="M 4.55,4.26 H 3.83 A 0.51,0.51 0 0 0 3.36,4.8 v 3.55 c 0,0.3 0.19,0.6 0.47,0.6 h 0.72 z"
          style="fill:#fff;"
        />
        <path
          d="M 5.9,4.26 V 3.55 A 0.3,0.3 0 0 1 6.2,3.26 h 1.37 a 0.28,0.28 0 0 1 0.29,0.29 v 0.71 h 1 V 9 h -4 V 4.26 Z M 7.45,3.61 H 6.32 v 0.66 h 1.13 z"
          style="fill:#fff;"
        />
        <text
          transform="translate(16.31,6.97)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Baggage Claim
          </tspan>
        </text>
        <text
          transform="translate(16.31,15)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            International
          </tspan>
        </text>
      </g>

      <!----------- Airside Dwell Title with Logo ----------------------->
      <g transform="matrix(1.2754055,0,0,1.2754055,318.07699,166.31014)">
        <path
          id="airsideLogo1Bg"
          d="M 3.84,0 C 3.84,0 0,0 0,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          id="airsideLogo1"
          d="M 6.68,6.35 A 0.39,0.39 0 0 1 6.29,6.75 0.4,0.4 0 0 1 5.89,6.35 0.36,0.36 0 0 1 6.12,6 V 6.28 H 6.4 V 6 A 0.38,0.38 0 0 1 6.68,6.35 M 6.11,5.07 H 3.7 V 4.65 a 1.21,1.21 0 0 1 2.41,0 z M 3.7,6.31 V 6 A 0.39,0.39 0 0 1 4,6.35 0.4,0.4 0 1 1 3.42,6 V 6.3 Z M 7.29,5.07 H 6.44 V 4.65 a 1.54,1.54 0 1 0 -3.08,0 V 5.07 H 2.53 A 0.17,0.17 0 0 0 2.36,5.24 l -0.21,6.41 a 0.18,0.18 0 0 0 0.17,0.17 H 7.58 A 0.18,0.18 0 0 0 7.75,11.65 L 7.45,5.24 A 0.16,0.16 0 0 0 7.29,5.07 M 10.6,5 A 0.4,0.4 0 0 1 9.8,5 0.36,0.36 0 0 1 10,4.7 V 5 h 0.28 V 4.67 A 0.38,0.38 0 0 1 10.6,5 M 10,3.74 H 7.61 V 3.32 a 1.21,1.21 0 0 1 2.41,0 z m 1.64,6.58 -0.29,-6.41 A 0.17,0.17 0 0 0 11.18,3.74 H 10.34 V 3.32 a 1.54,1.54 0 1 0 -3.08,0 V 3.74 H 6.72 a 1.45,1.45 0 0 1 0.1,0.51 v 0.42 h 0.85 a 0.17,0.17 0 0 1 0.16,0.17 l 0.26,5.65 h 3.4 a 0.17,0.17 0 0 0 0.17,-0.17"
          style="fill:#fff;"
        />
        <path
          id="airsideLogo2Bg"
          d="m 19.35,0 c 0,0 -3.84,0 -3.84,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          id="airsideLogo2-1"
          d="m 22.7,2.54 a 0.35,0.35 0 0 0 -0.69,0 v 2.23 a 0.34,0.34 0 0 1 -0.68,0 V 2.54 a 0.35,0.35 0 0 0 -0.69,0 v 2.23 a 0.35,0.35 0 0 1 -0.69,0 V 2.54 a 0.34,0.34 0 0 0 -0.68,0 V 6 c 0,0.43 0.59,0.82 1,1 v 4.17 a 0.69,0.69 0 0 0 0.69,0.69 v 0 a 0.69,0.69 0 0 0 0.69,-0.69 V 6.94 c 0.41,-0.15 1,-0.54 1,-1 z"
          style="fill:#fff;"
        />
        <path
          id="airsideLogo2-2"
          d="M 25.14,2.54 A 0.35,0.35 0 0 0 24.79,2.2 2.16,2.16 0 0 0 23.31,4.32 V 8.2 a 0.18,0.18 0 0 0 0.17,0.17 h 0.4 v 2.74 a 0.69,0.69 0 0 0 0.69,0.69 h -0.12 a 0.69,0.69 0 0 0 0.69,-0.69 z"
          style="fill:#fff;"
        />
        <text
          transform="translate(32.46,8.97)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Airside Dwell
          </tspan>
        </text>
      </g>

      <!----------- International Airside Dwell Title with Logo ----------------------->
      <g transform="matrix(1.2754055,0,0,1.2754055,840.91478,122.89709)">
        <path
          d="m 19.35,0 c 0,0 -3.84,0 -3.84,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          d="m 22.7,2.54 a 0.35,0.35 0 0 0 -0.69,0 v 2.23 a 0.34,0.34 0 0 1 -0.68,0 V 2.54 a 0.35,0.35 0 0 0 -0.69,0 v 2.23 a 0.35,0.35 0 0 1 -0.69,0 V 2.54 a 0.34,0.34 0 0 0 -0.68,0 V 6 c 0,0.43 0.59,0.82 1,1 v 4.17 a 0.69,0.69 0 0 0 0.69,0.69 v 0 a 0.69,0.69 0 0 0 0.69,-0.69 V 6.94 c 0.41,-0.15 1,-0.54 1,-1 z"
          style="fill:#fff;"
        />
        <path
          d="M 25.14,2.54 A 0.35,0.35 0 0 0 24.79,2.2 2.16,2.16 0 0 0 23.31,4.32 V 8.2 a 0.18,0.18 0 0 0 0.17,0.17 h 0.4 v 2.74 a 0.69,0.69 0 0 0 0.69,0.69 h -0.12 a 0.69,0.69 0 0 0 0.69,-0.69 z"
          style="fill:#fff;"
        />
        <path
          d="m 3.84,0.11 c 0,0 -3.84,0 -3.84,3.83 v 6.11 c 0,0 0,3.83 3.84,3.83 h 6.1 c 0,0 3.84,0 3.84,-3.83 V 3.94 c 0,0 0,-3.83 -3.84,-3.83 z"
          style="fill:#74b843;"
        />
        <path
          d="M 6.68,6.35 A 0.39,0.39 0 0 1 6.29,6.75 0.4,0.4 0 0 1 5.89,6.35 0.36,0.36 0 0 1 6.12,6 V 6.28 H 6.4 V 6 A 0.38,0.38 0 0 1 6.68,6.35 M 6.11,5.07 H 3.7 V 4.65 a 1.21,1.21 0 0 1 2.41,0 z M 3.7,6.31 V 6 A 0.39,0.39 0 0 1 4,6.35 0.4,0.4 0 1 1 3.42,6 V 6.3 Z M 7.29,5.07 H 6.44 V 4.65 a 1.54,1.54 0 1 0 -3.08,0 V 5.07 H 2.53 A 0.17,0.17 0 0 0 2.36,5.24 l -0.21,6.41 a 0.18,0.18 0 0 0 0.17,0.17 H 7.58 A 0.18,0.18 0 0 0 7.75,11.65 L 7.45,5.24 A 0.16,0.16 0 0 0 7.29,5.07 M 10.6,5 A 0.4,0.4 0 0 1 9.8,5 0.36,0.36 0 0 1 10,4.7 V 5 h 0.28 V 4.67 A 0.38,0.38 0 0 1 10.6,5 M 10,3.74 H 7.61 V 3.32 a 1.21,1.21 0 0 1 2.41,0 z m 1.64,6.58 -0.29,-6.41 A 0.17,0.17 0 0 0 11.18,3.74 H 10.34 V 3.32 a 1.54,1.54 0 1 0 -3.08,0 V 3.74 H 6.72 a 1.45,1.45 0 0 1 0.1,0.51 v 0.42 h 0.85 a 0.17,0.17 0 0 1 0.16,0.17 l 0.26,5.65 h 3.4 a 0.17,0.17 0 0 0 0.17,-0.17"
          style="fill:#fff;"
        />
        <text
          transform="translate(32.46,6.97)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Airside Dwell
          </tspan>
        </text>
        <text
          transform="translate(32.46,15)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            International
          </tspan>
        </text>
      </g>

      <!----------- Baggage Claim Title with Logo ----------------------->
      <g transform="matrix(1.3557531,0,0,1.3557531,873.32475,290.87913)">
        <path
          id="baggageLogoBg"
          d="M 3.84,0 C 3.84,0 0,0 0,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          id="baggageLogo"
          d="M 7.45,4.26 H 6.32 V 3.61 h 1.13 z m -1.55,0 h -1 V 9 h 4 V 4.26 h -1 V 3.55 A 0.28,0.28 0 0 0 7.61,3.26 H 6.2 A 0.3,0.3 0 0 0 5.9,3.55 Z m -1.35,0 H 3.83 A 0.51,0.51 0 0 0 3.36,4.8 v 3.55 c 0,0.3 0.19,0.6 0.47,0.6 H 4.55 Z M 9.23,9 h 0.7 A 0.51,0.51 0 0 0 10.41,8.46 V 4.8 A 0.52,0.52 0 0 0 9.93,4.26 h -0.7 z m 0.94,1.1 a 0.57,0.57 0 1 1 0.56,0.56 0.56,0.56 0 0 1 -0.56,-0.56 m -1.92,0 A 0.56,0.56 0 1 1 8.81,10.66 0.56,0.56 0 0 1 8.25,10.1 m -1.92,0 A 0.56,0.56 0 1 1 6.89,10.66 0.56,0.56 0 0 1 6.33,10.1 m -1.93,0 A 0.56,0.56 0 1 1 4.97,10.66 0.56,0.56 0 0 1 4.4,10.1 m -1.92,0 A 0.56,0.56 0 1 1 3.04,10.66 0.56,0.56 0 0 1 2.48,10.1"
          style="fill:#fff;"
        />
        <text
          transform="translate(16.31,8.36)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Baggage Claim
          </tspan>
        </text>
      </g>

      <!----------- Customs Title with Logo ----------------------->
      <g transform="matrix(1.332226,0,0,1.332226,1014.4901,367.72464)">
        <g transform="translate(-32.984249,5.7866908)">
          <path
            d="m 21.56,0 c 0,0 -3.83,0 -3.83,3.84 v 6.1 c 0,0 0,3.84 3.83,3.84 h 6.11 c 0,0 3.83,0 3.83,-3.84 v -6.1 c 0,0 0,-3.84 -3.83,-3.84 z"
            style="fill:#74b843;"
          />
          <polygon
            points="28.38,2.7 25.54,2.7 26.09,3.49 28.38,3.49 "
            style="fill:#fff;"
          />
          <path
            d="m 28.39,3.74 h -2.28 l -0.46,0.58 h 0.54 a 1.07,1.07 0 0 0 1.09,1.09 1.11,1.11 0 0 0 1.11,-1.11 z"
            style="fill:#fff;"
          />
          <path
            d="M 24.52,11.08 A 0.49,0.49 0 0 0 25,10.57 V 9.84 h -4.07 v 0.72 a 0.48,0.48 0 0 0 0.48,0.52 z"
            style="fill:#fff;"
          />
          <path
            d="m 19.72,9.35 a 0.48,0.48 0 0 0 0.5,0.49 h 0.71 V 5.72 h -0.71 a 0.48,0.48 0 0 0 -0.5,0.5 z"
            style="fill:#fff;"
          />
          <path
            d="m 28.77,5.74 a 0.77,0.77 0 0 1 0.74,0.81 v 4.53 h -4 V 6.8 l -2,2.7 A 0.53,0.53 0 1 1 22.67,8.86 L 24.83,6.05 A 0.79,0.79 0 0 1 25.5,5.74 h 0.85 L 27,6.87 27.2,6.13 26.96,5.74 h 0.69 l -0.24,0.39 0.2,0.74 0.62,-1.13 z"
            style="fill:#fff;"
          />
          <text
            transform="translate(33.31,8.36)"
            style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
          >
            <tspan x="0" y="0">
              Customs
            </tspan>
          </text>
        </g>
      </g>

      <!----------- PLAGS Title with Logo ----------------------->
      <g transform="matrix(1.332226,0,0,1.332226,644.94976,158.60082)">
        <g transform="translate(-32.984249,5.7866908)">
          <path
            d="m 21.56,0 c 0,0 -3.83,0 -3.83,3.84 v 6.1 c 0,0 0,3.84 3.83,3.84 h 6.11 c 0,0 3.83,0 3.83,-3.84 v -6.1 c 0,0 0,-3.84 -3.83,-3.84 z"
            style="fill:#74b843;"
          />
          <polygon
            points="28.38,2.7 25.54,2.7 26.09,3.49 28.38,3.49 "
            style="fill:#fff;"
          />
          <path
            d="m 28.39,3.74 h -2.28 l -0.46,0.58 h 0.54 a 1.07,1.07 0 0 0 1.09,1.09 1.11,1.11 0 0 0 1.11,-1.11 z"
            style="fill:#fff;"
          />
          <path
            d="M 24.52,11.08 A 0.49,0.49 0 0 0 25,10.57 V 9.84 h -4.07 v 0.72 a 0.48,0.48 0 0 0 0.48,0.52 z"
            style="fill:#fff;"
          />
          <path
            d="m 19.72,9.35 a 0.48,0.48 0 0 0 0.5,0.49 h 0.71 V 5.72 h -0.71 a 0.48,0.48 0 0 0 -0.5,0.5 z"
            style="fill:#fff;"
          />
          <path
            d="m 28.77,5.74 a 0.77,0.77 0 0 1 0.74,0.81 v 4.53 h -4 V 6.8 l -2,2.7 A 0.53,0.53 0 1 1 22.67,8.86 L 24.83,6.05 A 0.79,0.79 0 0 1 25.5,5.74 h 0.85 L 27,6.87 27.2,6.13 26.96,5.74 h 0.69 l -0.24,0.39 0.2,0.74 0.62,-1.13 z"
            style="fill:#fff;"
          />
          <text
            transform="translate(33.31,8.36)"
            style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
          >
            <tspan x="0" y="0">
              PLAGS
            </tspan>
          </text>
        </g>
      </g>

      <!----------- Security Title with Logo ----------------------->
      <g transform="matrix(1.332226,0,0,1.332226,507.29864,373.54835)">
        <path
          id="securityLogoBg"
          d="m 21.56,0 c 0,0 -3.83,0 -3.83,3.84 v 6.1 c 0,0 0,3.84 3.83,3.84 h 6.11 c 0,0 3.83,0 3.83,-3.84 v -6.1 c 0,0 0,-3.84 -3.83,-3.84 z"
          style="fill:#74b843;"
        />
        <polygon
          id="securityLogo1"
          points="28.38,2.7 25.54,2.7 26.09,3.49 28.38,3.49"
          style="fill:#fff;"
        />
        <path
          id="securityLogo2"
          d="m 28.39,3.74 h -2.28 l -0.46,0.58 h 0.54 a 1.07,1.07 0 0 0 1.09,1.09 1.11,1.11 0 0 0 1.11,-1.11 z"
          style="fill:#fff;"
        />
        <path
          id="securityLogo3"
          d="M 24.52,11.08 A 0.49,0.49 0 0 0 25,10.57 V 9.84 h -4.07 v 0.72 a 0.48,0.48 0 0 0 0.48,0.52 z"
          style="fill:#fff;"
        />
        <path
          id="securityLogo4"
          d="m 19.72,9.35 a 0.48,0.48 0 0 0 0.5,0.49 h 0.71 V 5.72 h -0.71 a 0.48,0.48 0 0 0 -0.5,0.5 z"
          style="fill:#fff;"
        />
        <path
          id="securityLogo5"
          d="m 28.77,5.74 a 0.77,0.77 0 0 1 0.74,0.81 v 4.53 h -4 V 6.8 l -2,2.7 A 0.53,0.53 0 1 1 22.67,8.86 L 24.83,6.05 A 0.79,0.79 0 0 1 25.5,5.74 h 0.85 L 27,6.87 27.2,6.13 26.96,5.74 h 0.69 l -0.24,0.39 0.2,0.74 0.62,-1.13 z"
          style="fill:#fff;"
        />
        <text
          transform="translate(-10,22.18)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Security Screening
          </tspan>
        </text>
      </g>

      <!----------- Check-In Title with Logo ----------------------->
      <g transform="matrix(1.3284734,0,0,1.3284734,290.83388,366.37254)">
        <path
          id="checkInLogoBg-1"
          d="m 23.12,0 c 0,0 -3.84,0 -3.84,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.84,0 3.84,-3.84 v -6.1 c 0,0 0,-3.84 -3.84,-3.84 z"
          style="fill:#74b843;"
        />
        <path
          id="checkInLogo1-1"
          d="M 28.43,3.76 A 0.73,0.73 0 1 0 27.7,3 0.73,0.73 0 0 0 28.43,3.73"
          style="fill:#fff;"
        />
        <path
          id="checkInLogo1-2"
          d="M 24,6.41 23.82,5.86 23.98,4.72 c 0,0 0.28,0.88 0.32,1 0.04,0.12 0.21,0.29 0.44,0 0.38,-0.42 0.81,-0.93 0.86,-1 a 0.26,0.26 0 0 0 0,-0.4 c -0.13,-0.09 -0.23,0 -0.37,0.1 L 24.69,5.04 24.28,4 A 0.49,0.49 0 0 0 23.84,3.67 H 22.41 A 0.47,0.47 0 0 0 22,4 l -0.78,2.43 h 0.57 l 0.55,-1.7 0.16,1.15 -0.18,0.55 z"
          style="fill:#fff;"
        />
        <path
          id="checkInLogo1-3"
          d="M 23.12,3.5 A 0.73,0.73 0 1 0 22.39,2.77 0.73,0.73 0 0 0 23.12,3.5"
          style="fill:#fff;"
        />
        <path
          id="checkInLogo1-4"
          d="M 31.26,6.66 H 21.08 a 0.33,0.33 0 1 0 0,0.66 h 10.18 a 0.33,0.33 0 1 0 0,-0.66"
          style="fill:#fff;"
        />
        <path
          id="checkInLogo1-5"
          d="m 30.52,7.18 v 0 L 29.58,4.24 v 0 A 0.49,0.49 0 0 0 29.15,3.91 h -1.44 a 0.5,0.5 0 0 0 -0.43,0.33 v 0 l -1,2.07 H 26.9 L 27.6,5 v 1.57 c -0.07,0.46 -0.7,4.89 -0.7,4.9 0,0.01 0.19,0.26 0.44,0.26 0.25,0 0.38,0 0.43,-0.2 0.05,-0.2 0.54,-3.6 0.54,-3.6 0,0 0,-0.06 0,-0.08 a 0.17,0.17 0 0 1 0.09,0 0.13,0.13 0 0 1 0.08,0 c 0,0 0,0 0,0 0.08,0.57 0.55,3.6 0.57,3.66 0.02,0.06 0.26,0.2 0.43,0.2 0.17,0 0.44,-0.12 0.44,-0.26 0,-0.14 -0.72,-5 -0.72,-5 v 0 V 5 L 30,7.31 a 0.27,0.27 0 0 0 0.53,0 0.19,0.19 0 0 0 0,-0.08"
          style="fill:#fff;"
        />
        <path
          id="checkInLogoBg-2"
          d="m 37,0 c 0,0 -3.84,0 -3.84,3.84 v 6.1 c 0,0 0,3.84 3.84,3.84 h 6.1 c 0,0 3.9,0 3.9,-3.84 V 3.84 C 47,3.84 47,0 43.11,0 Z"
          style="fill:#74b843;"
        />
        <path
          id="checkInLogo2-1"
          d="m 42.4,7.93 h 0.7 A 0.51,0.51 0 0 0 43.58,7.39 V 3.78 A 0.52,0.52 0 0 0 43.1,3.24 h -0.7 z"
          style="fill:#fff;"
        />
        <path
          id="checkInLogo2-2"
          d="M 37.72,3.24 H 37 a 0.51,0.51 0 0 0 -0.47,0.54 v 3.55 c 0,0.3 0.19,0.6 0.47,0.6 h 0.72 z"
          style="fill:#fff;"
        />
        <path
          id="checkInLogo2-3"
          d="M 39.07,3.24 V 2.53 a 0.3,0.3 0 0 1 0.3,-0.29 h 1.37 a 0.28,0.28 0 0 1 0.29,0.29 v 0.71 h 1 v 4.69 h -4 V 3.24 Z m 1.55,-0.65 h -1.13 v 0.65 h 1.13 z"
          style="fill:#fff;"
        />
        <path
          id="checkInLogo2-4"
          d="m 41.57,10.65 a 0.27,0.27 0 0 1 -0.07,0.18 l -1.31,1.31 a 0.25,0.25 0 0 1 -0.18,0.08 0.23,0.23 0 0 1 -0.18,-0.08 l -1.31,-1.31 a 0.23,0.23 0 0 1 -0.08,-0.18 0.25,0.25 0 0 1 0.08,-0.18 l 0.15,-0.15 a 0.24,0.24 0 0 1 0.36,0 l 0.59,0.59 v -2 a 0.24,0.24 0 0 1 0.26,-0.24 h 0.26 a 0.24,0.24 0 0 1 0.25,0.24 v 2 l 0.59,-0.59 a 0.27,0.27 0 0 1 0.18,-0.08 0.27,0.27 0 0 1 0.19,0.08 l 0.15,0.15 a 0.29,0.29 0 0 1 0.07,0.18"
          style="fill:#fff;"
        />
        <text
          transform="translate(-15,22.18)"
          style="font-size:8px; fill:#003d4e; font-family:FiraSans-Regular, Fira Sans;"
        >
          <tspan x="0" y="0">
            Check-in and Baggage Drop
          </tspan>
        </text>
      </g>

      <defs>
        <pattern id="image" x="0" y="0" height="100%" width="100%">
          <image
            x="0"
            y="0"
            width="20"
            height="20"
            href="../assets/avatar2.jpg"
          ></image>
        </pattern>
        <!-- id="linearGradient119576" -->
        <linearGradient
          id="linearGradient117578"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0.86885327,0,0,1,228.79392,32.225025)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        >
          <stop
            style="stop-color:#fe2d2d;stop-opacity:1"
            offset="0"
            id="stop119572"
          />
          <stop
            style="stop-color:#ff0303;stop-opacity:1"
            offset="1"
            id="stop119574"
          />
        </linearGradient>
        <linearGradient id="linearGradient115676">
          <stop
            style="stop-color:#fe2d2d;stop-opacity:1"
            offset="0"
            id="stop115674"
          />
          <stop
            style="stop-color:#fe1a1a;stop-opacity:1"
            offset="1"
            id="stop115672"
          />
        </linearGradient>
        <linearGradient id="linearGradient115494">
          <stop
            style="stop-color:#fd4343;stop-opacity:1"
            offset="0"
            id="stop115490"
          />
          <stop
            style="stop-color:#fe2d2d;stop-opacity:1"
            offset="1"
            id="stop115492"
          />
        </linearGradient>
        <linearGradient id="linearGradient112777">
          <stop
            style="stop-color:#fd4343;stop-opacity:1"
            offset="0"
            id="stop112773"
          />
          <stop
            style="stop-color:#fd5a5a;stop-opacity:1"
            offset="1"
            id="stop112775"
          />
        </linearGradient>
        <linearGradient id="linearGradient109723">
          <stop
            style="stop-color:#fc6f6f;stop-opacity:1"
            offset="0"
            id="stop109719"
          />
          <stop
            style="stop-color:#fd5a5a;stop-opacity:1"
            offset="1"
            id="stop109721"
          />
        </linearGradient>
        <linearGradient id="linearGradient106850">
          <stop
            style="stop-color:#fc6f6f;stop-opacity:1"
            offset="0"
            id="stop106846"
          />
          <stop
            style="stop-color:#fc8585;stop-opacity:1"
            offset="1"
            id="stop106848"
          />
        </linearGradient>
        <linearGradient id="linearGradient104055">
          <stop
            style="stop-color:#fb9c9c;stop-opacity:1"
            offset="0"
            id="stop104051"
          />
          <stop
            style="stop-color:#fc8585;stop-opacity:1"
            offset="1"
            id="stop104053"
          />
        </linearGradient>
        <linearGradient id="linearGradient101962">
          <stop
            style="stop-color:#fb9c9c;stop-opacity:1"
            offset="0"
            id="stop101958"
          />
          <stop
            style="stop-color:#fbbbbb;stop-opacity:1"
            offset="1"
            id="stop101960"
          />
        </linearGradient>
        <linearGradient id="linearGradient96593">
          <stop
            style="stop-color:#fadada;stop-opacity:1"
            offset="0"
            id="stop96589"
          />
          <stop
            style="stop-color:#fbbbbb;stop-opacity:1"
            offset="1"
            id="stop96591"
          />
        </linearGradient>
        <!-- id="linearGradient78511" -->
        <linearGradient
          id="linearGradient78513"
          x1="213.82944"
          y1="459.88925"
          x2="198.58067"
          y2="435.62985"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1,0,0,0.88852915,5.62439,49.264247)"
        >
          <stop
            style="stop-color:#fadada;stop-opacity:1"
            offset="0"
            id="stop78507"
          />
          <stop
            style="stop-color:#ffc107;stop-opacity:1"
            offset="1"
            id="stop78509"
          />
        </linearGradient>
        <linearGradient id="linearGradient75261">
          <stop
            style="stop-color:#fdc847;stop-opacity:1"
            offset="0"
            id="stop75257"
          />
          <stop
            style="stop-color:#ffc923;stop-opacity:1"
            offset="1"
            id="stop75259"
          />
        </linearGradient>
        <linearGradient id="linearGradient73012">
          <stop
            style="stop-color:#ffc923;stop-opacity:1"
            offset="0"
            id="stop73008"
          />
          <stop
            style="stop-color:#ffce32;stop-opacity:1"
            offset="1"
            id="stop73010"
          />
        </linearGradient>
        <linearGradient id="linearGradient70412">
          <stop
            style="stop-color:#ffce32;stop-opacity:1"
            offset="0"
            id="stop70408"
          />
          <stop
            style="stop-color:#ffd241;stop-opacity:1"
            offset="1"
            id="stop70410"
          />
        </linearGradient>
        <linearGradient id="linearGradient67787">
          <stop
            style="stop-color:#ffd241;stop-opacity:1"
            offset="0"
            id="stop67783"
          />
          <stop
            style="stop-color:#ffd751;stop-opacity:1"
            offset="1"
            id="stop67785"
          />
        </linearGradient>
        <linearGradient id="linearGradient64992">
          <stop
            style="stop-color:#ffd751;stop-opacity:1"
            offset="0"
            id="stop64988"
          />
          <stop
            style="stop-color:#ffdc62;stop-opacity:1"
            offset="1"
            id="stop64990"
          />
        </linearGradient>
        <linearGradient id="linearGradient60871">
          <stop
            style="stop-color:#ffdc62;stop-opacity:1"
            offset="0"
            id="stop60867"
          />
          <stop
            style="stop-color:#ffe171;stop-opacity:1"
            offset="1"
            id="stop60869"
          />
        </linearGradient>
        <linearGradient id="linearGradient56998">
          <stop
            style="stop-color:#ffe171;stop-opacity:1"
            offset="0"
            id="stop56994"
          />
          <stop
            style="stop-color:#ffe682;stop-opacity:1"
            offset="1"
            id="stop56996"
          />
        </linearGradient>
        <linearGradient id="linearGradient53841">
          <stop
            style="stop-color:#ffe682;stop-opacity:1"
            offset="0"
            id="stop53837"
          />
          <stop
            style="stop-color:#ffec97;stop-opacity:1"
            offset="1"
            id="stop53839"
          />
        </linearGradient>
        <linearGradient id="linearGradient43785">
          <stop
            style="stop-color:#ffec97;stop-opacity:1"
            offset="0"
            id="stop43781"
          />
          <stop
            style="stop-color:#bbe276;stop-opacity:1"
            offset="1"
            id="stop43783"
          />
        </linearGradient>
        <linearGradient id="linearGradient37988">
          <stop
            style="stop-color:#37c41e;stop-opacity:1"
            offset="0"
            id="stop37984"
          />
          <stop
            style="stop-color:#bce277;stop-opacity:1"
            offset="1"
            id="stop37986"
          />
        </linearGradient>
        <linearGradient id="linearGradient33571">
          <stop
            style="stop-color:#48c830;stop-opacity:1"
            offset="0"
            id="stop33567"
          />
          <stop
            style="stop-color:#37c41e;stop-opacity:1"
            offset="1"
            id="stop33569"
          />
        </linearGradient>
        <linearGradient id="linearGradient30932">
          <stop
            style="stop-color:#59cc42;stop-opacity:1"
            offset="0"
            id="stop30928"
          />
          <stop
            style="stop-color:#48c830;stop-opacity:1"
            offset="1"
            id="stop30930"
          />
        </linearGradient>
        <linearGradient id="linearGradient26981">
          <stop
            style="stop-color:#6cd056;stop-opacity:1"
            offset="0"
            id="stop26977"
          />
          <stop
            style="stop-color:#59cc42;stop-opacity:1"
            offset="1"
            id="stop26979"
          />
        </linearGradient>
        <linearGradient id="linearGradient23654">
          <stop
            style="stop-color:#7dd468;stop-opacity:1"
            offset="0"
            id="stop23650"
          />
          <stop
            style="stop-color:#6cd056;stop-opacity:1"
            offset="1"
            id="stop23652"
          />
        </linearGradient>
        <linearGradient id="linearGradient20603">
          <stop
            style="stop-color:#91d87d;stop-opacity:1"
            offset="0"
            id="stop20599"
          />
          <stop
            style="stop-color:#7dd468;stop-opacity:1"
            offset="1"
            id="stop20601"
          />
        </linearGradient>
        <linearGradient id="linearGradient16092">
          <stop
            style="stop-color:#a1dc8e;stop-opacity:1"
            offset="0"
            id="stop16088"
          />
          <stop
            style="stop-color:#91d87d;stop-opacity:1"
            offset="1"
            id="stop16090"
          />
        </linearGradient>
        <linearGradient id="linearGradient9545">
          <stop
            style="stop-color:#b4e0a2;stop-opacity:1"
            offset="0"
            id="stop9541"
          />
          <stop
            style="stop-color:#a1dc8e;stop-opacity:1"
            offset="1"
            id="stop9543"
          />
        </linearGradient>
        <linearGradient id="linearGradient8464">
          <stop
            style="stop-color:#c9e6b9;stop-opacity:1"
            offset="0"
            id="stop8460"
          />
          <stop
            style="stop-color:#b4e0a2;stop-opacity:1"
            offset="1"
            id="stop8462"
          />
        </linearGradient>
        <linearGradient id="linearGradient6166">
          <stop
            style="stop-color:#d9e8c9;stop-opacity:1"
            offset="0"
            id="stop6164"
          />
          <stop
            style="stop-color:#c9e6b9;stop-opacity:1"
            offset="1"
            id="stop6162"
          />
        </linearGradient>

        <!----- Gradient for CeckIn queue --------->
        <linearGradient
          xlink:href="#linearGradient6166"
          id="linearGradient6168"
          x1="209.52386"
          y1="410.71573"
          x2="222.51187"
          y2="431.79065"
          gradientUnits="userSpaceOnUse"
        />
        <linearGradient
          xlink:href="#linearGradient8464"
          id="linearGradient8466"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
          gradientUnits="userSpaceOnUse"
        />
        <linearGradient
          xlink:href="#linearGradient9545"
          id="linearGradient9508"
          gradientUnits="userSpaceOnUse"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
          gradientTransform="translate(24.995828)"
        />
        <linearGradient
          xlink:href="#linearGradient16092"
          id="linearGradient16055"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(49.991657)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient20603"
          id="linearGradient20566"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(74.98749)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient23654"
          id="linearGradient23409"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(99.98332)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient26981"
          id="linearGradient26944"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(124.97915)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient30932"
          id="linearGradient30751"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(149.97498)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient33571"
          id="linearGradient33534"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(174.97081)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient37988"
          id="linearGradient37474"
          x1="425.26843"
          y1="419.77264"
          x2="437.01608"
          y2="435.62982"
          gradientUnits="userSpaceOnUse"
        />
        <linearGradient
          xlink:href="#linearGradient43785"
          id="linearGradient40426"
          x1="414.83603"
          y1="443.94733"
          x2="437.01608"
          y2="435.62982"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0,-2)"
        />
        <linearGradient
          xlink:href="#linearGradient53841"
          id="linearGradient53804"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(169.04374,16.28312)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient56998"
          id="linearGradient56753"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(144.04791,16.28312)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient60871"
          id="linearGradient60834"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(119.05208,16.283121)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient64992"
          id="linearGradient64955"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(94.05625,16.283123)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient67787"
          id="linearGradient67750"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(69.060416,16.283125)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient70412"
          id="linearGradient70375"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.06459,16.283121)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient73012"
          id="linearGradient72975"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.068762,16.283119)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient75261"
          id="linearGradient75224"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0.74707409,0,0,1,57.757724,16.283115)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <!-- <linearGradient
              xlink:href="#linearGradient78511"
              id="linearGradient78513"
              x1="213.82944"
              y1="459.88925"
              x2="198.58067"
              y2="435.62985"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(1,0,0,0.88852915,5.62439,49.264247)" /> -->
        <linearGradient
          xlink:href="#linearGradient96593"
          id="linearGradient96330"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.6573544,32.225023)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient101962"
          id="linearGradient99663"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,492.24196,32.225022)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient104055"
          id="linearGradient102107"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.64902,32.22502)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient106850"
          id="linearGradient104200"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,542.23364,32.225018)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient109723"
          id="linearGradient106995"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(100.6407,32.225019)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient112777"
          id="linearGradient109932"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,592.22532,32.22502)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient115494"
          id="linearGradient112922"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(150.63238,32.225023)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          xlink:href="#linearGradient115676"
          id="linearGradient115639"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(175.62822,32.225024)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <!-- <linearGradient
              xlink:href="#linearGradient119576"
              id="linearGradient117578"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(0.86885327,0,0,1,228.79392,32.225025)"
              x1="220.79647"
              y1="425.66422"
              x2="245.7923"
              y2="425.66422" /> -->

        <!------  Gradients for security -------------->
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient6166"
          id="linearGradient120142"
          gradientUnits="userSpaceOnUse"
          x1="209.52386"
          y1="410.71573"
          x2="222.51187"
          y2="431.79065"
          gradientTransform="matrix(-1,0,0,1,796.78438,0.26184636)"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient122750"
          id="linearGradient120178"
          gradientUnits="userSpaceOnUse"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
          gradientTransform="matrix(-1,0,0,1,796.78437,0.2618458)"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient124464"
          id="linearGradient123023"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(305.19977,0.2618471)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient126440"
          id="linearGradient124609"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,746.79271,0.261843)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient37988"
          id="linearGradient126585"
          gradientUnits="userSpaceOnUse"
          x1="425.26843"
          y1="419.77264"
          x2="437.01608"
          y2="435.62982"
          gradientTransform="matrix(-0.68275115,0,0,1.1406372,788.2769,-59.602386)"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient128335"
          id="linearGradient126621"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-0.62430392,0,0,0.67313619,762.73451,144.055)"
          x1="414.83603"
          y1="443.94733"
          x2="437.01608"
          y2="435.62982"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient134438"
          id="linearGradient132412"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,749.54305,17.227799)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient136010"
          id="linearGradient134647"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(307.95011,17.227796)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient137986"
          id="linearGradient136155"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,799.53471,17.227794)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient138613"
          id="linearGradient138371"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0,-0.62898391,-0.6774111,0,873.83871,717.76809)"
          x1="425.26843"
          y1="419.77264"
          x2="437.01608"
          y2="435.62982"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient142823"
          id="linearGradient140926"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0,0.38547429,-0.83449196,0,940.70241,290.92153)"
          x1="425.26843"
          y1="419.77264"
          x2="437.01608"
          y2="435.62982"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient146790"
          id="linearGradient145322"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,797.9693,33.715766)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient150069"
          id="linearGradient150032"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1,0,0,1,772.97347,33.715769)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient153593"
          id="linearGradient152269"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0.87822086,0,0,1,312.05192,33.715774)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />
        <linearGradient
          inkscape:collect="always"
          xlink:href="#linearGradient155491"
          id="linearGradient153738"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-0.7078967,0,0,1,663.42474,33.715772)"
          x1="220.79647"
          y1="425.66422"
          x2="245.7923"
          y2="425.66422"
        />

        <!------  Security gradient colours -------------->
        <linearGradient inkscape:collect="always" id="linearGradient6166">
          <stop
            style="stop-color:#d9e8c9;stop-opacity:1"
            offset="0"
            id="stop6164"
          />
          <stop
            style="stop-color:#c9e6b9;stop-opacity:1"
            offset="1"
            id="stop6162"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient122750">
          <stop
            style="stop-color:#c9e6b9;stop-opacity:1"
            offset="0"
            id="stop122746"
          />
          <stop
            style="stop-color:#8fd87b;stop-opacity:1"
            offset="1"
            id="stop122748"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient124464">
          <stop
            style="stop-color:#64ce4e;stop-opacity:1"
            offset="0"
            id="stop124460"
          />
          <stop
            style="stop-color:#8fd87b;stop-opacity:1"
            offset="1"
            id="stop124462"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient126440">
          <stop
            style="stop-color:#64ce4e;stop-opacity:1"
            offset="0"
            id="stop126436"
          />
          <stop
            style="stop-color:#3bc522;stop-opacity:1"
            offset="1"
            id="stop126438"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient37988">
          <stop
            style="stop-color:#37c41e;stop-opacity:1"
            offset="0"
            id="stop37984"
          />
          <stop
            style="stop-color:#bce277;stop-opacity:1"
            offset="1"
            id="stop37986"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient134438">
          <stop
            style="stop-color:#ffdc62;stop-opacity:1"
            offset="0"
            id="stop134434"
          />
          <stop
            style="stop-color:#ffec97;stop-opacity:1"
            offset="1"
            id="stop134436"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient136010">
          <stop
            style="stop-color:#ffdc62;stop-opacity:1"
            offset="0"
            id="stop136006"
          />
          <stop
            style="stop-color:#ffd13b;stop-opacity:1"
            offset="1"
            id="stop136008"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient137986">
          <stop
            style="stop-color:#ffc618;stop-opacity:1"
            offset="0"
            id="stop137982"
          />
          <stop
            style="stop-color:#ffd13b;stop-opacity:1"
            offset="1"
            id="stop137984"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient128335">
          <stop
            style="stop-color:#ffec97;stop-opacity:1"
            offset="0"
            id="stop128331"
          />
          <stop
            style="stop-color:#afdf6e;stop-opacity:1"
            offset="1"
            id="stop128333"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient138613">
          <stop
            style="stop-color:#ffc11d;stop-opacity:1"
            offset="0"
            id="stop138609"
          />
          <stop
            style="stop-color:#ffc618;stop-opacity:1"
            offset="1"
            id="stop138611"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient146790">
          <stop
            style="stop-color:#fbafaf;stop-opacity:1"
            offset="0"
            id="stop146786"
          />
          <stop
            style="stop-color:#fc9191;stop-opacity:1"
            offset="1"
            id="stop146788"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient142823">
          <stop
            style="stop-color:#ffc11d;stop-opacity:1"
            offset="0"
            id="stop142819"
          />
          <stop
            style="stop-color:#fbafaf;stop-opacity:1"
            offset="1"
            id="stop142821"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient150069">
          <stop
            style="stop-color:#fc9191;stop-opacity:1"
            offset="0"
            id="stop150067"
          />
          <stop
            style="stop-color:#fd5e5e;stop-opacity:1"
            offset="1"
            id="stop150065"
          />
        </linearGradient>
        <linearGradient inkscape:collect="always" id="linearGradient153593">
          <stop
            style="stop-color:#fe2f2f;stop-opacity:1"
            offset="0"
            id="stop153589"
          />
          <stop
            style="stop-color:#fd5e5e;stop-opacity:1"
            offset="1"
            id="stop153591"
          />
        </linearGradient>
        <linearGradient id="linearGradient155491">
          <stop
            style="stop-color:#fe2f2f;stop-opacity:1"
            offset="0"
            id="stop155487"
          />
          <stop
            style="stop-color:#ff0a0a;stop-opacity:1"
            offset="1"
            id="stop155489"
          />
        </linearGradient>

        <!----------- Progress bar defs ---------------->
        <linearGradient
          id="progress-bar-gradient"
          xlink:href="#queueGradient"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.153398,-0.55242718)"
          x1="308.88695"
          y1="150.06183"
          x2="393.59647"
          y2="150.06183"
        />
        <linearGradient
          xlink:href="#queueGradient"
          id="progress-bar-gradient-small"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0.7261158,0,0,1,96.633746,-0.55242718)"
          x1="308.88695"
          y1="150.06183"
          x2="393.59647"
          y2="150.06183"
        />
        <linearGradient id="queueGradient">
          <stop style="stop-color:#38d556;stop-opacity:1" offset="0" />
          <stop style="stop-color:#f5ef25;stop-opacity:1" offset="0.5" />
          <stop style="stop-color:#f60000;stop-opacity:1" offset="1" />
        </linearGradient>

        <filter id="insetShadow" filterunits="userSpaceOnUse">
          <feFlood flood-color="#000" flood-opacity="1"></feFlood>
          <feComposite in2="SourceGraphic" operator="xor"></feComposite>
          <feGaussianBlur stdDeviation="3"></feGaussianBlur>
          <feOffset dx="0" dy="0" result="offsetblur"></feOffset>
          <feFlood flood-color="#aaa" flood-opacity="0.8"></feFlood>
          <feComposite in2="offsetblur" operator="atop"></feComposite>
          <feComposite in2="SourceGraphic" operator="in"></feComposite>
          <feMerge>
            <feMergeNode in="SourceGraphic"></feMergeNode>
            <feMergeNode></feMergeNode>
          </feMerge>
        </filter>
        <!----------- END Progress bar defs ---------------->
      </defs>

      <circle
        r="10"
        cy="0"
        cx="0"
        id="dot"
        visibility="hidden"
        stroke="#36bd36c7"
        stroke-width="1"
        fill="url(#image)"
      />
      <circle r="10" cy="0" cx="0" id="dot2" class="d-none" fill="green" />
      <text
        class="svg-text"
        x="196.78821"
        y="363.17984"
        v-if="workersCheckAvail"
        id="t-check"
      >
        Counters: {{ workersCheckAvail }}
      </text>
      <text
        class="svg-text"
        x="483.79181"
        y="370.34262"
        v-if="workersSecAvail"
        id="t-sec"
      >
        Lanes: {{ workersSecAvail }}
      </text>

      <!-----------  CheckIn queue color line --------------->
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(1) }"
        style="display:inline;mix-blend-mode:normal;fill:none;fill-opacity:1;stroke:url(#linearGradient6168);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 211.97442,416.59711 c 0,0 -0.15283,9.49839 8.82205,9.06711"
        id="green-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(2) }"
        style="display:inline;fill:none;stroke:url(#linearGradient8466);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 220.79647,425.66422 H 245.7923"
        id="green-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(3) }"
        style="display:inline;fill:none;stroke:url(#linearGradient9508);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 245.7923,425.66422 h 24.99583"
        id="green-3"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(4) }"
        style="display:inline;fill:none;stroke:url(#linearGradient16055);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 270.78813,425.66422 h 24.99583"
        id="green-4"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(5) }"
        style="display:inline;fill:none;stroke:url(#linearGradient20566);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 295.78396,425.66422 h 24.99583"
        id="green-5"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(6) }"
        style="display:inline;fill:none;stroke:url(#linearGradient23409);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 320.77979,425.66422 h 24.99583"
        id="green-6"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(7) }"
        style="display:inline;fill:none;stroke:url(#linearGradient26944);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 345.77562,425.66422 h 24.99583"
        id="green-7"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(8) }"
        style="display:inline;fill:none;stroke:url(#linearGradient30751);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 370.77145,425.66422 h 24.99583"
        id="green-8"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(9) }"
        style="display:inline;fill:none;stroke:url(#linearGradient33534);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 395.76728,425.66422 h 24.99583"
        id="green-9"
      />
      <!-- visual order changed 12->11->10 to provide better look in UI --->
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(12) }"
        style="display:inline;fill:none;stroke:url(#linearGradient53804);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 389.84021,441.94734 h 24.99583"
        id="yellow-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(11) }"
        style="fill:none;stroke:url(#linearGradient40426);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 437.01608,433.62983 c 0,0 1.73282,9.01064 -22.18004,8.31751"
        id="green-corener-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(10) }"
        style="fill:none;stroke:url(#linearGradient37474);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 420.76311,425.66422 c 18.67891,-0.0847 16.25297,9.96561 16.25297,9.96561"
        id="green-corener-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(13) }"
        style="display:inline;fill:none;stroke:url(#linearGradient56753);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 364.84438,441.94734 h 24.99583"
        id="yellow-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(14) }"
        style="display:inline;fill:none;stroke:url(#linearGradient60834);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 339.84855,441.94734 h 24.99583"
        id="yellow-3"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(15) }"
        style="display:inline;fill:none;stroke:url(#linearGradient64955);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 314.85272,441.94734 h 24.99583"
        id="yellow-4"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(16) }"
        style="display:inline;fill:none;stroke:url(#linearGradient67750);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 289.85689,441.94734 h 24.99583"
        id="yellow-5"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(17) }"
        style="display:inline;fill:none;stroke:url(#linearGradient70375);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 264.86106,441.94734 h 24.99583"
        id="yellow-6"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(18) }"
        style="display:inline;fill:none;stroke:url(#linearGradient72975);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 239.86523,441.94734 h 24.99583"
        id="yellow-7"
      />
      <!-------- visual order changed 20->19 to provide better look in UI --->
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(20) }"
        style="fill:none;fill-opacity:1;stroke:url(#linearGradient78513);stroke-width:11.3114;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 220.49379,441.94734 c 0,0 -22.87344,7.31983 -1.03996,15.94191"
        id="yellow-corner"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(19) }"
        style="display:inline;fill:none;stroke:url(#linearGradient75224);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 222.70905,441.94734 h 18.67374"
        id="yellow-8"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(21) }"
        style="display:inline;fill:none;stroke:url(#linearGradient96330);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 221.45383,457.88925 h 24.99583"
        id="red-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(22) }"
        style="display:inline;fill:none;stroke:url(#linearGradient99663);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 271.44549,457.88925 H 246.44966"
        id="red-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(23) }"
        style="display:inline;fill:none;stroke:url(#linearGradient102107);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 271.44549,457.88925 h 24.99584"
        id="red-3"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(24) }"
        style="display:inline;fill:none;stroke:url(#linearGradient104200);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 321.43717,457.88925 H 296.44133"
        id="red-4"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(25) }"
        style="display:inline;fill:none;stroke:url(#linearGradient106995);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 321.43717,457.88925 h 24.99584"
        id="red-5"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(26) }"
        style="display:inline;fill:none;stroke:url(#linearGradient109932);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 371.42885,457.88925 H 346.43301"
        id="red-6"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(27) }"
        style="display:inline;fill:none;stroke:url(#linearGradient112922);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 371.42885,457.88925 h 24.99584"
        id="red-7"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(28) }"
        style="display:inline;fill:none;stroke:url(#linearGradient115639);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 396.42469,457.88925 h 24.99584"
        id="red-8"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentCheckInQueue > qVisNr(28) }"
        style="display:inline;fill:none;stroke:url(#linearGradient117578);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 420.63365,457.88925 h 21.71772"
        id="red-9"
      />

      <!------------- Security paths --------------------->
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(1) }"
        style="display:inline;mix-blend-mode:normal;fill:none;fill-opacity:1;stroke:url(#linearGradient120142);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 584.80995,416.85896 c 0,0 0.15283,9.49839 -8.82205,9.06711"
        id="sec-green-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(2) }"
        style="display:inline;fill:none;stroke:url(#linearGradient120178);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 575.9879,425.92607 H 550.99207"
        id="sec-green-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(3) }"
        style="display:inline;fill:none;stroke:url(#linearGradient123023);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 525.99624,425.92607 h 24.99583"
        id="sec-green-3"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(4) }"
        style="display:inline;fill:none;stroke:url(#linearGradient124609);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 525.99624,425.92607 H 501.00041"
        id="sec-green-4"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(5) }"
        style="fill:none;stroke:url(#linearGradient126585);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 501.00041,425.92607 c -12.75305,-0.0966 -11.09674,11.36714 -11.09674,11.36714"
        id="sec-green-corner-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(7) }"
        style="display:inline;fill:none;stroke:url(#linearGradient132412);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 528.74658,442.89202 H 503.75075"
        id="sec-yellow-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(6) }"
        style="fill:none;stroke:url(#linearGradient126621);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 489.90367,437.29321 c 0,0 -1.08181,6.06539 13.84708,5.59881"
        id="sec-green-corner-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(8) }"
        style="display:inline;fill:none;stroke:url(#linearGradient134647);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 528.74658,442.89202 h 24.99583"
        id="sec-yellow-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(9) }"
        style="display:inline;fill:none;stroke:url(#linearGradient136155);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 578.73824,442.89202 H 553.74241"
        id="sec-yellow-3"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(10) }"
        style="fill:none;stroke:url(#linearGradient138371);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 585.48905,453.11488 c 0.0574,-11.74873 -6.75081,-10.22286 -6.75081,-10.22286"
        id="sec-yellow-corner-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(11) }"
        style="fill:none;stroke:url(#linearGradient140926);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 585.48905,453.11488 c 0.0707,7.20024 -8.31622,6.26511 -8.31622,6.26511"
        id="sec-red-corner-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(12) }"
        style="display:inline;fill:none;stroke:url(#linearGradient145322);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 577.17283,459.37999 H 552.177"
        id="sec-red-1"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(13) }"
        style="display:inline;fill:none;stroke:url(#linearGradient150032);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 552.177,459.37999 H 527.18117"
        id="sec-red-2"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(14) }"
        style="display:inline;fill:none;stroke:url(#linearGradient152269);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="m 505.95999,459.37999 h 21.95185"
        id="sec-red-3"
      />
      <path
        class="q-pass"
        :class="{ vis: this.currentSecurityQueue > secQVisNr(15) }"
        style="display:inline;fill:none;stroke:url(#linearGradient153738);stroke-width:12;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
        d="M 507.12364,459.37999 H 489.42919"
        id="sec-red-4"
      />

      <!--------------  International Airside Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('intAirsideNode', 120)"
        :location="'521.22265,-36.128125'"
      />

      <!--------------  Airside Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('airsideNode', 250)"
        :location="'2,2'"
      />

      <!--------------  International Baggage Claim Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('intBaggageNode', 120)"
        :location="'709.34784,129.36973'"
      />

      <!--------------  Baggage Claim Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('baggageNode', 100)"
        :location="'554.44216,128.96651'"
      />

      <!-------------- Customs Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('customsNode', 120)"
        :location="'687.73007,213.92442'"
      />

      <!-------------- Border Control Inbound Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('borderControlInNode', 120)"
        :location="'854.91142,209.74786'"
      />

      <!-------------- Border Control Inbound Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('borderControlOutNode', 120)"
        :location="'426.35422,9.24505'"
      />

      <!-------------- PLAGS Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('plagsNode', 120)"
        :location="'313.62272,9.98048'"
      />

      <!-------------- Gate 1 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate1Node', 150)"
        :location="'0.80254242,0,0,0.71086719,-197.35279,172.81715'"
        :small="true"
      />

      <!-------------- Gate 2 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate2Node', 150)"
        :location="'0.80254242,0,0,0.71086719,-123.15279,172.8171'"
        :small="true"
      />

      <!-------------- Gate 3 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate3Node', 150)"
        :location="'0.80254242,0,0,0.71086719,-220.43641,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 4 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate4Node', 150)"
        :location="'0.80254242,0,0,0.71086719,-92.43641,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 5 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate5Node', 150)"
        :location="'0.80254242,0,0,0.71086719,21.563594,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 6 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate6Node', 150)"
        :location="'0.80254242,0,0,0.71086719,121.56359,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 7 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate7Node', 150)"
        :location="'0.80254242,0,0,0.71086719,231.36321,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 8 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate8Node', 150)"
        :location="'0.80254242,0,0,0.71086719,327.36321,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 9 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate9Node', 150)"
        :location="'0.80254242,0,0,0.71086719,415.76321,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 10 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate10Node', 150)"
        :location="'0.80254242,0,0,0.71086719,553.76321,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 11 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate11Node', 150)"
        :location="'0.80254242,0,0,0.71086719,687.76321,-25.669852'"
        :small="true"
      />

      <!-------------- Gate 12 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate12Node', 150)"
        :location="'0.80254242,0,0,0.71086719,763.76321,-52.569852'"
        :small="true"
      />

      <!-------------- Gate 13 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate13Node', 150)"
        :location="'0.80254242,0,0,0.71086719,762.86321,-24.169852'"
        :small="true"
      />

      <!-------------- Gate 14 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate14Node', 150)"
        :location="'0.80254242,0,0,0.71086719,785.44521,9.7621478'"
        :small="true"
      />

      <!-------------- Gate 15 Queue progress bar  --------------------->
      <progress-bar
        :barPos="getQueueBarPos('gate15Node', 150)"
        :location="'0.80254242,0,0,0.71086719,884.94521,9.7621478'"
        :small="true"
      />
    </svg>

    <!-- ---------- Sidebar Node Info ----------->
    <b-sidebar id="sidebar-right" title="DIA Simulation" right shadow>
      <side-bar></side-bar>
    </b-sidebar>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import airport from '../assets/svg'

// import moment from 'moment'
import SideBar from './SideBar.vue'
import ProgressBar from './SVG/ProgressBar.vue'

export default {
  name: 'MapSvg',
  components: {
    SideBar,
    ProgressBar
  },
  data() {
    return {
      airport, // Airport SVG map
      tooltipText: '',
      pathList: {
        curve: {
          0: 'curve',
          1: 'curve2',
          2: 'curve3',
          3: 'curve4',
          4: 'curve5',
          5: 'curve6'
        },
        time: {
          0: '4000',
          1: '4000',
          2: '4000',
          3: '8000',
          4: '8000',
          5: '7000'
        },
        simTime: {
          0: '3000',
          1: '3000',
          2: '3000',
          3: '0',
          4: '3000',
          5: '7000'
        }
      },
      trackPos: 0
    }
  },
  computed: {
    workersCheckAvail() {
      return this.$store.state.checkInNode['availWorkers']
    },
    workersSecAvail() {
      return this.$store.state.securityNode['availWorkers']
    },
    currentCheckInQueue() {
      let queue = this.$store.state.checkInNode['currentQueueLength']
      if (queue) {
        return queue
      }
      return 0
    },
    currentSecurityQueue() {
      let queue = this.$store.state.securityNode['currentQueueLength']
      if (queue) {
        return queue
      }
      return 0
    }
  },
  methods: {
    ...mapMutations(['addNodeToList']),
    getQueueBarPos: function(nodeName, max) {
      let queue = this.$store.state[nodeName]['currentQueueLength']
      if (queue) {
        if (queue <= max) {
          return String((80 / max) * queue)
        } else {
          return '80'
        }
      }
      return '0'
    },
    qVisNr: function(current) {
      const tp = 29
      const max = 50
      return (max / tp) * current
    },
    secQVisNr: function(current) {
      const tp = 15
      const max = 100
      return (max / tp) * current
    },
    startDotMoving: function(dotName, trackName, duration) {
      let dot = document.getElementById(dotName)
      let track = document.getElementById(trackName)
      if (this.trackPos == '5') {
        this.trackPos = 0
      } else {
        this.trackPos += 1
      }

      let tZero = Date.now()
      requestAnimationFrame(() => this.run(tZero, duration, track, dot))
    },
    move: function(u, track, dot) {
      const p = track.getPointAtLength(u * track.getTotalLength())
      dot.setAttribute('transform', `translate(${p.x}, ${p.y})`)
    },
    run: function(tZero, duration, track, dot) {
      let u = Math.min((Date.now() - tZero) / duration, 1)

      if (u < 1) {
        // Keep requesting frames, till animation is ready
        requestAnimationFrame(() => this.run(tZero, duration, track, dot))
      } else {
        this.onFinish(dot)
      }

      this.move(u, track, dot)
    },
    onFinish: function(dot) {
      let status = this.$store.state.simulationStatus
      // Schedule the animation to restart
      if ((status == 'running') & (this.trackPos < 6)) {
        setTimeout(
          () =>
            this.startDotMoving(
              dot.id,
              `${this.pathList.curve[this.trackPos]}`,
              `${this.pathList.time[this.trackPos]}`
            ),
          this.pathList.simTime[this.trackPos]
        )
      }
    },
    tooltipContent: function(nodeId = '') {
      return this.show(nodeId)
    },
    show: function(nodeName) {
      let text = ''
      if (this.$store.state.simulationData) {
        // ---- Checking if selected node is Check In  --------
        if (nodeName === 'check-in') {
          text += this.mapNodeInfo(this.$store.state.checkInNode)
        }

        // ---- Checking if selected node is Security Check  --------
        if (nodeName === 'security-check') {
          text += this.mapNodeInfo(this.$store.state.securityNode)
        }
        // ---- Checking if selected node is Airside Dwell  --------
        if (nodeName === 'airside-dwell') {
          text += this.mapNodeInfo(this.$store.state.airsideNode)
        }
        // ---- Checking if selected node is International Airside Dwell  --------
        if (nodeName === 'int-airside-dwell') {
          text += this.mapNodeInfo(this.$store.state.intAirsideNode)
        }
        // ---- Checking if selected node is Baggage Claim  --------
        if (nodeName === 'baggage-claim') {
          text += this.mapNodeInfo(this.$store.state.baggageNode)
        }
        // ---- Checking if selected node is Int Baggage Claim  --------
        if (nodeName === 'int-baggage-claim') {
          text += this.mapNodeInfo(this.$store.state.intBaggageNode)
        }
        // ---- Checking if selected node is PLAGS  --------
        if (nodeName === 'plags') {
          text += this.mapNodeInfo(this.$store.state.plagsNode)
        }
        // ---- Checking if selected node is Border Control Out  --------
        if (nodeName === 'border-control-out') {
          text += this.mapNodeInfo(this.$store.state.borderControlOutNode)
        }
        // ---- Checking if selected node is Border Control In  --------
        if (nodeName === 'border-control-in') {
          text += this.mapNodeInfo(this.$store.state.borderControlInNode)
        }
        // ---- Checking if selected node is Customs  --------
        if (nodeName === 'customs') {
          text += this.mapNodeInfo(this.$store.state.customsNode)
        }
        // ---- Checking if selected node is any of Gates  --------
        for (let i = 0; i <= 15; i++) {
          if (nodeName === `gate-${i}`) {
            text += this.mapNodeInfo(this.$store.state[`gate${i}Node`])
          }
        }
      } else {
        text = 'No Data!</br>Please start simulation first'
      }
      return text
    },
    mapNodeInfo: function(node) {
      let tooltipInfo = ''
      if (node) {
        if (node.nodeName == 'Airside Dwell') {
          tooltipInfo += 'Passengers: ' + node.currentQueueLength + '</br>'
          tooltipInfo +=
            'Avg wait time: ' + this.secondsToHms(node.avgWaitTime) + '</br>'
          tooltipInfo += 'Max wait time: ' + this.secondsToHms(node.maxWaitTime)
        } else {
          tooltipInfo += 'Length of Queue: ' + node.currentQueueLength + '</br>'
          tooltipInfo += 'New passengers: ' + node.newPassengers + '</br>'
          tooltipInfo += 'Served passengers: ' + node.passServed + '</br>'
          tooltipInfo +=
            'Avg wait time: ' + this.secondsToHms(node.avgWaitTime) + '</br>'
          tooltipInfo +=
            'Max wait time: ' + this.secondsToHms(node.maxWaitTime) + '</br>'
          tooltipInfo +=
            'Max service time: ' +
            this.secondsToHms(node.maxServiceTime) +
            '</br>'
          tooltipInfo +=
            'Avg service time: ' + this.secondsToHms(node.avgServiceTime)
        }
      } else {
        tooltipInfo += `No Data!</br>Please load data from database`
      }
      return tooltipInfo
    },
    secondsToHms: function(d) {
      if (d == '-') {
        return '-'
      }
      d = Math.round(Number(d))
      var h = Math.floor(d / 3600)
      var m = Math.floor((d % 3600) / 60)
      var s = Math.floor((d % 3600) % 60)

      var hDisplay = h > 0 ? h + 'h ' : ''
      var mDisplay = m > 0 ? m + 'm ' : ''
      var sDisplay = s > 0 ? s + 's ' : ''
      return hDisplay + mDisplay + sDisplay
    },
    startDots: function() {
      this.startDotMoving(
        'dot',
        `${this.pathList.curve[this.trackPos]}`,
        `${this.pathList.time[this.trackPos]}`
      )
      // this.startDotMoving('dot2', 'curve2', 2000)
      // dot.init('dot', 'curve');
      // anim.start(4000);
    }
  },
  mounted() {}
}
</script>

<style scoped>
*:focus {
  outline: none;
}
.pre-formatted {
  white-space: pre;
}

.airport {
  background-image: url('../assets/Airport plan[16-05-22]-1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.mapBg {
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f1f3fa;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem;
}
.svg-map {
  width: 100%;
  height: auto;
  /* stroke: #666;
  stroke-width: 1; */
  stroke-linecap: round;
  stroke-linejoin: round;
}
.svg-map__location {
  cursor: pointer;
  opacity: 0.05;
  fill: #f44e4a;
  fill-opacity: 1;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
}
.svg-map__progress-bar {
  transition: 0.5s;
  -webkit-transition: 0.5s;
}
.svg-text {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 1.25;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  fill: #000000;
  fill-opacity: 1;
  stroke: none;
}
.q-pass {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.75s;
  -webkit-transition: opacity 0.75s;
}
.vis {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.75s;
  -webkit-transition: opacity 0.75s;
}
</style>
