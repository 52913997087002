<template>
  <div>
    <div v-if="fieldData.Edit == true" class="">
      <b-select
        size="sm"
        v-if="fieldType == 'select'"
        :value="fieldData.Value"
        :options="!selectOptions ? distFunctions : selectOptions"
        @change="activateBlur()"
        @blur.native="removeEdit($event)"
        v-focus
      ></b-select>
      <b-select
        size="sm"
        v-if="fieldType == 'select-workers'"
        :value="fieldData.Value"
        @change="activateBlur()"
        @blur.native="removeEdit($event)"
        v-focus
      >
        <b-select-option v-for="n in maxValue" :value="n" :key="n">{{
          n
        }}</b-select-option></b-select
      >
      <b-select
        size="sm"
        v-if="fieldType == 'WeekDay'"
        :value="fieldData.Value"
        :options="weekDays"
        @change="activateBlur()"
        @blur.native="removeEdit($event)"
        v-focus
      ></b-select>
      <b-input
        size="sm"
        v-if="fieldType == 'input'"
        :type="fieldData.Type.toLowerCase()"
        :value="fieldData.Value"
        @keyup.enter="activateBlur()"
        @blur="removeEdit($event)"
        v-focus
      ></b-input>
      <span v-if="fieldType == 'Time'" @blur.capture="removeTimeEdit($event)">
        <input
          tabindex="0"
          type="checkbox"
          id="checkbox"
          v-model="checked"
          class="mr-2"
        />
        <small
          ><label for="checkbox" tabindex="0">{{
            this.fieldName == 'Start' ? 'Previuos Day' : 'Next Day'
          }}</label></small
        >
        <b-input
          size="sm"
          :type="fieldData.Type.toLowerCase()"
          v-model="time"
          v-focus
        ></b-input>
      </span>
    </div>
    <div v-else class="position-relative mr-4">
      <span
        class="text-muted font-14 py-1 edit-cursor"
        @click="
          fieldType != 'Time' ? enableEdit() : enableTimeEdit(),
            hideEdit($event)
        "
        @mouseover="showEdit($event)"
        @mouseleave="hideEdit($event)"
        >{{
          fieldType != 'WeekDay' ? fieldData.Value : weekDays[weekId].text
        }}</span
      ><small class="text-muted edit-icon"
        ><i class="fas fa-edit mx-1"></i
      ></small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeField',
  data() {
    return {
      weekDays: [
        { value: '0', text: 'Monday' },
        { value: '1', text: 'Tuesday' },
        { value: '2', text: 'Wednesday' },
        { value: '3', text: 'Thursday' },
        { value: '4', text: 'Friday' },
        { value: '5', text: 'Saturday' },
        { value: '6', text: 'Sunday' },
        { value: '*', text: '* - Any' }
      ],
      distFunctions: [
        'Normal',
        'Beta',
        { value: 'ExponentialPower', text: 'Exponential Power' },
        'Exponential',
        'Gamma'
      ],
      checked: false,
      time: '00:00'
    }
  },
  props: {
    fieldData: Object,
    fieldType: String,
    maxValue: Number,
    fieldName: String,
    selectOptions: null
  },
  computed: {
    console: () => console,
    weekId() {
      if (this.fieldData.Value == '*') return '7'
      return this.fieldData.Value
    }
  },
  methods: {
    extractTime: function() {
      const value = this.fieldData.Value
      const previuos = value.indexOf('-')
      const next = value.indexOf('+')
      if (previuos >= 0 || next >= 0) {
        this.checked = true
        return value.slice(0, -1)
      }
      return value
    },
    enableTimeEdit: async function() {
      this.fieldData.Edit = true
      this.time = await this.extractTime()
      this.$forceUpdate()
    },
    removeTimeEdit: function(event) {
      setTimeout(() => {
        if (event.relatedTarget == null) {
          const symbol = this.fieldName == 'Start' ? '-' : '+'
          const suffix = this.checked ? symbol : ''
          this.fieldData.Value = String(this.time) + suffix
          delete this.fieldData.Edit
        }
        this.$forceUpdate()
      }, 50)
    },
    enableEdit: function() {
      this.fieldData.Edit = true
      this.$forceUpdate()
    },
    removeEdit: function(event) {
      let newValue = event.target.value
      if (!isNaN(event.target.value)) {
        newValue = parseFloat(event.target.value)
      }
      const oldValue = this.fieldData.Value

      this.fieldData.Value = newValue
      delete this.fieldData.Edit

      if (newValue != oldValue) {
        this.$emit('updated')
        this.$emit('functionChange', newValue)
      }
      this.$forceUpdate()
    },
    activateBlur: function() {
      delete this.fieldData.Edit
      this.$forceUpdate()
    },
    showEdit: function(event) {
      let el = event.target.nextElementSibling
      el.classList.add('edit-icon-show')
    },
    hideEdit: function(event) {
      let el = event.target.nextElementSibling
      el.classList.remove('edit-icon-show')
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
.font-14 {
  font-size: 14px !important;
}
.edit-cursor {
  border: rgba(255, 255, 255, 0) solid 1px;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.edit-cursor:hover {
  cursor: pointer;
  border: #dee2e6 solid 1px;
  border-radius: 0.15rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #fff;
}
.edit-icon-show {
  display: block !important;
}
.edit-icon {
  display: none;
  position: absolute;
  right: -23px;
  top: 0;
}
</style>
