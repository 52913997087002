<template>
  <div class="row no-gutters">
    <b-row no-gutters class="w-100">
      <div class="mb-2 col-xl-6 px-2">
        <b-input
          type="search"
          placeholder="Search"
          class="form-control"
          v-model="search"
        ></b-input>
      </div>
    </b-row>
    <b-row v-if="config" no-gutters class="w-100" align-v="stretch">
      <div
        v-for="(item, key) in nodes"
        :key="item.id"
        class="col-xl-6 px-2 mb-3"
      >
        <div class="card text-left h-100 mb-0">
          <b-row no-gutters class="mb-3 align-items-center">
            <h4 class="header-title mr-auto mb-0 d-flex align-items-center">
              <b-img
                v-bind="imgProps"
                :src="require(`@/assets/svg/nodes/${key}.svg`)"
                class="mr-2 my-1"
              ></b-img
              >{{ key | camelCase }}
            </h4>
            <b-button
              class="py-0"
              size="sm"
              @click="$router.push(`/node-config?node=${key}`)"
              ><i class="fas fa-edit mr-2"></i>Update</b-button
            >
          </b-row>
          <div class="table-responsive">
            <table
              class="table table-bordered table-sm table-centered mb-0 font-14"
            >
              <thead class="table-dia">
                <tr>
                  <th>Week Day</th>
                  <th colspan="2">Time Window</th>
                  <th>Counters</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in item.Schedule"
                  :key="row.id"
                  class="schedule-row"
                >
                  <td>
                    {{ row.WeekDay.Value | weekDay }}
                  </td>
                  <td>
                    {{ row.TimeWindow.Start.Value }}
                  </td>
                  <td>{{ row.TimeWindow.Finish.Value }}</td>
                  <td>{{ row.Workers.Value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <small>Default Workers: {{ item.DefaultWorkers.Value }}</small>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ResourceSchedule',
  data() {
    return {
      search: '',
      imgProps: { blank: false, width: 24, height: 24, class: '' }
    }
  },
  computed: {
    ...mapGetters(['config']),
    nodes() {
      let nodes = {}
      for (let item in this.config.Settings.Nodes) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.config.Settings.Nodes[item],
            'Schedule'
          )
        ) {
          nodes[item] = this.config.Settings.Nodes[item]
        }
      }
      if (this.search) {
        Object.filter = (obj, predicate) =>
          Object.keys(obj)
            .filter(key => predicate(key.toLowerCase()))
            .reduce((res, key) => ((res[key] = obj[key]), res), {})

        nodes = Object.filter(
          nodes,
          node =>
            node.indexOf(this.search.toLowerCase().replace(/\s/g, '')) > -1
        )
      }
      return nodes
    }
  },
  methods: {},
  filters: {
    camelCase: function(title) {
      return title.replace(/([a-z])([A-Z])/g, '$1 $2')
    },
    weekDay: function(day) {
      switch (day) {
        case 0:
          return 'Monday'
        case 1:
          return 'Tuesday'
        case 2:
          return 'Wednesday'
        case 3:
          return 'Thursday'
        case 4:
          return 'Friday'
        case 5:
          return 'Saturday'
        case 6:
          return 'Sunday'
        case '*':
          return '* - Any'
        default:
          return '* - Any'
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
input {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
input:focus {
  border-bottom: 1px solid #4285f4;
  -webkit-box-shadow: 0 1px 0 0 #4285f4;
  box-shadow: 0 1px 0 0 #4285f4;
}
.form-control {
  height: auto;
  padding: 0.6rem 0 0.4rem 0;
  margin: 0 0 0.5rem 0;
  background-color: transparent;
  border-radius: 0;
}
.schedule-row:nth-child(even) {
  background-color: white;
}
</style>
