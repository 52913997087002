<template>
  <div class="card text-left">
    <b-row no-gutters class="">
      <b-col class="mr-3" col xl="6">
        <h5 class="header-title">
          Bay and Gate Allocation Status:
        </h5>
        <b-alert v-if="status" :variant="status.status" show>
          {{ status.message }}
        </b-alert>
        <b-button @click="runBayGate" class="mt-2" size="sm"
          ><b-spinner
            v-if="apiLoading == 'BayGate'"
            small
            class="mr-2"
          ></b-spinner
          >Run Bay and Gate allocation</b-button
        ></b-col
      >
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'BayGateStep',
  data() {
    return {
      bayGateStatus: null
    }
  },
  props: {
    status: Object
  },
  computed: {
    ...mapGetters(['apiLoading', 'authUser'])
  },
  methods: {
    ...mapActions(['triggerCalculation']),
    ...mapMutations(['setAxiosConfig', 'setRemoteSimStatus']),
    runBayGate: async function() {
      this.setAxiosConfig(this.authUser.signInUserSession.idToken.jwtToken)
      let params = { type: 'BayGate' }
      this.setRemoteSimStatus({
        single: 'GATEBAY',
        status: 'INITIALISING...'
      })
      let res = await this.triggerCalculation(params)
      if (res.status == 'success') {
        this.makeToast('success', res.content)
        this.bayGateStatus = {
          status: 'success',
          message: 'Allocating...'
        }
        this.$emit('bayGateStatusChange', this.bayGateStatus)
      } else {
        this.bayGateStatus = {
          status: 'danger',
          message: 'There was an error while allocating Bay/Gate'
        }
        this.$emit('bayGateStatusChange', this.bayGateStatus)
        this.makeToast('danger', res.err_msg)
      }
    },
    makeToast(variant = null, text = '') {
      this.$bvToast.toast(text, {
        title: `Bay and Gate Allocation Status`,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    }
  }
}
</script>
