<template>
  <div v-if="simulationData" class="d-flex row align-items-center w-75">
    <span class="col text-nowrap pr-2 text-14 text-right">{{ startTime }}</span>
    <!-- label for="range-1">Example range with min and max</label> -->
    <new-slider
      class="col-4 blue"
      v-model="value"
      :min="this.min"
      :max="length - 1"
      :format="getTime"
      @change="changePos(value)"
    />
    <span class="col text-nowrap text-14 text-left pl-2">{{ endTime }}</span>
    <div class="col d-flex flex-column justify-content-end">
      <div class="text-nowrap text-left text-14">
        <i class="far fa-calendar-alt"></i>
        {{ runTSDate }}
      </div>
      <span class="text-nowrap text-left">
        <i class="far fa-clock align-middle"></i>
        {{ runTSTime }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import NewSlider from '@vueform/slider/dist/slider.vue2.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Slider',
  components: {
    NewSlider
  },
  data() {
    return {
      value: 0,
      sliderClock: null,
      min: 0
    }
  },
  computed: {
    ...mapGetters([
      'simulationData',
      'simSelectedDay',
      'currentSimTime',
      'simulationLength'
    ]),
    position() {
      if (this.input) {
        return this.input
      } else {
        return 0
      }
    },
    length() {
      if (this.simulationData) {
        return this.simulationData.data[this.simSelectedDay].length
      } else {
        return 100
      }
    },
    runTSDate() {
      let date = moment(this.currentSimTime)
      return date.format('MMMM Do YYYY')
    },
    runTSTime() {
      // let date = moment(this.currentSimTime)
      // let dateUTC = date.tz('UTC')
      // return dateUTC.tz('Australia/Broken_Hill').format('h:mm:ss a')
      return moment(this.currentSimTime).format('h:mm:ss a')
    },
    startTime() {
      let firstTime = Object.keys(
        this.simulationData.data[this.simSelectedDay]
      )[0]
      let date = moment(firstTime)
      return date.format('h:mm:ss a')
    },
    endTime() {
      let lastTime = Object.keys(this.simulationData.data[this.simSelectedDay])[
        this.simulationLength - 1
      ]
      let date = moment(lastTime)
      return date.format('h:mm:ss a')
    }
  },
  methods: {
    ...mapMutations(['setSimPos']),
    ...mapActions(['updateNodeInfo']),
    getTime: function(value) {
      value = Math.round(value)
      let data = this.simulationData.data[this.simSelectedDay]
      let date = moment(Object.keys(data)[value])
      return date.format('h:mm:ss a')
    },
    changePos: function(input) {
      let length = this.length
      if (input == length) {
        input -= 1
      }
      this.value = parseInt(input)
      this.setSimPos(this.value)
      this.updateNodeInfo()
      this.updatestatus()
    },
    startMoving: function() {
      this.value += 1
      if (this.value < 144) {
        this.sliderClock = setTimeout(this.startMoving, 1000)
      }
    },
    stopMoving: function() {
      clearTimeout(this.sliderClock)
    },
    keyPress(e) {
      let activeElem = document.activeElement
      let sliderElem = document.getElementsByClassName('slider-handle')
      let found = false
      if (activeElem == sliderElem['0']) {
        found = true
      }
      if (this.$store.state.simulationStatus === 'stopped' && !found) {
        let keyCode = e.code
        if (keyCode === 'ArrowRight') {
          let length = this.length
          if (this.value < length - 1) {
            this.changePos(this.value + 1)
          }
        }
        if (keyCode === 'ArrowLeft') {
          if (this.value > 0) {
            this.changePos(this.value - 1)
          }
        }
      }
    },
    updatestatus: function() {
      // ------ Update color of Security Check node ----------
      let sec = document.getElementById('security-check')
      sec.style.fill = 'red'
      sec.style.opacity = this.calcOpacity(
        this.$store.state.securityNode['avgWaitTime'],
        900
      )

      // ------ Update color of Check-In node ----------
      let check = document.getElementById('check-in')
      check.style.fill = 'red'
      check.style.opacity = this.calcOpacity(
        this.$store.state.checkInNode['avgWaitTime'],
        1080
      )

      // ------ Update color of Airside Dwell node ----------
      let air = document.getElementById('airside-dwell')
      air.style.fill = 'red'
      air.style.opacity = this.calcOpacity(
        this.$store.state.airsideNode['avgWaitTime'],
        7200
      )

      // ------ Update color of International Airside Dwell node ----------
      let intAir = document.getElementById('int-airside-dwell')
      intAir.style.fill = 'red'
      intAir.style.opacity = this.calcOpacity(
        this.$store.state.intAirsideNode['avgWaitTime'],
        7200
      )

      // ------ Update color of Baggage Claim node ----------
      let bag = document.getElementById('baggage-claim')
      bag.style.fill = 'red'
      bag.style.opacity = this.calcOpacity(
        this.$store.state.baggageNode['avgWaitTime'],
        900
      )

      // ---- Update color of International Baggage Claim node -----
      let intBag = document.getElementById('int-baggage-claim')
      intBag.style.fill = 'red'
      intBag.style.opacity = this.calcOpacity(
        this.$store.state.intBaggageNode['avgWaitTime'],
        1100
      )

      // ---- Update color of International PLAGS node -----
      let plags = document.getElementById('plags')
      plags.style.fill = 'red'
      plags.style.opacity = this.calcOpacity(
        this.$store.state.plagsNode['avgWaitTime'],
        1200
      )

      // ---- Update color of International Border Control Outbound node -----
      let borderOut = document.getElementById('border-control-out')
      borderOut.style.fill = 'red'
      borderOut.style.opacity = this.calcOpacity(
        this.$store.state.borderControlOutNode['avgWaitTime'],
        600
      )

      // ---- Update color of International Border Control Inbound node -----
      let borderIn = document.getElementById('border-control-in')
      borderIn.style.fill = 'red'
      borderIn.style.opacity = this.calcOpacity(
        this.$store.state.borderControlInNode['avgWaitTime'],
        600
      )

      // ---- Update color of International Customs node -----
      let customs = document.getElementById('customs')
      customs.style.fill = 'red'
      customs.style.opacity = this.calcOpacity(
        this.$store.state.customsNode['avgWaitTime'],
        1200
      )

      // ---- Update color of Gates node -----
      for (let i = 1; i <= 15; i++) {
        let gate = document.getElementById(`gate-${i}`)
        gate.style.fill = 'red'
        gate.style.opacity = this.calcOpacity(
          this.$store.state[`gate${i}Node`]['avgWaitTime'],
          1800
        )
      }
    },
    calcOpacity: function(info, max) {
      let nodeOp = 0
      if (info != '-') {
        nodeOp = info / max
      }
      return nodeOp
    }
  },
  created() {
    window.addEventListener('keyup', this.keyPress)
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyPress)
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
.text-14 {
  font-size: 14px;
}
.blue {
  --slider-handle-bg: #54a0c9;
  --slider-tooltip-bg: #54a0c9;
  --slider-connect-bg: #54a0c9;
  --slider-handle-ring-color: #007dbd;
}
</style>
