<template>
  <div id="login-card">
    <div class="d-flex flex-column text-center align-items-center">
      <img
        src="../assets/mail_sent.svg"
        class="mb-3"
        alt="mail sent image"
        height="64"
        width="64"
      />
      <h4 class="mt-0">Please check your email</h4>
      <p class="text-muted">
        An email has been send to <b>{{ confirmEmail }}</b
        >. Please check for an email from company, enter your verification code
        and new password.
      </p>
    </div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="error != ''"
      @dismissed="error = ''"
    >
      <i class="fas fa-exclamation-triangle mr-2"></i>{{ error }}
    </b-alert>
    <b-form @submit="onSubmit" class="d-flex flex-column text-left">
      <b-form-group id="input-group-1">
        <label for="input-1" class="mb-0">Username:</label>
        <b-form-input
          id="input-1"
          v-model="confirmUsername"
          type="text"
          class="input-readonly"
          plaintext
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-2">
        <label for="input-2" class="">Verification code:</label>
        <b-form-input
          id="input-2"
          v-model="form.code"
          type="text"
          placeholder="Enter verification code"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-3">
        <label for="input-3" class="">New Password:</label>
        <b-input-group class="">
          <template #append>
            <b-input-group-text @click="showPass()"
              ><strong :class="[showPassword ? 'text-forecast' : 'text-muted']"
                ><i
                  class="far"
                  :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
                ></i></strong
            ></b-input-group-text>
          </template>
          <b-form-input
            id="input-3"
            v-bind:type="showPassword ? 'text' : 'password'"
            v-model="form.newPass"
            placeholder="Enter your new password"
            required
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-button
        type="submit"
        class="btn-forecast d-flex justify-content-center align-items-center"
        ><b-spinner v-if="resetCodeLoading" small></b-spinner>
        <i v-if="!resetCodeLoading" class="fa fa-undo"></i>
        <span class="ml-2">Confirm Password Reset</span></b-button
      >
    </b-form>
    <footer class="footer footer-alt">
      <p class="text-muted">
        Back to
        <router-link to="/login" class="text-muted ml-1"
          ><b>Log In</b></router-link
        >
      </p>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Auth } from 'aws-amplify'

export default {
  name: 'ResetPassCode',
  data() {
    return {
      form: {
        code: '',
        newPass: ''
      },
      resetCodeLoading: false,
      error: '',
      showPassword: false
    }
  },
  computed: mapGetters(['confirmEmail', 'confirmUsername']),
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      this.resetCodeLoading = true
      try {
        await Auth.forgotPasswordSubmit(
          this.confirmUsername,
          this.form.code,
          this.form.newPass
        )
        this.resetCodeLoading = false
        this.$router.push({ path: '/resetpass/success' })
      } catch (err) {
        console.log(err)
        this.error = err.message
        this.resetCodeLoading = false
      }
    },
    showPass: function () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>
