import Vue from 'vue'
import Router from 'vue-router'
import { Auth } from 'aws-amplify'

Vue.use(Router)

import SimHistory from '../components/SimHistory.vue'
import ApiControls from '../components/ApiControls.vue'
import FlightSchedule from '../components/FlightSchedule.vue'
import NodeConfig from '../components/NodeConfig/NodeConfig.vue'
import LoginBase from '../components/LoginBase'
import Insights from '../components/Insights.vue'

import store from '../store'

async function guardMyroute(to, from, next) {
  let isAuthenticated = false
  let currentUser = store.getters.authUser
  if (!currentUser) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        store.commit('setAuthUser', user)
        isAuthenticated = true
      }
    } catch (err) {
      console.log(err)
    }
  } else {
    isAuthenticated = true
  }
  if (isAuthenticated) {
    next() // allow to enter route
  } else {
    next('/login') // go to '/login';
  }
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: __dirname,
    scrollBehavior() {
      // always scroll to top
      return { x: 0, y: 0 }
    },
    routes: [
      { path: '/', name: 'home', redirect: '/login', component: LoginBase },
      { path: '/dashboard', component: ApiControls, beforeEnter: guardMyroute },
      { path: '/run', component: ApiControls, beforeEnter: guardMyroute },
      {
        path: '/insights',
        component: Insights,
        beforeEnter: guardMyroute
      },
      {
        path: '/flight-schedule',
        component: FlightSchedule,
        beforeEnter: guardMyroute
      },
      {
        path: '/node-config',
        component: NodeConfig,
        beforeEnter: guardMyroute
      },
      {
        path: '/history/:runId?',
        name: 'history',
        component: SimHistory,
        beforeEnter: guardMyroute
      }
    ]
  })
}
