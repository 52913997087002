<template>
  <div class=""></div>
</template>

<script>
import Auth from '@aws-amplify/auth'
import moment from 'moment-timezone'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'S3Controls',
  data() {
    return {
      analysisObj: null
    }
  },
  computed: mapGetters([
    'authUser',
    's3Bucket',
    'simFile',
    'analysisFiles',
    'simSelectedDay',
    'analysisLoading',
    'simulationData',
    'simulationName',
    'simulationLength',
    'simulationList',
    'stopLoop'
  ]),
  methods: {
    ...mapActions(['fetchS3SimFile', 'simulationAction']),
    ...mapMutations([
      'setSimData',
      'setSimName',
      'setSimDays',
      'addSimDay',
      'setSimSelectedDay',
      'setAnalysisLoading',
      'setStopLoop',
      'setAxiosConfig'
    ]),
    async fetchSimFiles(id) {
      let run = id
      this.setAxiosConfig(this.authUser.signInUserSession.idToken.jwtToken)
      let params = {}
      params.method = 'get'
      params.type = 'getData'

      let start = moment(this.simulationList[run].min)
      let end = moment(this.simulationList[run].max)

      this.analysisObj = { runID: run, data: {} }

      for (var m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
        // stop loop if Reset
        if (this.stopLoop) {
          break
        }
        this.setAnalysisLoading(true)
        let currentDate = m.format('YYYY-MM-DD')
        params.content = { run: run, date: currentDate }
        const res2 = await this.simulationAction(params)

        this.setAnalysisLoading(false)
        // stop loop if Reset
        if (this.stopLoop) {
          break
        }

        this.parseAnalysisData(JSON.parse(res2.response.data.data), {
          runId: run,
          date: currentDate
        })
        // stop loop if Reset
        if (this.stopLoop) {
          break
        }
        if (!this.simSelectedDay) this.setSimSelectedDay(currentDate)

        let countRows = Object.keys(this.analysisObj.data[currentDate]).length
        this.analysisObj.data[currentDate].length = countRows
      }
      this.setStopLoop(false)
    },
    parseAnalysisData: async function(data, info) {
      let date = info.date
      this.analysisObj.data[date] = {}

      data.forEach(element => {
        let checkTime = Object.prototype.hasOwnProperty.call(
          this.analysisObj.data[date],
          element.Time
        )
        if (!checkTime) {
          this.analysisObj.data[date][element.Time] = {}
        }
        let checkNode = Object.prototype.hasOwnProperty.call(
          this.analysisObj.data[date][element.Time],
          element.Location
        )
        if (!checkNode) {
          this.analysisObj.data[date][element.Time][element.Location] = {
            Workers: element.MaxWorkers
          }
        }
        this.analysisObj.data[date][element.Time][element.Location][
          `${element.Metrics}`
        ] = element.Data
      })
      // stop loop if Reset
      if (this.stopLoop) {
        return
      }
      this.addSimDay(info.date)
      this.setSimData(this.analysisObj)
      this.setSimName(info.name ? info.name : info.runId)
    },
    async callS3(simName) {
      let cred = await Auth.currentCredentials()
      let analysisObj = { runID: simName, data: {} }
      this.setAnalysisLoading(true)
      for (const [day] of Object.entries(this.analysisFiles[simName])) {
        // stop loop if Reset
        if (this.stopLoop) {
          break
        }

        if (day != '__data') {
          analysisObj.data[day] = {}
          if (!this.simSelectedDay) {
            this.setSimSelectedDay(day)
          }
          for (const [node] of Object.entries(
            this.analysisFiles[simName][day]
          )) {
            // stop loop if Reset
            if (this.stopLoop) {
              break
            }

            if (node != '__data') {
              let path = `${cred.identityId}/${simName}/${day}/${node}`
              await this.fetchS3SimFile(path)
              // stop loop if Reset
              if (this.stopLoop) {
                break
              }
              this.$papa.parse(this.simFile, {
                delimiter: ',',
                header: true,
                skipEmptyLines: true,
                complete: async results => {
                  results.data.forEach(element => {
                    let check = Object.prototype.hasOwnProperty.call(
                      analysisObj.data[day],
                      element.Time
                    )
                    if (!check) {
                      analysisObj.data[day][element.Time] = {}
                    }
                    let check2 = Object.prototype.hasOwnProperty.call(
                      analysisObj.data[day][element.Time],
                      element.Location
                    )
                    if (!check2) {
                      analysisObj.data[day][element.Time][element.Location] = {}
                    }
                    analysisObj.data[day][element.Time][element.Location][
                      `${element.Metrics}`
                    ] = element.Data
                  })
                }
              })
            }
          }
          if (this.stopLoop) {
            break
          }
          if (!this.simulationData) {
            this.setSimData(analysisObj)
          }

          if (!this.simulationName) {
            this.setSimName(simName)
          }
          this.addSimDay(day)
        }
      }
      if (!analysisObj) {
        this.makeToast('danger', 'File is missing!')
      }
      this.setStopLoop(false)
      this.setAnalysisLoading(false)
    },
    makeToast(variant = null, text = '') {
      let l = this.simulationLength

      // Use a shorter name for this.$createElement
      const h = this.$createElement
      // Create the message
      const vNodesMsg = h(
        'p',
        { class: ['text-left', 'mb-0', 'd-flex', 'flex-column'] },
        [
          h('span', { class: [] }, `Simulation - ${text}`),
          h('span', { class: [] }, `Simulation Length: ${l}`)
        ]
      )
      this.$bvToast.toast([vNodesMsg], {
        title: 'Darwin International Airport',
        toaster: 'b-toaster-top-right',
        autoHideDelay: 2000,
        variant: variant
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
