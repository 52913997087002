<template>
  <div>
    <div class="page-title-box d-flex">
      <h4 class="page-title">
        <i class="fas fa-file-contract mr-2"></i>Insights
      </h4>
    </div>
    <b-card v-if="!quicksightURL">
      <b-button @click="getQsURL()"
        ><b-spinner
          v-if="qsApiLoading == 'quicksight'"
          small
          class="mr-2"
        ></b-spinner
        ><span v-if="qsApiLoading == 'quicksight'">Loading</span>
        <span v-else>Open Quicksight</span>
        Dashboard</b-button
      >
    </b-card>
    <div id="embeddingContainer" ref="embeddingContainer"></div>
  </div>
</template>
<script>
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'

import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Insights',
  data() {
    return {
      APIStatus: '',
      quicksightURL: '',
      options: {
        url: '',
        container: '',
        parameters: {
          country: 'Australia'
        },
        scrolling: 'no',
        height: 'AutoFit',
        loadingHeight: '700px',
        iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
        width: '100%',
        locale: 'en-AU',
        footerPaddingEnabled: true,
        sheetId: 'YOUR_SHEETID', // use this option to specify initial sheet id to load for the embedded dashboard
        sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls in dashboard embedding
        printEnabled: false, // use this option to enable or disable print option for dashboard embedding
        undoRedoDisabled: false, // set this option to true to disable undo and redo buttons for dashboard embedding
        resetDisabled: false // set this option to true to disable reset button for dashboard embedding
      }
    }
  },
  computed: {
    ...mapGetters(['authUser', 'qsApiLoading'])
  },
  methods: {
    ...mapActions(['getQuicksightURL']),
    ...mapMutations(['setActive']),
    getQsURL: async function() {
      this.token = this.authUser.signInUserSession.idToken.jwtToken
      let res = await this.getQuicksightURL(this.token)
      if (res.status == 'success') {
        this.makeToast('success', 'Embed API URL was retrieved successfuly')
        this.quicksightURL = res.content.EmbedUrl
        this.APIStatus = {
          status: 'success',
          message: res.content.EmbedUrl
        }
        this.options.url = this.quicksightURL
        this.options.container = this.$refs.embeddingContainer
        embedDashboard(this.options)
      } else {
        this.APIStatus = {
          status: 'danger',
          message: 'There was an error while fetching QS embed URL'
        }
        this.makeToast('danger', res.err_msg)
      }
    },

    makeToast(variant = null, text = '') {
      this.$bvToast.toast(text, {
        title: `Gateway API status`,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    }
  },
  mounted() {
    this.setActive('insights')
    this.getQsURL()
  }
}
</script>
