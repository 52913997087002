<template>
  <div>
    <div class="page-title-box d-flex">
      <h4 class="page-title">
        <i class="fas fa-plane mr-2"></i>Initialise simulation
      </h4>
    </div>
    <div class="card">
      <div class="row mr-0">
        <!-------- Simulation services STATUS ----->
        <div class="row justify-content-start m-0">
          <b-row class="align-items-start w-100 mb-2" no-gutters>
            <div class="dia-info">
              <i class="fas fa-info mx-2"></i>Digital Twin Status Table
            </div>
            <b-badge
              @click="simStatus()"
              variant="warning"
              class="badge ml-auto pointer"
              ><b-spinner
                v-if="loading == 'status'"
                small
                class="mr-1 spinner"
              ></b-spinner
              ><i v-else class="fas fa-sync-alt mr-1"></i>Check Status</b-badge
            >
          </b-row>
          <status-table
            v-on:showApiMessage="
              apiMessage = $event.message
              makeToast(
                $event.status == 'success' ? 'success' : 'danger',
                $event.status == 'success' ? 'Success' : 'Error'
              )
            "
          ></status-table>
        </div>
        <!-- end STATUS --->
      </div>
    </div>
    <simulation-steps ref="steps"></simulation-steps>
  </div>
</template>

<script>
import axios from 'axios'

// import { Auth } from 'aws-amplify'

// import simAPIServices from '@/services/simulationApi.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import SimulationSteps from '@/components/SimulationSteps/SimulationSteps.vue'
import StatusTable from '@/components/StatusTable.vue'
import Auth from '@aws-amplify/auth'

const baseAPI = process.env.VUE_APP_BASE_API_URL // Protected API from Pahse 2

export default {
  name: 'ApiControls',
  components: {
    SimulationSteps,
    StatusTable
  },
  data() {
    return {
      apiMessage: '',
      loading: '',
      clock: null
    }
  },
  computed: {
    ...mapGetters(['authUser', 'remoteSimulationStatus']),
    config() {
      return {
        headers: {
          Authorization: this.authUser.signInUserSession.idToken.jwtToken
        }
      }
    }
  },
  props: {},
  methods: {
    ...mapMutations(['setAuthUser', 'setRemoteSimStatus', 'setActive']),
    ...mapActions(['getRemoteFlightSchedule']),
    async simStatus() {
      try {
        this.loading = 'status'
        const response = await axios.get(
          baseAPI + '/getstatus?onlymine=True',
          this.config
        )
        this.loading = ''
        await this.parseStatus(response.data['message'].Status)
      } catch (e) {
        if (e.response) {
          if (e.response.status === 401) {
            if (
              e.response.data['message'] == 'The incoming token has expired'
            ) {
              let user = await Auth.currentAuthenticatedUser()
              if (user) {
                await this.setAuthUser(user)
                this.simStatus()
              }
            } else {
              this.apiMessage = e.response.data['message']
              this.makeToast('danger', 'Error')
            }
          }
          console.log(e.response)
        } else {
          this.apiMessage = e.message
          this.makeToast('danger', 'Error')
        }
        await this.parseStatus([], true)
        this.loading = ''
      }
    },
    parseStatus: function(statusObj, error = false) {
      let status = {
        SIMULATION: 'IDLE',
        GATEBAY: 'IDLE',
        LAODFACTOR: 'IDLE'
      }
      if (statusObj.length > 0) {
        statusObj.forEach(element => {
          switch (element.RunType) {
            case 'SERVICE':
              if (element.Service == 'CALLOADFACTOR') {
                status.LAODFACTOR = element.RunStatus
                break
              }
              if (element.Service == 'CALBAYALLOCATION') {
                status.GATEBAY = element.RunStatus
                break
              }
              break
            default:
              status[`${element.RunType}`] = element.RunStatus
              break
          }
        })
      }
      if (error) {
        status.SIMULATION = 'ERROR'
        status.GATEBAY = 'ERROR'
        status.LAODFACTOR = 'ERROR'
      }
      this.setRemoteSimStatus({ status: status })
      this.apiMessage = String(JSON.stringify(status))
      return 'Done '
    },
    makeToast(variant = null, text = '') {
      // Use a shorter name for this.$createElement
      const h = this.$createElement
      // Create the message
      const vNodesMsg = h(
        'p',
        { class: ['text-left', 'mb-0', 'd-flex', 'flex-column'] },
        [
          h('span', { class: [] }, `Simulation - ${text}`),
          h('span', { class: [] }, `API response: ${this.apiMessage}`)
        ]
      )
      this.$bvToast.toast([vNodesMsg], {
        title: 'Darwin International Airport',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 2000,
        variant: variant
      })
    }
  },
  watch: {
    remoteSimulationStatus: {
      async handler(current, old) {
        if (
          current.SIMULATION != 'IDLE' ||
          current.GATEBAY != 'IDLE' ||
          current.LAODFACTOR != 'IDLE'
        ) {
          if (this.clock != null) {
            clearTimeout(this.clock)
            this.clock = null
          }
          this.clock = setTimeout(() => {
            this.simStatus()
          }, 10000)
        } else {
          if (old.GATEBAY != 'IDLE') {
            await this.getRemoteFlightSchedule()
            this.$refs.steps.checkForGateBay()
          }
          if (old.LAODFACTOR != 'IDLE') {
            await this.getRemoteFlightSchedule()
            this.$refs.steps.checkForLoadFactor()
          }
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.setActive('simulation')
    this.simStatus()
  },
  beforeDestroy() {
    if (this.clock) {
      clearTimeout(this.clock)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .table-dia {
  color: #fff;
  background-color: #007dbd;
}
::v-deep .badge:hover {
  transform: scale(1.05);
  box-shadow: #a3d1fc 0px 2px 4px 0px, #a3d1fc 0px 2px 4px 0px;
}
.bg {
  box-shadow: 0 0 6px 2px #e8dddd;
  border-radius: 19px;
  padding: 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  background-color: #f1f3fa;
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
}
.card {
  border: none;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  margin-bottom: 24px;
  background-color: #f1f3fa;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem;
  padding: 1.5rem 1.5rem;
}
.text-8 {
  font-size: 8px;
}
.info-text {
  background-color: #e4eff9;
  border-radius: 4px;
  color: #007dbd;
  border-left-style: solid;
  border-color: #007dbd;
  border-width: 2px;
}
.pointer {
  cursor: pointer;
}
.dia-info {
  color: #007dbd;
}
.spinner {
  width: 0.75rem;
  height: 0.75rem;
}
</style>
