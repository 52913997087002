const state = {
  user: null,
  confirmEmail: '',
  confirmUsername: ''
}
const getters = {
  authUser: (state) => state.user,
  confirmEmail: (state) => state.confirmEmail,
  confirmUsername: (state) => state.confirmUsername
}
const actions = {}
const mutations = {
  setAuthUser: (state, user) => (state.user = user),
  setconfirmEmail: (state, email) => (state.confirmEmail = email),
  setconfirmUsername: (state, user) => (state.confirmUsername = user)
}
export default {
  state,
  getters,
  actions,
  mutations
}
