export default {
  label: 'Map of Airport',
  viewBox: '0 0 1287.04 541.44',
  locations: [
    {
      name: 'Check In',
      id: 'check-in',
      vuexName: 'checkIn',
      path:
        'm 194.07534,351.76156 h 317.79838 v 8.66408 h -31.19068 v 11.09002 h -13.51596 v 33.61662 H 194.07534 Z'
    },
    {
      name: 'Security Check',
      id: 'security-check',
      vuexName: 'security',
      path: 'm 480.68304,360.42564 h 114.36583 v 48.17227 H 480.33648 Z'
    },
    {
      name: 'Baggage Claim',
      id: 'baggage-claim',
      vuexName: 'baggage',
      path: 'M 856.49389,270.03682 H 986.8473 l 0.26657,82.37056 H 856.22732 Z'
    },
    {
      name: 'International Baggage Claim',
      id: 'int-baggage-claim',
      vuexName: 'intBaggage',
      path:
        'm 987.55429,322.47936 h 22.14451 v -53.03301 h 165.7281 v 49.30413 h 17.2634 v 33.28374 H 987.55429 Z'
    },
    {
      name: 'Airside Dwell',
      id: 'airside-dwell',
      vuexName: 'airside',
      path:
        'm 559.833,145.18851 v 37.83209 h -10.53746 v 10.36017 h -13.72319 v 5.24321 H 302.76808 v -55.99641 h 256.94239 z'
    },
    {
      name: 'International Airside Dwell',
      id: 'int-airside-dwell',
      vuexName: 'intAirside',
      path:
        'M 785.58569,150.77274 V 100.639 H 1017.012 v 5.75913 l 21.002,-1.3e-4 v 44.535 z'
    },
    {
      name: 'PLAGS',
      id: 'plags',
      vuexName: 'plags',
      path:
        'M 616.43967,152.12463 641.02268,128.025 h 55.72609 v 23.82342 h 30.39624 v 46.33483 H 616.57778 Z'
    },
    {
      name: 'Border Control',
      id: 'border-control-out',
      vuexName: 'borderControlOut',
      path: 'm 838.28153,151.95313 -111.13652,-0.10471 v 46.33483 h 111.13652 z'
    },
    {
      name: 'Border Control',
      id: 'border-control-in',
      vuexName: 'borderControlIn',
      path:
        'm 1264.0381,352.29492 v 12.25586 h -1.1231 v 60.9375 h 4.8096 v 15.82031 h -96.9238 v -9.71679 h 14.6972 v -42.99317 l -36.3525,-36.35254 z'
    },
    {
      name: 'Customs',
      id: 'customs',
      vuexName: 'customs',
      path: 'm 987.74414,353.4668 v 77.00195 H 1121.3379 V 353.4668 Z'
    },
    {
      name: 'Gate 1',
      id: 'gate-1',
      vuexName: 'gate1',
      path: 'm 31.60368,270.80078 h 82.08152 v 29.58985 H 31.542969 Z'
    },
    {
      name: 'Gate 2',
      id: 'gate-2',
      vuexName: 'gate2',
      path: 'm 113.6852,300.39063 v -29.58985 h 79.8939 v 29.58985 z'
    },
    {
      name: 'Gate 3',
      id: 'gate-3',
      vuexName: 'gate3',
      path: 'M 30.957031,70.654297 132.1682,70.779732 V 100.74891 H 30.859375 Z'
    },
    {
      name: 'Gate 4',
      id: 'gate-4',
      vuexName: 'gate4',
      path:
        'M 217.24199,70.779732 H 132.1682 v 29.969178 h 85.06813 c 0.012,-9.989535 0.006,-19.9795 0.006,-29.969178 z'
    },
    {
      name: 'Gate 5',
      id: 'gate-5',
      vuexName: 'gate5',
      path: 'M 273.82813,100.61079 V 70.572571 h 81.10633 v 30.038219 z'
    },
    {
      name: 'Gate 6',
      id: 'gate-6',
      vuexName: 'gate6',
      path: 'm 354.93446,70.572571 h 77.47791 v 30.038219 h -77.47791 z'
    },
    {
      name: 'Gate 7',
      id: 'gate-7',
      vuexName: 'gate7',
      path: 'm 484.21296,70.57258 h 77.47791 v 30.03821 h -77.47791 z'
    },
    {
      name: 'Gate 8',
      id: 'gate-8',
      vuexName: 'gate8',
      path: 'm 561.69087,70.572577 h 82.57328 v 30.038213 h -82.57328 z'
    },
    {
      name: 'Gate 9',
      id: 'gate-9',
      vuexName: 'gate9',
      path: 'm 644.26415,70.572587 h 81.98259 v 30.038203 h -81.98259 z'
    },
    {
      name: 'Gate 10',
      id: 'gate-10',
      vuexName: 'gate10',
      path: 'm 785.57409,70.572595 h 81.98259 v 30.038195 h -81.98259 z'
    },
    {
      name: 'Gate 11',
      id: 'gate-11',
      vuexName: 'gate11',
      path: 'm 920.72134,70.5726 h 81.98256 v 30.03819 h -81.98256 z'
    },
    {
      name: 'Gate 12',
      id: 'gate-12',
      vuexName: 'gate12',
      path: 'm 1016.9947,49.300685 h 60.5757 v 28.842862 h -60.5757 z'
    },
    {
      name: 'Gate 13',
      id: 'gate-13',
      vuexName: 'gate13',
      path: 'm 1016.9911,78.139931 h 60.5829 v 28.359979 h -60.5829 z'
    },
    {
      name: 'Gate 14',
      id: 'gate-14',
      vuexName: 'gate14',
      path: 'm 1038.0139,106.49991 h 80.143 v 28.35998 h -80.143 z'
    },
    {
      name: 'Gate 15',
      id: 'gate-15',
      vuexName: 'gate15',
      path: 'm 1118.2085,106.55154 76.5745,4.6e-4 v 28.25622 h -76.5745 z'
    }
  ]
}
