import axios from 'axios'
import { Storage, Auth } from 'aws-amplify'
import moment from 'moment-timezone'

// Disabled - Loading simulation list from Database
// ------------------ DISABLED --------------------
// async function processStorageList(result) {
//   const filesystem = {}
//   // https://stackoverflow.com/questions/44759750/how-can-i-create-a-nested-object-representation-of-a-folder-structure
//   const add = (source, target, item) => {
//     const elements = source.split('/')
//     const element = elements.shift()
//     if (!element) return // blank
//     target[element] = target[element] || { __data: item } // element;
//     if (elements.length) {
//       target[element] =
//         typeof target[element] === 'object' ? target[element] : {}
//       add(elements.join('/'), target[element], item)
//     }
//   }
//   await result.forEach(item => add(item.key, filesystem, item))
//   return filesystem
// }
// --------------------------------------------------

const state = {
  s3BucketName: null,
  simulationList: null,
  analysisFiles: null,
  analysisList: null,
  simulationFile: null,
  error: null,
  s3ApiLoading: '',
  fileContent: '',
  flightScheduleCSV: {
    file: null,
    lastModified: null,
    remote: false,
    flightNo: 0,
    firstFlight: '',
    lastFlight: ''
  },
  disabledBaysJSON: null,
  configJSON: null
}

const getters = {
  s3Bucket: state => state.s3BucketName,
  simulationList: state => state.simulationList,
  simFile: state => state.simulationFile,
  error: state => state.error,
  s3ApiLoading: state => state.s3ApiLoading,
  fileContent: state => state.fileContent,
  remoteFlightScheduleCSV: state => state.flightScheduleCSV,
  disabledBays: state => state.disabledBaysJSON,
  config: state => state.configJSON,
  analysisFiles: state => state.analysisFiles
}

const actions = {
  async fetchS3Bucket({ commit }) {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/getbucketname`
    )
    commit('setBucketName', response.data.s3bucket)
  },

  // Disabled - Loading simulation list from Database
  // ------------------ DISABLED --------------------
  // async getAnalysisList({ commit }) {
  //   let folders = []
  //   let cred = await Auth.currentCredentials()
  //   try {
  //     let result = await Storage.list('analysis/' + cred.identityId + '/')
  //     let list = await processStorageList(result)
  //     console.log(list.analysis[cred.identityId])
  //     for (const [key] of Object.entries(list.analysis[cred.identityId])) {
  //       if (key != '__data') folders.push(key)
  //     }
  //     commit('setAnlaysisFiles', list.analysis[cred.identityId])
  //     commit('setSimList', folders)
  //     console.log(folders)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // },
  // --------------------------------------------------
  async getRemoteFlightSchedule({ commit }) {
    await Auth.currentCredentials()
    try {
      commit('setS3Loading', 'get')
      let res = await Storage.get('flightschedule.csv', {
        level: 'protected',
        download: true, // defaults to false
        cacheControl: 'no-cache',
        contentType: 'text/csv' // set return content type, eg "text/html"
      })
      var file = new File([res.Body], 'flightschedule.csv', {
        type: res.type
      })
      let date = moment(res.LastModified)
      let dateUTC = date.tz('UTC')
      let dateLocal = dateUTC
        .tz('Australia/Broken_Hill')
        .format('MMMM Do YYYY h:mm:ss a')
      let csvInfo = {
        file: file,
        lastModified: dateLocal,
        remote: true
      }
      commit('setFlightScheduleCSV', csvInfo)
      commit('setS3Loading', '')
      return { status: 'success', response: res }
    } catch (error) {
      commit('setS3Loading', '')
      return { status: 'error', err_msg: error.message }
    }
  },
  async copyMasterFile({ commit }, filename) {
    await Auth.currentCredentials()
    try {
      commit('setS3Loading', 'get')
      await Storage.copy(
        {
          key: filename,
          level: 'public'
        },
        {
          key: filename,
          level: 'protected'
        }
      )
      commit('setS3Loading', '')
    } catch (error) {
      console.log(error)
      commit('setS3Loading', '')
    }
  },
  async fetchS3SimFile({ commit }, simName) {
    Auth.currentCredentials()
    try {
      this.loading = true
      const response = await Storage.get('analysis/' + simName, {
        download: true
      })
      const file = await response.Body.text()
      commit('setSimFile', file)
      this.loading = false
    } catch (err) {
      console.log(err)
      this.loading = false
    }
  },
  async uploadFlightSchedule({ commit, getters }) {
    Auth.currentCredentials()
    const content = getters.fileContent
    try {
      commit('setS3Loading', 'upload')
      const response = await Storage.put('flightschedule.csv', content, {
        level: 'protected',
        contentType: 'text/csv'
      })
      commit('setS3Loading', '')
      return { status: 'success', response: response }
    } catch (err) {
      commit('setS3Loading', '')
      return { status: 'error', err_msg: err.message }
    }
  },
  async uploadDisabledBays({ commit, getters }) {
    Auth.currentCredentials()
    const content = JSON.stringify(getters.disabledBays)
    try {
      commit('setS3Loading', 'dBays')
      const response = await Storage.put('disabled-bays.json', content, {
        level: 'protected',
        contentType: 'text/json'
      })
      commit('setS3Loading', '')
      return { status: 'success', response: response }
    } catch (err) {
      commit('setS3Loading', '')
      return { status: 'error', err_msg: err.message }
    }
  },
  async uploadToS3({ commit, getters }, { object, fileName }) {
    Auth.currentCredentials()
    const content = JSON.stringify(getters[`${object}`])
    try {
      commit('setS3Loading', 'upload')
      const response = await Storage.put(fileName, content, {
        level: 'protected',
        contentType: 'text/json'
      })
      commit('setS3Loading', '')
      return { status: 'success', response: response }
    } catch (err) {
      commit('setS3Loading', '')
      return { status: 'error', err_msg: err.message }
    }
  },
  async getRemoteDisabledBaysList({ commit }) {
    await Auth.currentCredentials()
    try {
      commit('setS3Loading', 'getBays')
      let res = await Storage.get('disabled-bays.json', {
        level: 'protected',
        download: true, // defaults to false
        cacheControl: 'no-cache',
        contentType: 'application/json' // set return content type, eg "text/html"
      })
      let string = await res.Body.text()
      var content = JSON.parse(string)
      commit('setDisabledBaysJSON', content)
      commit('setS3Loading', '')
      return { status: 'success', response: res }
    } catch (error) {
      commit('setS3Loading', '')
      return { status: 'error', err_msg: error.message }
    }
  },
  async getRemoteConfigFile({ commit }) {
    await Auth.currentCredentials()
    try {
      commit('setS3Loading', 'config')
      let res = await Storage.get('settings.json', {
        level: 'protected',
        download: true, // defaults to false
        cacheControl: 'no-cache',
        contentType: 'application/json' // set return content type, eg "text/html"
      })
      let string = await res.Body.text()
      var content = JSON.parse(string)
      commit('setConfig', content)
      commit('setS3Loading', '')
      return { status: 'success', response: res, content: content }
    } catch (error) {
      commit('setS3Loading', '')
      return { status: 'error', err_msg: error.message }
    }
  }
}

const mutations = {
  setBucketName: (state, name) => (state.s3BucketName = name),
  setSimList: (state, list) => (state.simulationList = list),
  setError: (state, error) => (state.error = error),
  setSimFile: (state, file) => (state.simulationFile = file),
  setS3Loading: (state, option) => (state.s3ApiLoading = option),
  setFileContent: (state, option) => (state.fileContent = option),
  setFlightScheduleCSV: (state, option) => {
    for (const [key, value] of Object.entries(option)) {
      state.flightScheduleCSV[key] = value
    }
  },
  setDisabledBaysJSON: (state, option) => (state.disabledBaysJSON = option),
  setConfig: (state, option) => (state.configJSON = option),
  setAnlaysisFiles: (state, option) => (state.analysisFiles = option)
}

export default {
  state,
  getters,
  actions,
  mutations
}
