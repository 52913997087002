<template>
  <div id="login-card">
    <h4 class="mt-0">Free Sign Up</h4>
    <p class="text-muted mb-5">
      Don't have an account? Create your account, it takes less than a minute.
    </p>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="error != ''"
      @dismissed="error = ''"
    >
      <i class="fas fa-exclamation-triangle mr-2"></i>{{ error }}
    </b-alert>
    <b-form @submit="onSubmit" class="d-flex flex-column text-left">
      <b-form-group id="input-group-1">
        <label label-for="input-1">Username:</label>
        <b-form-input
          id="input-1"
          v-model="form.username"
          type="text"
          placeholder="Enter username"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        description="We'll never share your email with anyone else."
      >
        <label label-for="input-2">Email address:</label>
        <b-form-input
          id="input-2"
          v-model="form.email"
          type="email"
          placeholder="Enter email address"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3">
        <label label-for="input-3">Password:</label>
        <b-input-group class="">
          <template #append>
            <b-input-group-text @click="showPass()"
              ><strong :class="[showPassword ? 'text-forecast' : 'text-muted']"
                ><i
                  class="far"
                  :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
                ></i></strong
            ></b-input-group-text>
          </template>
          <b-form-input
            id="input-3"
            v-bind:type="showPassword ? 'text' : 'password'"
            v-model="form.password"
            placeholder="Enter your password"
            required
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-button
        type="submit"
        class="btn-forecast d-flex justify-content-center align-items-center"
        ><b-spinner v-if="authLoading" small></b-spinner
        ><i v-if="!authLoading" class="fas fa-user"></i
        ><span class="ml-2">Sign Up</span></b-button
      >
    </b-form>
    <footer class="footer footer-alt">
      <p class="text-muted">
        Already have account?
        <router-link to="login" class="text-muted"><b>Log In</b></router-link>
      </p>
    </footer>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapMutations } from 'vuex'

export default {
  name: 'SignUp',
  data() {
    return {
      form: {
        username: '',
        email: '',
        password: ''
      },
      showPassword: false,
      authLoading: false,
      error: ''
    }
  },
  methods: {
    ...mapMutations(['setconfirmUsername', 'setconfirmEmail']),
    onSubmit(event) {
      event.preventDefault()
      this.signUp(this.form.username, this.form.password, this.form.email)
    },
    showPass: function () {
      this.showPassword = !this.showPassword
    },
    async signUp(username, password, email, phone_number = null) {
      try {
        this.authLoading = true
        await Auth.signUp({
          username,
          password,
          attributes: {
            email, // optional
            phone_number // optional - E.164 number convention
            // other custom attributes
          }
        })
        this.authLoading = false
        this.setconfirmUsername(username)
        this.setconfirmEmail(email)
        this.$router.push({ path: '/signup/confirm' })
      } catch (error) {
        this.authLoading = false
        this.error = error.message
        console.log('error signing up:', error)
      }
    }
  }
}
</script>
